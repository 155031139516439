import * as React from 'react';
import { useAppSelector as useSelector } from 'Store/index';

import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { last } from 'ramda';
import {
  dmdDiagnoses,
  epilepsyDiagnoses,
  huntingtonDiagnoses,
  keoDiagnoses,
  reoDiagnoses,
  msDiagnoses,
  nmosdDiagnoses,
  parkinsonDiagnoses,
  sleepApneaDiagnoses,
  smaDiagnoses,
  TDocument,
  mgravisDiagnoses,
} from '../utils/definitions';
import { setBasicTreatmentInfo, setCourses, setDiagnosis, setDiagnosisInfo } from '../utils/functions';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { INeuroDocument } from 'neuro-data-structures';
import { sortDocuments } from 'Utility/randomUtil';
import { useIntl } from 'react-intl';

const findLastPlatformDiagnosis = (documents: Array<TDocument>, platform: Platform): IDiagnosis | undefined => {
  let diagnosesArray: Array<TDiagnosis> = [];
  switch (platform) {
    case 'sma':
      diagnosesArray = smaDiagnoses;
      break;
    case 'dmd':
      diagnosesArray = dmdDiagnoses;
      break;
    case 'ms':
      diagnosesArray = [...msDiagnoses, ...keoDiagnoses, ...reoDiagnoses, ...nmosdDiagnoses];
      break;
    case 'parkinson':
      diagnosesArray = parkinsonDiagnoses;
      break;
    case 'huntington':
      diagnosesArray = huntingtonDiagnoses;
      break;
    case 'epilepsy':
      diagnosesArray = epilepsyDiagnoses;
      break;
    case 'sleepApnea':
      diagnosesArray = sleepApneaDiagnoses;
      break;
    case 'mgravis':
      diagnosesArray = mgravisDiagnoses;
      break;
    default:
      [];
  }
  const lastValidDiagnosis = last(
    (documents.filter((document: TDocument) => document._type === 'diagnosis') as Array<IDiagnosis>)
      .filter((document: IDiagnosis) => diagnosesArray.includes(document.diagnosis))
      .sort((d1: IDiagnosis, d2: IDiagnosis) => sortPartialDate(d1.date, d2.date)),
  );
  return lastValidDiagnosis;
};

// Extend this if more treatment periods are implemented
const findLastPlatformTreatmentPeriod = (
  documents: Array<TDocument>,
  platform: Platform,
): ININMTTreatmentPeriod | undefined => {
  switch (platform) {
    case 'ninmt': {
      const treatmentPeriods = documents.filter((d) => d._type === 'ninmtTreatmentPeriod');
      return treatmentPeriods.sort((d1: ININMTTreatmentPeriod, d2: ININMTTreatmentPeriod) =>
        sortPartialDate(d2.date, d1.date),
      )[0];
    }
    default:
      return undefined;
  }
};

const DashItem = ({ documents, platform }: IDashProps): JSX.Element => {
  const reduxDocuments = useSelector((state: any) => state.documents?.documents)?.filter((d: INeuroDocument) =>
    ['diagnosis', 'neurologicalStatusAndEDSS', 'relapse'].includes(d.documentType),
  );

  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const sortedDocuments = documents.sort((d1, d2) => sortDocuments([{ type: 'date', sortField: 'date' }])(d1, d2));

  // Diagnosis
  const lastDiagnosis = findLastPlatformDiagnosis(sortedDocuments, platform) as IDiagnosis | undefined;
  const courses = setCourses(sortedDocuments, platform);
  const diagnosis = lastDiagnosis ? setDiagnosis(fm, lastDiagnosis, courses) : '';
  const diagnosisDate = lastDiagnosis?.date ? formatPartialDate(lastDiagnosis.date) : '';
  const diagnosisInfo = setDiagnosisInfo(platform, sortedDocuments, reduxDocuments, lastDiagnosis);

  // Basic treatment information
  const lastTreatmentPeriod = findLastPlatformTreatmentPeriod(sortedDocuments, platform);
  const treatmentPeriodDate = lastTreatmentPeriod?.date ? formatPartialDate(lastTreatmentPeriod.date) : '';
  const treatmentPeriodInfo = lastTreatmentPeriod ? setBasicTreatmentInfo(platform, sortedDocuments) : [];

  if (platform === 'parkinson') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosis}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          ...diagnosisInfo.map((info, i) => ({ title: info.header, value: info.value, key: 'dgInfo' + i })),
        ]}
      />
    );
  }

  if (platform === 'epilepsy') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosis}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          ...diagnosisInfo.map((info, i) => ({ title: info.header, value: info.value, key: 'dgInfo' + i })),
        ]}
      />
    );
  }

  if (platform === 'sleepApnea') {
    const sleepApneaAndRespiratoryFailureDocs = (
      sortedDocuments as (IDiagnosis | ISleepApneaFirstVisit | IRespiratoryFirstVisit)[]
    ).filter(
      (d) =>
        'diagnosis' in d &&
        ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'].includes(d.diagnosis ?? ''),
    ) as IDiagnosis[];

    sleepApneaAndRespiratoryFailureDocs.sort((s1, s2) => sortPartialDate(s2.date, s1.date));

    const sleepApneaDgs = sleepApneaAndRespiratoryFailureDocs.filter(
      (d) => d.diagnosis === 'G47.3' || d.diagnosis === 'sleepApneaSuspicion',
    );
    const respiratoryFailureDgs = sleepApneaAndRespiratoryFailureDocs.filter(
      (d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9' || d.diagnosis === 'respiratoryFailureSuspicion',
    );

    let renderedDgs = [];
    // Take most recent diagnosis from both, or all from the other
    if (sleepApneaDgs.length > 0 && respiratoryFailureDgs.length > 0) {
      renderedDgs = [sleepApneaDgs[0], respiratoryFailureDgs[0]];
    } else {
      renderedDgs = [...sleepApneaDgs, ...respiratoryFailureDgs];
    }

    renderedDgs = renderedDgs.sort((s1, s2) => sortPartialDate(s2.date, s1.date)).slice(0, 2);
    let sleepApneaDgInfo = diagnosisInfo;
    if (renderedDgs.length >= 2) {
      sleepApneaDgInfo = sleepApneaDgInfo.slice(0, 1);
    }
    return (
      <React.Fragment>
        {renderedDgs.map((d) => (
          <TileContentMaker
            key={d._id}
            type="bigHeaderInfo"
            bigHeaderText={
              d.diagnosis === 'sleepApneaSuspicion' || d.diagnosis === 'respiratoryFailureSuspicion'
                ? fm(`diagnosis.opts.${d.diagnosis}`)
                : d.diagnosis || ''
            }
            data={[
              {
                title: fm('diagnosis.diagnosisDate'),
                value: isPartialDate(d.date) ? formatPartialDate(d.date) : '',
                key: 'diagnosisDate',
              },
            ]}
          />
        ))}
        <div style={{ height: '2rem' }} />
        <TileContentMaker
          type="specificList"
          data={sleepApneaDgInfo.map((i, idx) => ({
            date: isPartialDate(i.date) ? formatPartialDate(i.date) : '',
            title: i.header,
            value: i.value,
            key: `${idx}`,
          }))}
        />
      </React.Fragment>
    );
  }

  if (platform === 'ms') {
    const diagnosisText =
      Array.isArray(reoDiagnoses) &&
      lastDiagnosis?.diagnosis &&
      (reoDiagnoses as TDiagnosis[]).includes(lastDiagnosis?.diagnosis)
        ? fm(`diagnosis.msTitle.${diagnosis}`)
        : diagnosis;
    const filteredInfo = diagnosisInfo.filter((d) => d.header) || [];
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosisText}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          ...filteredInfo.map((info, i) => ({ title: info.header, value: info.value, key: 'dgInfo' + i })),
        ]}
      />
    );
  }

  if (platform === 'sma') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosis}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          {
            title: fm('diagnosis.smaTypeShort'),
            value: lastDiagnosis?.smaType,
            key: 'smaTypeShort',
          },
        ]}
      />
    );
  }

  if (platform === 'dmd') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosis}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          {
            title: fm('diagnosis.dmdMutationNameShort'),
            value: ![undefined, null, 'c.'].includes(lastDiagnosis?.dmdMutationName)
              ? lastDiagnosis?.dmdMutationName ?? ''
              : null,
            key: 'dmdMutationNameShort',
          },
        ]}
      />
    );
  }

  if (platform === 'ninmt') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={false}
        bigHeaderText={null}
        data={[
          { title: fm('diagnosis.ninmt.treatmentPeriod'), value: treatmentPeriodDate, key: 'treatmentPeriodDate' },
          ...treatmentPeriodInfo.map((i, idx) => ({
            title: i.header,
            value: i.value,
            key: `${idx}`,
          })),
        ]}
      />
    );
  }

  if (platform === 'mgravis') {
    return (
      <TileContentMaker
        type="bigHeaderInfo"
        isDiagnosis={true}
        bigHeaderText={diagnosis}
        data={[
          { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
          ...diagnosisInfo.map((di, i) => ({ title: di.header, value: di.value, key: di.key || `${i}` })),
        ]}
      />
    );
  }

  return (
    // For the rest (Huntington...)
    <TileContentMaker
      type="bigHeaderInfo"
      isDiagnosis={true}
      bigHeaderText={diagnosis}
      data={[
        { title: fm('diagnosis.diagnosisDate'), value: diagnosisDate, key: 'diagnosisDate' },
        ...diagnosisInfo.map((i, idx) => ({
          title: i.header,
          value: i.value,
          key: `${idx}`,
        })),
      ]}
    />
  );
};

const DiagnosisDash = ({ documents }: IOwnProps): JSX.Element => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);

  return <>{platform && <DashItem platform={platform} documents={documents} />}</>;
};

interface IDashProps {
  documents: Array<IDiagnosis>;
  platform: Platform;
}

interface IOwnProps {
  documents: Array<IDiagnosis>;
}

export default DiagnosisDash;
