export const queryParamsBuilder = (queryParams: string[]) => {
  let paramsString = '';
  queryParams.map((param) => {
    paramsString += `${'/'}${param}`;
  });
  return paramsString;
};

export interface IDefaultFetcherInterface {
  body?: null | Record<string, any> | string;
  queryParams?: null | string[];
}
