import { fetchWithOptions } from 'Utility/fetch';
import { makeLog } from 'Utility/logger';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { IDefaultFetcherInterface, queryParamsBuilder } from './util';
import { TMESSAGE } from '../types/TMessage';
import { IMessageListItem } from '../interfaces';

interface IMessageCreate extends IDefaultFetcherInterface {
  name: 'create';
  body: string;
  /** patientid, threadid */
  queryParams: [string, string];
}
interface IMessageDelete extends IDefaultFetcherInterface {
  name: 'delete';
  /** patientid, messageid */
  queryParams: [string, string];
}

interface IMessageList extends IDefaultFetcherInterface {
  name: 'list';
  /** patientid, threadid */
  queryParams: [string, string];
}
interface IMessageSend extends IDefaultFetcherInterface {
  name: 'send';
}
interface IMessageUpdate extends IDefaultFetcherInterface {
  name: 'update';
  /** patientid, threadid, messageid */
  queryParams: [string, string, string];
}

type TMessageActions = IMessageCreate | IMessageDelete | IMessageList | IMessageSend | IMessageUpdate;

const basePath = '/api/messaging/v1';

const messageFetchConfig = {
  create: {
    method: 'POST',
    path: basePath + '/message/create',
  },
  delete: {
    method: 'DELETE',
    path: basePath + '/message/',
  },
  list: {
    method: 'GET',
    path: basePath + '/messages/',
  },
  send: {
    method: 'POST',
    path: basePath + '/message/send',
  },
  update: {
    method: 'PATCH',
    path: basePath + '/message/content',
  },
};

type TDefaultReturn<N> = {
  returnType: N extends 'list' ? IMessageListItem[] | false : N extends 'create' ? TMESSAGE | false : boolean;
};

export const messageFetchBuilder = <T>(builderProps: TMessageActions): Promise<TDefaultReturn<T>['returnType']> => {
  const actionName = builderProps.name;
  const path =
    messageFetchConfig[actionName].path +
    `${builderProps.queryParams ? queryParamsBuilder(builderProps.queryParams) : ''}`;
  // Body string, add option for object if needed
  const bodyString = builderProps.body && typeof builderProps.body === 'string' ? { body: builderProps.body } : {};

  return fetchWithOptions(
    path,
    { neurojwt: parseJWTFromCookie(), 'Content-Type': 'text/plain; charset=utf-8' },
    { method: messageFetchConfig[actionName].method, ...bodyString },
  )
    .then((res) => {
      if (res.status === 200) {
        // Return response body parsed, or true if it cannot be parsed
        return res.json().catch(() => true);
      } else throw res;
    })
    .catch((res) => {
      makeLog(
        'Error',
        { name: `Message ${actionName} failed`, message: `${messageFetchConfig[actionName].method} failed` },
        res,
      );
      return null;
    });
};
