// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

/**
 * Omit fields from an object
 * @param {Array<string>} omittedFields - List of field names to be omitted
 * @param {Record<string, any>} object - An object
 * @returns {Record<string, any>} An object
 */
const omit = <T = { [key: string]: any }, K = string>(omittedFields: Array<K>, object: T): Omit<T, K> => {
  if (!object) return {};
  if (omittedFields.length < 1) return object;

  const omittedFieldsSet = new Set(omittedFields);
  return Object.fromEntries(Object.entries(object).filter((e) => !omittedFieldsSet.has(e[0])));
};

/**
 * Find a value inside an object/array by giving a path to it
 * TODO: Maybe change to even better correspond ramda path (Integer paths are meant to index arrays, strings are meant for objects.)
 * @param {Array<string|number>} pathArray - List of fieldnames/indexes, path to the wanted value
 * @param {Record<string, any>|Array<any>} objectOrArray - An object or array
 * @returns {any} Value
 */
const path = (
  pathArray: Array<string | number>,
  objectOrArray?: Record<string | number, any> | Array<any> | null,
): any => {
  let currentValue = undefined;
  let currentTarget = objectOrArray || null;
  pathArray.every((fieldName) => {
    if (Array.isArray(currentTarget)) {
      const index = parseInt(fieldName); // Should it be parsed?
      currentValue = currentTarget?.[index];
    } else {
      currentValue = currentTarget?.[fieldName];
    }

    if (typeof currentValue !== 'object' && !Array.isArray(currentValue)) return false;
    else {
      currentTarget = currentValue;
      return true;
    }
  });

  return currentValue;
};

/**
 * Return array with only unique values
 * TODO: Test that it works with objects as well
 * @param {Array<any>} array - An array
 * @returns {Array<any>} Value
 */
const uniq = (array: Array<any>): Array<any> => {
  return Array.from(new Set(array));
};

/**
 * Checks if the value is null or undefined
 * @param {any} value - A Value
 * @returns {boolean} Whether the value is null or undefined
 */
const isNil = (value: any): value is null | undefined => {
  return value === null || value === undefined;
};

/**
 * Take a certain amount of items from and array and return them as an array
 * @param {number} number - Number of values taken
 * @param {Array<any>} array - An array
 * @returns {Array<any>} New array
 */
const take = (number: number, array: Array<any>): Array<any> => {
  return array.slice(0, number);
};

/**
 * Check whether the value is empty ([], '', {}).
 *
 * undefined, null, 0 are not empty
 *
 * Not a complete replacement for ramdas isEmpty. Does not for example support ArrayBuffer
 * @param {any} value - A Value
 * @returns {boolean} Whether the value is empty
 */
const isEmpty = (value?: any): boolean => {
  if (value === null || value === undefined) return false;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return value === '';
};

export { omit, path, uniq, isNil, take, isEmpty };
