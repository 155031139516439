import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { styled } from '@mui/system';

import colors from '../../../config/theme/colors';
import Tooltip from '../../ToolTip';

const StyledRadioBox = styled('div')({
  height: '3rem',
  textAlign: 'center',
  border: `1px ${colors.primary} solid`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: 'rgba(4, 90, 139, 0.08)',
  },
  marginBottom: '1rem', // For multi row options
});

const StyledLabel = styled('div')({
  height: '100%',
  color: colors.primary,
  fontSize: '1.5rem',
  fontWeight: 600,
});

const formatValue = (value: string | number | boolean): string | boolean | number => {
  return value === 'true'
    ? true
    : value === 'false'
      ? false
      : typeof value === 'string' && !isNaN(parseInt(value)) && value.includes('.')
        ? parseFloat(value)
        : value;
};

const radioOnChange = (name: string, clickedValue: string | number, onChange: IInputBasics['onChange']) => (): void => {
  const value = formatValue(clickedValue);
  onChange && onChange({ [name]: value });
};

// Component that makes it possbile to give multiple values as input; the radiobuttonrow accepts only one value as input
const MultiRadioButtonRows = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  tooltips,
  height,
  width,
}: IInputBasics & IMultiRadioButtonRows): JSX.Element | JSX.Element[] => {
  const optionSelectButton = (label: string | number, index: number): JSX.Element => {
    return (
      <StyledRadioBox
        style={{
          backgroundColor: (value || []).includes(formatValue(label)) ? 'rgba(4, 90, 139, 0.28)' : undefined, // 0.28 for better contrast
          height: height ? height + 'rem' : undefined,
          minWidth: width ? width + 'rem' : undefined,
          margin: index % 2 ? '0 -0.1rem' : undefined, // Overlap borders
        }}
        onClick={radioOnChange(name, label, onChange)}
      >
        <StyledLabel>
          <Container style={{ height: '100%' }} alignItems="center" justifyContent="center">
            <Item style={{ margin: '0rem 1rem' }}>{optionFormatter ? optionFormatter(label) : label}</Item>
          </Container>
        </StyledLabel>
      </StyledRadioBox>
    );
  };

  return editing ? (
    <Container>
      {options.map((o, i) => (
        <Item key={o}>
          {tooltips && tooltips[i] && tooltips[i].index === i ? (
            <Tooltip title={''} description={tooltips[i].message} content={optionSelectButton(o, i)} hover={true} />
          ) : (
            optionSelectButton(o, i)
          )}
        </Item>
      ))}
    </Container>
  ) : (
    <>{optionFormatter && value ? value.map((v) => optionFormatter(v)) : (value || []).join(', ')}</>
  );
};

export default MultiRadioButtonRows;
