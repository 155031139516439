/**
 * Store settings and middleware
 */

import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import documents from './documents';
import form from './form';
import sessionReducer, { subscribersChangedAction, websocketClosedAction } from './session';
import * as sessionMiddleware from './session/middleware';
import appointments from './appointments';
import patient from './patient';
import myapp from './myapp';
import settings from './settings';
import patientsearch from './patientsearch';
import readonly from './readonly';
import messaging from './messaging';
import WebSocketManager, { IWebSocketManager } from './session/WebSocketManager';
import createConnectedCapabilityAsserter from 'Utility/connectedCapabilityAsserter';

const middleware = [thunk, sessionMiddleware.invalidatePeerOnLoad.middleware];

const store = configureStore({
  reducer: {
    documents,
    form,
    session: sessionReducer,
    appointments,
    patient,
    myapp,
    settings,
    patientsearch,
    readonly,
    messaging,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(middleware);
  },
  devTools: true,
});

export const ws_manager: IWebSocketManager = new WebSocketManager(
  {
    connection_attempt_timeout_ms: 1000,
    connection_reattempt_delay_ms: 5000,
    max_connection_attempts_count: 4,
  },
  {
    dispatch: store.dispatch,
    get_state: store.getState,
    action_creator_get_subscription_message: subscribersChangedAction,
    action_creator_websocket_closed: websocketClosedAction,
  },
);

export const capabilityAsserter = createConnectedCapabilityAsserter(store);
export const assertCapabilities = capabilityAsserter.assertCapabilities;

export type RootState = ReturnType<typeof store.getState>;
export type TDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => TDispatch = useDispatch;

export default store;
