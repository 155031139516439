import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector as useSelector } from 'Store/index';
import { formatPartialDate, isPartialDate, findLatest } from 'neuro-utils';

import { leadConfiguration } from '../Document/config';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { path } from 'Utility/ramdaReplacement';

const generatorType = (generator: string, platform: string): JSX.Element | null => {
  if (leadConfiguration[platform][generator]?.rechargeable === true) {
    return <FormattedMessage id="dbs.rechargeable" />;
  } else if (leadConfiguration[platform][generator]?.rechargeable === false)
    return <FormattedMessage id="dbs.primaryCell" />;
  return null;
};

/**
 * Derive treatment status from IDBS document's dates, "ended status" and discontinuation events.
 */
const getTreatmentStatus = (doc: IDBS | undefined): Required<IDBS>['status'] | 'unknown' => {
  const isStarted = isPartialDate(doc?.date);
  const isPaused =
    doc &&
    Array.isArray(doc?.discontinuationEvents) &&
    findLatest<IDBSDiscontinuationEvent>(doc.discontinuationEvents, (dE) => !isPartialDate(dE.endDate));
  const isEnded = doc?.hasEnded?.[0] === true;

  if (isStarted && !isPaused && !isEnded) return 'inProgress';
  else if (isStarted && isPaused && !isEnded) return 'suspended';
  else if (isStarted && isEnded) return 'ended';
  else return 'unknown'; // fallthrough shouldn't occur
};

const DBSDash = ({ documents }: IOwnProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const fm = (id: string) => formatMessage({ id });

  const platform = useSelector((s: IState) => s.session.platforms?.selected) ?? '';

  const doc = documents?.[0];
  const generator = doc ? (path(['generators', 0, 'generator'], documents[0]) as string) : '';
  const treatmentStatus = getTreatmentStatus(doc);

  const generatorText =
    generator === 'Other rechargeable' ? (
      fm('dbs.otherRechargeable')
    ) : generator === 'Other battery' ? (
      fm('dbs.otherPrimaryCell')
    ) : (
      <>
        {generator}
        {generatorType(generator, platform) ? (
          <>
            {' - '}
            {generatorType(generator, platform)}
          </>
        ) : (
          ''
        )}
      </>
    );
  return (
    <React.Fragment>
      {doc?.hasEnded?.[0] === true ? (
        // DBS ended
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={generatorText}
          bigHeaderTextSubheader={fm('dbs.generator')}
          data={[
            {
              title: fm('dbs.startedShort'),
              value: isPartialDate(doc.date) ? formatPartialDate(doc.date) : null,
              key: 'startDate',
            },
            {
              title: fm('dbs.endedShort'),
              value: isPartialDate(doc.endDate) ? formatPartialDate(doc.endDate) : null,
              key: 'endedShort',
            },
            ...(doc?.endReason && platform === 'epilepsy'
              ? [
                  {
                    title: fm('dbs.endedReason'),
                    value: fm(`dbs.opts.${doc.endReason}`),
                    key: 'endedReason',
                  },
                ]
              : []),
            ...(doc?.removed
              ? [
                  {
                    title: fm('dbs.removedShort'),
                    value: isPartialDate(doc.removalDate) ? formatPartialDate(doc.removalDate) : null,
                    key: 'removedShort',
                  },
                ]
              : []),
            ...(doc?.removed && Array.isArray(doc?.removalType) && doc?.removalType.length > 0
              ? [
                  {
                    title: fm('dbs.removalType'),
                    value: (
                      <>
                        {doc.removalType.length === 4
                          ? fm('dbs.opts.wholeSystem')
                          : doc.removalType.map((rt, i) => (
                              <React.Fragment key={rt}>
                                {fm(`dbs.opts.${rt}`)}
                                {i < (doc.removalType?.length || 0) - 1 ? ', ' : ''}
                              </React.Fragment>
                            ))}
                      </>
                    ),
                    key: 'removalType',
                  },
                ]
              : []),
          ]}
        />
      ) : (
        // DBS not ended
        <TileContentMaker
          type="bigHeaderInfo"
          bigHeaderText={generatorText}
          bigHeaderTextSubheader={fm('dbs.generator')}
          data={[
            {
              title: fm('dbs.startedShort'),
              value: isPartialDate(doc.date) ? formatPartialDate(doc.date) : null,
              key: 'startDate',
            },
            {
              title: fm('dbs.status'),
              value: fm('dbs.opts.' + treatmentStatus),
              key: 'status',
            },
            ...(treatmentStatus === 'inProgress'
              ? [
                  ...(doc?.settings?.[0]?.generatorAdjustedDate
                    ? [
                        {
                          title: fm('dbs.latestSettings'),
                          value: isPartialDate(doc.settings[0].generatorAdjustedDate)
                            ? formatPartialDate(doc.settings[0].generatorAdjustedDate)
                            : null,
                          key: 'latestSettings',
                        },
                      ]
                    : []),
                  ...(doc?.generators && doc.generators.length > 1 && doc.generators[0].generatorDate
                    ? [
                        {
                          title: fm('dbs.latestGeneratorChange'),
                          value: isPartialDate(doc.generators[0].generatorDate)
                            ? formatPartialDate(doc.generators[0].generatorDate)
                            : null,
                          key: 'latestGeneratorChange',
                        },
                      ]
                    : []),
                  ...(doc?.generators?.[0]?.generatorNextChangeDate
                    ? [
                        {
                          title: fm('dbs.nextGeneratorChange'),
                          value: isPartialDate(doc?.generators?.[0]?.generatorNextChangeDate)
                            ? formatPartialDate(doc?.generators?.[0]?.generatorNextChangeDate)
                            : null,
                          key: 'nextGeneratorChange',
                        },
                      ]
                    : []),
                ]
              : treatmentStatus === 'suspended'
                ? [
                    ...(doc?.discontinuationEvents?.[0]?.date
                      ? [
                          {
                            title: fm('dbs.discontinuationDate'),
                            value: isPartialDate(doc?.discontinuationEvents?.[0]?.date)
                              ? formatPartialDate(doc?.discontinuationEvents?.[0]?.date)
                              : null,
                            key: 'discontinuationDate',
                          },
                        ]
                      : []),
                    ...(doc?.discontinuationEvents?.[0]?.discontinuationReason
                      ? [
                          {
                            title: fm('dbs.discontinuationReasonShort'),
                            value: fm('dbs.opts.' + doc?.discontinuationEvents?.[0]?.discontinuationReason),
                            key: 'discontinuationReasonShort',
                          },
                        ]
                      : []),
                  ]
                : []),
          ]}
        />
      )}
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: IDBS[];
}

export default DBSDash;
