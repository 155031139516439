import { isPartialDate, nowPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import * as React from 'react';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { IHistoryContext, withHistoryContext } from '../../../containers/FormContextHandler';
import { path } from 'Utility/ramdaReplacement';

export const patientHasSleepApneaDiagnosis = (documents: Array<IDiagnosis>) =>
  documents.some((d) => d.diagnosis === 'G47.3' || d.diagnosis === 'sleepApneaSuspicion');

export const patientHasRespiratoryFailureDiagnosis = (documents: Array<IDiagnosis>) =>
  documents.some(
    (d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9' || d.diagnosis === 'respiratoryFailureSuspicion',
  );

export const getRightDocumentsForPlatform = (documents: (TDocument & IDiagnosis)[]) => {
  const dgDocs = documents.filter((d) => d._type === 'diagnosis') as IDiagnosis[];
  const allSRPlatformDiagnosis = ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'];
  const oxygenTherapyDocs = dgDocs.some((d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9')
    ? (documents.filter((d) => d._type === 'oxygenTherapy') as IOxygenTherapy[])
    : dgDocs.every((d) => !allSRPlatformDiagnosis.includes(d.diagnosis ?? ''))
      ? (documents.filter((d) => d._type === 'oxygenTherapy') as IOxygenTherapy[])
      : [];
  const hfncTherapyDocs = dgDocs.some((d) => d.diagnosis === 'J96.1' || d.diagnosis === 'J96.9')
    ? (documents.filter((d) => d._type === 'hfncTherapy') as IHFNCTherapy[])
    : dgDocs.every((d) => !allSRPlatformDiagnosis.includes(d.diagnosis ?? ''))
      ? (documents.filter((d) => d._type === 'hfncTherapy') as IHFNCTherapy[])
      : [];
  const papTherapyDocs = documents.filter((d) => d._type === 'papTherapy') as IPAPTherapy[];
  const madTherapyDocs = dgDocs.some((d) => d.diagnosis === 'G47.3')
    ? (documents.filter((d) => d._type === 'madTherapy') as IMADTherapy[])
    : dgDocs.every((d) => !allSRPlatformDiagnosis.includes(d.diagnosis ?? ''))
      ? (documents.filter((d) => d._type === 'madTherapy') as IMADTherapy[])
      : [];
  const surgicalTreatmentDocs = documents.filter((d) => d._type === 'surgicalTreatment') as ISurgicalTreatment[];
  const otherTreatmentDocs = documents.filter((d) => d._type === 'otherTreatment') as IOtherTreatment[];

  const therapyRefusalDocs = documents.filter(
    (d) => d._type === 'patientDoesNotWantRespiratorySupportTherapy',
  ) as IOtherTreatment[];

  const treatmentDocs: TDocument[] = [
    ...oxygenTherapyDocs,
    ...hfncTherapyDocs,
    ...papTherapyDocs,
    ...madTherapyDocs,
    ...surgicalTreatmentDocs,
    ...otherTreatmentDocs,
    ...therapyRefusalDocs,
  ];

  return treatmentDocs;
};

// Find out if the treatment has ended (has an endDate which is not in the future) and has been marked as ended
export const isEnded = (hasEnded?: boolean[], endDate?: PartialDate): boolean => {
  if (hasEnded && hasEnded?.[0] === true && isPartialDate(endDate)) {
    const nowDate = partialDateToValue(nowPartialDate());
    const endedDate = partialDateToValue(endDate);
    if (endedDate <= nowDate) return true;
    else return false;
  } else return false;
};

export const getActiveTreatments = (documentArray: TDocument[]) =>
  documentArray
    ? documentArray.filter((at: TDocument) => {
        return at._type === 'patientDoesNotWantRespiratorySupportTherapy'
          ? !isEnded([isPartialDate(path(['endDate'], at))], path(['endDate'], at))
          : !['surgicalTreatment', 'otherTreatment'].includes(at._type) ||
              (at._type === 'otherTreatment' &&
                !['referralToNutritionist', 'referralToWeightControl', 'psychiatristConsultation'].includes(
                  path(['otherTreatmentType'], at) ?? '',
                ))
            ? !isEnded(path(['hasEnded'], at), path(['endDate'], at))
            : sortPartialDate(at.date, nowPartialDate()) <= 0;
      })
    : [];

export const getEndedTreatments = (documentArray: TDocument[]) =>
  documentArray
    ? documentArray.filter((t) =>
        t._type === 'patientDoesNotWantRespiratorySupportTherapy'
          ? isEnded([isPartialDate(path(['endDate'], t))], path(['endDate'], t))
          : isEnded(path(['hasEnded'], t), path(['endDate'], t)),
      )
    : [];

export const filterDocs = (type: string, documents: TDocument[]): TDocument[] =>
  documents ? documents.filter((d) => d._type === type) : [];

export const docType = (documents: Array<TDocument>, editing: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        documents.find((d: TDocument) => d._id === editing),
      ) || undefined
    : undefined;

export type TDocument =
  | IDiagnosis
  | IPatientDoesNotWantRespiratorySupportTherapy
  | IOxygenTherapy
  | IHFNCTherapy
  | IPAPTherapy
  | IMADTherapy
  | ISurgicalTreatment
  | IOtherTreatment;

export type TDocTypes =
  | 'patientDoesNotWantRespiratorySupportTherapy'
  | 'oxygenTherapy'
  | 'hfncTherapy'
  | 'papTherapy'
  | 'madTherapy'
  | 'surgicalTreatment'
  | 'otherTreatment';

export const treatmentDocTypes = [
  'patientDoesNotWantRespiratorySupportTherapy',
  'oxygenTherapy',
  'hfncTherapy',
  'papTherapy',
  'madTherapy',
  'surgicalTreatment',
  'otherTreatment',
];

export const getHeaderId = (documents: Array<TDocument>, editing: any, viewing: any): string =>
  docType(documents, editing ?? viewing) === 'patientDoesNotWantRespiratorySupportTherapy'
    ? 'treatment.patientDoesNotWantRespiratorySupportTherapy.title'
    : docType(documents, editing ?? viewing) === 'oxygenTherapy'
      ? 'treatment.oxygenTherapy.title'
      : docType(documents, editing ?? viewing) === 'hfncTherapy'
        ? 'treatment.hfncTherapy.title'
        : docType(documents, editing ?? viewing) === 'papTherapy'
          ? 'treatment.papTherapy.title'
          : docType(documents, editing ?? viewing) === 'madTherapy'
            ? 'treatment.madTherapy.title'
            : docType(documents, editing ?? viewing) === 'surgicalTreatment'
              ? 'treatment.surgicalTreatment.title'
              : docType(documents, editing ?? viewing) === 'otherTreatment'
                ? 'treatment.otherTreatment.title'
                : 'treatment.title';

/**
 * Function for checking if the current doc being edited is the first treatment doc
 * @param docs array of documents
 * @param view view-prop
 * @param editing editing-prop
 * @returns true if the current doc being edited is the first treatment doc, otherwise false
 */
export const checkIfCurrentDocumentIsTheFirstTreatment = (
  docs: Array<any>,
  view: IView | undefined,
  editing: string | undefined,
): boolean => {
  const treatmentDocs: TDocument[] = docs
    .filter((d) => treatmentDocTypes.includes(d._type))
    .sort((n1, n2) => n1._cdate - n2._cdate)
    .sort((n1: TDocument, n2: TDocument) => sortPartialDate(n1.date, n2.date));
  // If only one document in the array of documents, return true
  if (treatmentDocs.length === 1) return true;
  // If first of the docs is same as the one being edited, return true
  if (treatmentDocs?.[0] && treatmentDocs?.[0]._id === editing && isPartialDate(treatmentDocs?.[0].date)) return true;
  // If first of the docs is same as the one being viewed, return true
  if (treatmentDocs?.[0] && treatmentDocs?.[0]._id === view?.viewing && isPartialDate(treatmentDocs?.[0].date))
    return true;
  return false;
};

/**
 * Function for checking if the type of sleepApneaFirstVisit or respiratoryFirstVisit document is acute treatment start
 * @param {Array<any>} docs array of documents
 * @param {'sleepApneaFirstVisit' | 'respiratoryFirstVisit' | undefined} type for specifying if the checked document is for sleep apnea or respiratory failure - if undefined, sleep apnea is used as default
 * @returns {boolean} true if the type of the first visit document is acute treatment start, false if it is referral
 */
export const checkIfAcuteTreatmentStart = (
  docs: Array<any>,
  type?: 'sleepApneaFirstVisit' | 'respiratoryFirstVisit',
): boolean => {
  const firstVisitDoc = docs.find((d) => d._type === (type ?? 'sleepApneaFirstVisit')) as
    | ISleepApneaFirstVisit
    | IRespiratoryFirstVisit;
  if (firstVisitDoc && firstVisitDoc?.referralOrAcute === 'acute') return true;
  return false;
};

/**
 * Function for getting the date of first visit document if its type is referral
 * @param {Array<any>} docs array of documents
 * @param {'sleepApneaFirstVisit' | 'respiratoryFirstVisit' | undefined} type for specifying if the checked document is for sleep apnea or respiratory failure - if undefined, sleep apnea is used as default
 * @returns {PartialDate | undefined} date of first visit document if it exists and its type is referral, otherwise undefined
 */
export const getDateOfReferral = (
  docs: Array<any>,
  type?: 'sleepApneaFirstVisit' | 'respiratoryFirstVisit',
): PartialDate | undefined => {
  const firstVisitDoc = docs.find((d) => d._type === (type ?? 'sleepApneaFirstVisit')) as
    | ISleepApneaFirstVisit
    | IRespiratoryFirstVisit;
  return firstVisitDoc && firstVisitDoc?.referralOrAcute === 'referral' ? firstVisitDoc?.dateOfReferral : undefined;
};

/**
 * Function for checking if delay from suspicion to treatment start should be shown
 * @param acute tells if treatment has been started as acute
 * @param isFirstDoc tells if the current doc being edited is first of a kind
 * @returns boolean that indicates if delay should be shown or not
 */
export const showDelay = (acute: boolean, isFirstDoc: boolean): boolean => {
  // Do not show the question if acute treatment start
  if (acute) return false;
  // Do not show the question if the doc being edited is not first of a kind
  if (!isFirstDoc) return false;
  return true;
};

/**
 * Function for mapping treatment start/decision dates to PDNWRST endDates
 * If a treatment start/decision date greater than or equal to PDNWRST date is found, it is mapped to PDNWRST endDate
 * Else nothing is done (return empty array)
 * @param {Array<TDocument>} documents array of treatment documents (including PDNWRST)
 * @returns {Array<IPatientDoesNotWantRespiratorySupportTherapy>} array of PDNWRST documents with mapped endDates OR empty array
 */
export const mapPatientDoesNotWantRespiratorySupportTherapyEndDates = (
  documents: Array<TDocument>,
): Array<IPatientDoesNotWantRespiratorySupportTherapy> => {
  const docs: Array<TDocument> = JSON.parse(JSON.stringify(documents));
  const treatmentDocs = docs.filter(
    (d) =>
      d._editing === false &&
      ['oxygenTherapy', 'hfncTherapy', 'papTherapy', 'madTherapy', 'surgicalTreatment'].includes(d._type),
  );
  const pDocs: Array<IPatientDoesNotWantRespiratorySupportTherapy> = docs.filter(
    (d) => d._editing === false && d._type === 'patientDoesNotWantRespiratorySupportTherapy',
  );
  // Find PDNWRST documents without endDates
  const pDocsWithoutEndDates = pDocs.filter((p) => !path(['endDate'], p));

  type TDocTypesWithTreatmentDecision = IPAPTherapy | IMADTherapy | ISurgicalTreatment;
  const docTypesWithTreatmentDecision = ['papTherapy', 'madTherapy', 'surgicalTreatment'];

  if (treatmentDocs.length > 0 && pDocsWithoutEndDates.length > 0) {
    // Find, list and sort dates of treatment starts and/or decisions
    const treatmentDates = treatmentDocs
      .filter(
        (d) =>
          // If doc type does not have treatment decision date, only check for date
          (!docTypesWithTreatmentDecision.includes(d._type) && d.date) ||
          // Else check for both
          (docTypesWithTreatmentDecision.includes(d._type) &&
            ((d as TDocTypesWithTreatmentDecision).treatmentDecisionDate || d.date)),
      )
      // Prefer treatment decision date since it should be earlier than start date
      .map((t) =>
        path(['treatmentDecisionDate'], t) ? (t as TDocTypesWithTreatmentDecision).treatmentDecisionDate : t.date,
      )
      .sort((a, b) => sortPartialDate(a, b));
    // Map suitable endDates to PDNWRST documents
    return pDocsWithoutEndDates
      .map((p) => {
        p.endDate = treatmentDates.find((date) => sortPartialDate(date, p.date) >= 0);
        return p;
      })
      .filter((p) => isPartialDate(p.endDate));
  }
  return [];
};

/**
 * Continuous treatment: has a start date and a possible end date
 * Treatments that are not continuous: surgicalTreatment and other treatments of type referralToNutritionist, referralToWeightControl and psychiatristConsultation
 * @param {TDocument} d treatment document
 * @returns {boolean} true if treatment is continuous, false if not
 */
export const isContinuous = (d: TDocument) =>
  ['oxygenTherapy', 'hfncTherapy', 'papTherapy', 'madTherapy', 'patientDoesNotWantRespiratorySupportTherapy'].includes(
    d._type,
  ) ||
  (d._type === 'otherTreatment' &&
    [undefined, 'postureTreatment', 'otherTreatment'].includes(path(['otherTreatmentType'], d) ?? ''));

/**
 * Function for categorizing treatments of given type by their status: active, ended, future or not started
 * @param {Array<TDocument>} documents array of all documents in treatment section
 * @param {string} type document type to be checked
 * @returns {{ active: Array<TDocument>; ended: Array<TDocument>; future: Array<TDocument>; notStarted: Array<TDocument> }} object that has treatments categorized by their status
 */
export const categorizeTreatmentsByStatus = (
  documents: Array<TDocument>,
  type: string,
): { active: Array<TDocument>; ended: Array<TDocument>; future: Array<TDocument>; notStarted: Array<TDocument> } => {
  const docs = documents.filter((d: TDocument) => d._type === type) as TDocument[];

  const isStarted = (d: TDocument) =>
    d._type === 'madTherapy' ? (d as IMADTherapy).isTreatmentStarted === true : true;

  // Continuous treatments are active if they have no end date or it is in the future, non-continuous are active if their date is current date
  const activeDocs = docs
    .filter((d) =>
      isContinuous(d)
        ? !isEnded(path(['hasEnded'], d), path(['endDate'], d)) &&
          (sortPartialDate(d.date, nowPartialDate()) <= 0 || type === 'patientDoesNotWantRespiratorySupportTherapy') &&
          isStarted(d)
        : sortPartialDate(d.date, nowPartialDate()) === 0,
    )
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date));

  // Continuous treatments have ended if they have an end date and it has passed, non-continuous have ended if their date has passed
  const endedDocs = docs
    .filter((d) =>
      isContinuous(d)
        ? isEnded(path(['hasEnded'], d), path(['endDate'], d)) &&
          (sortPartialDate(d.date, nowPartialDate()) <= 0 || type === 'patientDoesNotWantRespiratorySupportTherapy') &&
          isStarted(d)
        : sortPartialDate(d.date, nowPartialDate()) < 0,
    )
    .sort((n1, n2) => sortPartialDate(n1.date, n2.date));

  // Treatments are in the future if their start date is later than current date (PDNWRST excluded)
  const futureDocs =
    type !== 'patientDoesNotWantRespiratorySupportTherapy'
      ? docs
          .filter((d) => sortPartialDate(d.date, nowPartialDate()) > 0)
          .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      : [];

  // For MAD therapy, treatments not started concern treatments marked as not started by radio selection
  const notStartedDocs =
    type === 'madTherapy' ? docs.filter((d) => !isStarted(d)).sort((n1, n2) => sortPartialDate(n1.date, n2.date)) : [];

  return {
    active: activeDocs,
    ended: endedDocs,
    future: futureDocs,
    notStarted: notStartedDocs,
  };
};

const DocumentCreationButtonGenerator = ({
  name,
  text,
  documents,
  startEdit,
  additionalData,
  fm,
}: { name: TDocTypesWithEnddates; text: string } & IHistoryContext<TDocumentsWithEnddates, IAdditionalData>) => {
  const thisDocTypeDocuments = (documents || [])?.filter((d) => d._type === name);

  const notEndedTreatment = thisDocTypeDocuments.find((p) => {
    if (name === 'madTherapy') {
      const isTreatmentStarted = (p as IMADTherapy).isTreatmentStarted;
      return !isTreatmentStarted ? false : !isPartialDate(p.endDate);
    } else return !isPartialDate(p.endDate);
  });

  // Retrospective visit reason
  const retro = additionalData?.visitReasonRetro;

  const oxygenTherapyAvailable = (documents: Array<IOxygenTherapy>): boolean => {
    const activeDocs = documents.filter((d) => !isPartialDate(d.endDate));
    if (
      activeDocs.some((d) =>
        ['oxygenConcentratorTherapeutic', 'oxygenConcentratorPalliative'].includes(d.oxygenTherapyType ?? ''),
      ) &&
      activeDocs.some((d) => d.oxygenTherapyType === 'stressOxygen')
    ) {
      return false;
    }
    return true;
  };

  const disabled =
    !retro && (name === 'oxygenTherapy' ? !oxygenTherapyAvailable(documents as IOxygenTherapy[]) : !!notEndedTreatment);

  return (
    <DocumentCreationButton
      name={name}
      text={text}
      onClick={startEdit && startEdit({}, name)}
      width={15}
      height={3}
      fontSize={14}
      alternate={true}
      disabled={disabled}
      disabledTooltip={<>{fm('treatment.endOngoingToAddNew')}</>}
    />
  );
};

export const DocumentCreationButtonWithContext = withHistoryContext(DocumentCreationButtonGenerator);

type TDocTypesWithEnddates =
  | 'oxygenTherapy'
  | 'hfncTherapy'
  | 'papTherapy'
  | 'madTherapy'
  | 'patientDoesNotWantRespiratorySupportTherapy';

type TDocumentsWithEnddates =
  | IPAPTherapy
  | IHFNCTherapy
  | IOxygenTherapy
  | IMADTherapy
  | IPatientDoesNotWantRespiratorySupportTherapy;

interface IAdditionalData {
  visitReasonRetro?: boolean;
}
