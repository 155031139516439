import { values, intersperse } from 'ramda';
import {
  regimenTypesByMed,
  regimenTypesByPlatform,
  medicationSubstancesByCategory,
  regimenTypesBySubstanceAndDosageform,
  getAtcCodesByPlatform,
} from '../Document/config';
import { a2String } from '../../../utility/string';
import { dateFromPartial, isPartialDate, nowPartialDate, nowTime, sortPartialDate, sortTime } from 'neuro-utils';
import { isParkinsonInfusionType } from '../Document/Form/Medication/RegimenDialog/utils/isParkinsonInfusionType';
import store from 'Store/index';
import { path, uniq } from 'Utility/ramdaReplacement';

/**
 * Map medications and their substances to categories
 * @param {IMedication[]} documents - Medication documents
 * @returns {ISubstanceCategories} Medication categories object with number of medications in each category
 */
export const medicationSubstanceCategories = (documents: IMedication[]): ISubstanceCategories => {
  const catNumbers = {} as { [key: string]: number };
  documents.forEach((d: IMedication) => {
    Object.keys(medicationSubstancesByCategory).forEach((c: string) => {
      if (medicationSubstancesByCategory[c].test(d.medicationSubstances ?? '')) {
        if (catNumbers[c]) {
          catNumbers[c]++;
        } else catNumbers[c] = 1;
      }
    });
  });
  return catNumbers;
};

interface ISubstanceCategories {
  levodopa?: number;
  maobInhibitors?: number;
  dopamineAgonists?: number;
}

/**
 * Get disease modifying medications by platform-specific ATC-codes
 * @param {IMedication[]} documents - All medication documents
 * @param {string} platform - Platform (eg. sma, parkinson, ...)
 * @returns {IMedication[]} Medication documents filtered by platform-specific ATC-codes to only include disease modifying medications
 */
export const getDiseaseModifyingMedications = (
  medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined,
  documents: IMedication[],
  platform?: string,
): IMedication[] => {
  const atcCodesByPlatform = getAtcCodesByPlatform(medicationSettings, platform);
  if (platform && atcCodesByPlatform && Array.isArray(documents)) {
    return documents?.filter((document) =>
      atcCodesByPlatform.find((code: string) => code === (document.atc ?? '')?.substring(0, code.length)),
    );
  }
  return documents;
};

/**
 * Regimen strengths into a string
 * @param {IStrengths} s - Strengths
 */
export const formatStrenghtString = (s: IStrengths): string => a2String(intersperse('/', values(s)));

/**
 * Sort given strengths
 * @param {IStrengths} s1 - First strengths
 * @param {IStrengths} s2 - Second strengths
 */
export const sortByStr = (s1?: IStrengths, s2?: IStrengths): number => {
  // By which key the strengths are sorted by (usually the first one)
  const sortedKey = s1 ? Object.keys(s1)[0] : s2 ? Object.keys(s2)[0] : '';

  const first =
    s1 && !isNaN(parseInt(s1[sortedKey]?.split(' ')[0], 10)) ? parseInt(s1[sortedKey].split(' ')[0], 10) : 0;
  const second =
    s2 && !isNaN(parseInt(s2[sortedKey]?.split(' ')[0], 10)) ? parseInt(s2[sortedKey].split(' ')[0], 10) : 0;

  return first > second ? 1 : second > first ? -1 : 0;
};

/**
 * Match medication name to its regimen type
 * @param {string} medication - Medication name
 * @param {string} dosageForm - Medication dosage form
 * @returns {string} Regimen type
 */
export const getRegimenType = (
  medication: string | undefined,
  substances?: string,
  dosageForm?: string,
): string | undefined => {
  if (substances && dosageForm) {
    const matchRegimen =
      regimenTypesBySubstanceAndDosageform.find((s) => s.substance.test(substances) && s.dosageForm.test(dosageForm))
        ?.regimen || null;
    if (matchRegimen) return matchRegimen;
  }
  const type = Object.keys(regimenTypesByMed).map((reg) => {
    if (medication && regimenTypesByMed[reg].includes(medication?.toUpperCase())) {
      return reg;
    } else return undefined;
  });
  return type.find((t) => !!t) || 'default';
};

/**
 * Get possible medication regimen types by medication, platform and dosage form
 * @param {string} medication - Medication name
 * @param {string} platform - Platform (eg. sma, parkinson, ...)
 * @param {string} dosageForm - Dosage form, i.e. a string possibly containing word "liuos"
 * @return {string[]} List of regimen types
 */
export const getRegimenOptions = (
  medication: string | undefined,
  platform?: string,
  isClinicalStudy?: boolean[],
): string[] => {
  if (path([0], isClinicalStudy) === true) {
    return regimenTypesByPlatform['other'];
  }

  const regimenType = getRegimenType(medication);
  let regimens = regimenType ? [regimenType] : [];

  // regimen types by platform
  if (platform && regimenTypesByPlatform[platform]) {
    regimens = [...regimens, ...regimenTypesByPlatform[platform]];
  } else {
    regimens = [...regimens, ...regimenTypesByPlatform['other']];
  }
  const regimTypes = uniq(regimens);

  return regimTypes;
};

/**
 * Check if medication has ended
 * @param {IMedication} document - Medication document to be checked
 * @return {boolean} True if hasEnded is true and endDate has passed or is empty, false otherwise
 */
export const medicationEnded = (document: IMedication): boolean => {
  if (document.hasEnded?.[0] === true) {
    if (document.endDate && sortPartialDate(document.endDate, nowPartialDate()) === 1) {
      return false;
    }
    return true;
  }
  return false;
};

export const numberOfMedicines = (documents: IMedication[]): number | undefined => {
  // Filter out medications that have ended
  const meds =
    documents.length > 0
      ? documents.filter((item: IMedication) => medicationEnded(item) !== true).map((item) => item.medicationName)
      : [];

  if (meds.length > 0) {
    // Filter out duplicates here
    return meds.filter((item, index) => meds.indexOf(item) === index).length;
  } else {
    return undefined;
  }
};

/**
 * Check whether document has an active pause.
 * @param doc document whose pauses to check
 * @returns true if document has an active pause, false if not
 */
export const pauseIsActive = (doc: IMedication): boolean => {
  // pause is not active if there are no pauses or if medication is ended
  if (!doc.pauses || doc.pauses.length < 1 || medicationEnded(doc)) return false;
  // pause has been started if start time is now or in the past, not in the future
  const startedPauses = doc.pauses.filter((p: IMedicationPause) => {
    const startInPast: boolean = sortPartialDate(p.startDate, nowPartialDate()) === -1;
    const startNowOrEarlierToday: boolean =
      sortPartialDate(p.startDate, nowPartialDate()) === 0 && sortTime(p.startTime, nowTime()) <= 0;
    return startInPast || startNowOrEarlierToday;
  });
  // pause is still active if it has no end, it ends in a later day or later today
  const stillActivePauses = startedPauses.filter((p: IMedicationPause) => {
    const noEnd = !p.endDate || (p.endDate && !isPartialDate(p.endDate));
    const endInFuture = sortPartialDate(nowPartialDate(), p.endDate) === -1;
    const endLaterToday = sortPartialDate(nowPartialDate(), p.endDate) === 0 && sortTime(p.endTime, nowTime()) > 0;
    return noEnd || endInFuture || endLaterToday;
  });
  return stillActivePauses.length >= 1;
};

/**
 * Find active medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of active medication documents
 */
export const findActiveMedications = (d: Array<any>): Array<IMedication> => {
  const activeMeds = d.filter((d) => d._type === 'medication' && !medicationEnded(d) && !pauseIsActive(d));
  return activeMeds;
};

/**
 * Find paused medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of paused medication documents
 */
export const findPausedMedications = (d: Array<any>): Array<IMedication> => {
  const pausedMeds = d.filter((d) => d._type === 'medication' && !medicationEnded(d) && pauseIsActive(d));
  return pausedMeds;
};

/**
 * Find ended medication documents
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedication>} Array of ended medication documents
 */
export const findEndedMedications = (d: Array<any>): Array<IMedication> =>
  d.filter((d) => d._type === 'medication' && medicationEnded(d));

/**
 * Find other treatments
 * @param {Array<any>} d Array of documents
 * @returns {Array<IMedicationOtherTreatment>} Array of other treatments
 */
export const findOtherTreatments = (d: Array<any>): Array<IMedicationOtherTreatment> =>
  d.filter((d) => d._type === 'otherTreatment' || d._type === 'procedure');

/**
 * Find periods without medication
 * @param {Array<any>} d Array of documents
 * @returns {Array<IPeriodWithoutMedication>} Array of periods
 */
export const findPeriodsWithoutMedication = (d: Array<any>): Array<IPeriodWithoutMedication> =>
  d.filter((d) => d._type === 'periodWithoutMedication');

/**
 * See if locked regimen can be edited based on regimen type and document status
 * @param {IMedication} d - Medication document
 * @returns {boolean} Can or cannot edit
 */
export const canEditLockedRegimen = (d: IMedication, regimen: IRegimenBasics): boolean => {
  if (!d._lockedFor) return false;
  if (isParkinsonInfusionType(d) && regimen.regimenType === 'custom') return true;
  return false;
};

export interface IFilterSettings {
  medicationName?: string;
  date?: { startDate?: PartialDate; endDate?: PartialDate };
  status?: Array<'inUse' | 'toStart' | 'paused' | 'ended'>;
  platformSpecificDrugsOnly?: boolean;
  regimenType?: Array<'default' | 'single-dose' | 'onDemand' | 'custom' | 'static' | 'other'>;
  source?: Array<'stellarq' | 'integration' | string>; // Strings are organization ids
}

/**
 * Filter medications based on an object (of arrays) of different rules
 * @param {Object} settings - Filter by these rules
 * @returns
 */
export const filterMedications =
  (medicationSettings: IOrganizationNeuroSettings['medicationSettings'] | undefined, settings: IFilterSettings) =>
  (d: IMedication): boolean => {
    let filterMatch = true;

    const reduxStore: IState = store.getState();

    const ruleNames = Object.keys(settings);
    ruleNames.some((rule) => {
      /**
       * Go though the rules until any of them returns true, and changes filterMatch to false meaning that the medication should be filtered.
       * Every rule should return true only if the rule is set and the medication does not match it.
       */
      switch (rule) {
        case 'medicationName': {
          const filterName = settings['medicationName'];
          const regexp = new RegExp(`${filterName}`, 'i'); // case insensitive
          if (
            !filterName ||
            filterName.length === 0 ||
            d.medicationName?.match(regexp) ||
            d.medicationSubstances?.match(regexp)
          ) {
            break;
          } else {
            // No match for the medication
            filterMatch = false;
            // Break some-loop
            return true;
          }
        }

        case 'date': {
          const medicationStartDate = d.usageStartDate
            ? dateFromPartial(d.usageStartDate)
            : d.startDate && dateFromPartial(d.startDate);
          const medicationEndDate =
            Array.isArray(d.hasEnded) && d.hasEnded[0] === true && d.endDate && dateFromPartial(d.endDate);

          const filterStartDate = settings['date']?.startDate && dateFromPartial(settings['date']?.startDate);
          const filterEndDate = settings['date']?.endDate && dateFromPartial(settings['date']?.endDate);
          if (
            (medicationStartDate || 0) < (filterStartDate || 0) ||
            (medicationEndDate || 10000000000000) > (filterEndDate || 10000000000000) // If end date doesn't exists, then use year ~2286
          ) {
            filterMatch = false;
            return true;
          }
          break;
        }

        case 'status': {
          const statuses = settings['status'] || [];
          const medicationStartDate = d.usageStartDate
            ? dateFromPartial(d.usageStartDate)
            : d.startDate && dateFromPartial(d.startDate);

          if (statuses.length === 0) break;
          if (
            statuses.includes('inUse') &&
            (medicationStartDate?.valueOf() ?? 0) <= Date.now() && // This also shows medications without date
            !medicationEnded(d) &&
            !pauseIsActive(d)
          ) {
            break;
          }
          if (statuses.includes('toStart') && (medicationStartDate?.valueOf() || 0) > Date.now()) {
            break;
          }
          if (statuses.includes('paused') && !medicationEnded(d) && pauseIsActive(d)) {
            break;
          }
          if (statuses.includes('ended') && medicationEnded(d)) {
            break;
          }
          filterMatch = false;
          return true;
        }

        case 'platformSpecificDrugsOnly': {
          const platformSpecificDrugsOnly = settings['platformSpecificDrugsOnly'];
          const platform = reduxStore.session.platforms?.selected;

          let isSpecific = false;
          platform &&
            getAtcCodesByPlatform(medicationSettings, platform).forEach((datc) => {
              if (datc === (d.atc ?? '')?.substring(0, datc.length)) {
                isSpecific = true;
              }
              return;
            });
          if (!platformSpecificDrugsOnly || isSpecific) {
            break;
          }
          filterMatch = false;
          return true;
        }

        case 'regimenType': {
          if (!(d.regimen || []).find((d) => d.regimenType)) break;

          if (settings['regimenType'] && settings['regimenType'].length === 0) break;
          if (d.regimen?.some((r) => r.regimenType && settings['regimenType']?.includes(r.regimenType))) {
            break;
          }
          filterMatch = false;
          return true;
        }

        case 'source': {
          const sources = settings['source'] || [];
          const userOrg = reduxStore.session.data?.orgid;

          if (sources.length === 0) break;
          if (sources.includes('stellarq') && d._ownerOrg === userOrg && !(d._lockedFor && d._source)) {
            break;
          }
          if (sources.includes('integration') && d._ownerOrg === userOrg && d._lockedFor && d._source) {
            break;
          }
          if (sources.length > 0 && sources.includes(d._ownerOrg || '')) {
            break;
          }
          filterMatch = false;
          return true;
        }

        default:
          break;
      }
      return false;
    });

    return filterMatch;
  };
