import { sum, values, pick } from 'ramda';
import { controlProps } from '../../../utility/documentHandling';
import { exists, sortPartialDate } from 'neuro-utils';
import { omit, path } from 'Utility/ramdaReplacement';

// These fields are excluded when determining if UPDRS document has any score
const excludedFields: (keyof IUPDRSIII | 'info')[] = [
  'date',
  'state',
  'minutesSinceLevodopa',
  'retrospectiveData',
  'dyskinesiaPresent',
  'dyskinesiaInterference',
  'type',
  'info', // Removed, but might exist in some old documents?
  'testType',
  ...controlProps,
];

// Omit also these fields when determining if the UPDRS has been filled
const omittedFields = [...excludedFields, 'manualScore'];

// UPDRS 3 fields to be included when calculating the score
export const updrsFields: (keyof IUPDRSIII)[] = [
  'speechUpdrs',
  'facialExpressionUpdrs',
  'restTremorFace',
  'restTremorRightHand',
  'restTremorLeftHand',
  'restTremorRightLeg',
  'restTremorLeftLeg',
  'actionPosturalTremorRightHand',
  'actionPosturalTremorLeftHand',
  'rigidityNeckUpdrs',
  'rigidityRightHand',
  'rigidityLeftHand',
  'rigidityRightLeg',
  'rigidityLeftLeg',
  'fingerTapsRight',
  'fingerTapsLeft',
  'handMovementsRightUpdrs',
  'handMovementsLeftUpdrs',
  'pronationSupinationRightUpdrs',
  'pronationSupinationLeftUpdrs',
  'legAgilityRightUpdrs',
  'legAgilityLeftUpdrs',
  'arisingFromChairUpdrs',
  'postureUpdrs',
  'walking',
  'balance',
  'bradykinesiaHypokinesia',
];

// MDS UPDRS fields to be included when calculating the score
export const mdsUPDRSFields: (keyof IUPDRSIII)[] = [
  'speech',
  'facialExpression',
  'rigidityNeck',
  'rigidityRUE',
  'rigidityLUE',
  'rigidityRLE',
  'rigidityLLE',
  'fingerTappingRight',
  'fingerTappingLeft',
  'handMovementsRight',
  'handMovementsLeft',
  'pronationSupinationRight',
  'pronationSupinationLeft',
  'toeTappingRight',
  'toeTappingLeft',
  'legAgilityRight',
  'legAgilityLeft',
  'arisingFromChair',
  'gait',
  'freezingOfGait',
  'posturalStability',
  'posture',
  'globalSpontaneityOfMovement',
  'posturalTremorOfHandsRight',
  'posturalTremorOfHandsLeft',
  'kineticTremorOfHandsRight',
  'kineticTremorOfHandsLeft',
  'restTremorAmplitudeRUE',
  'restTremorAmplitudeLUE',
  'restTremorAmplitudeRLE',
  'restTremorAmplitudeLLE',
  'restTremorAmplitudeLipJaw',
  'constancyOfRestTremor',
];

/**
 * Function for checking if all the updrs fields are filled correctly
 * @param doc UPDRS document
 */
export const isUpdrsComplete = (doc?: IUPDRSIII | undefined, testType?: 'UPDRS' | 'MDSUPDRS'): boolean => {
  if (doc && testType && testType === 'MDSUPDRS' && doc.retrospectiveData?.[0] !== true) {
    return (
      mdsUPDRSFields.filter((key) => {
        return exists(doc[key]) && doc[key] !== '';
      }).length === mdsUPDRSFields.length
    );
  } else if (doc && testType && testType === 'UPDRS' && doc.retrospectiveData?.[0] !== true) {
    return (
      updrsFields.filter((key) => {
        return exists(doc[key]) && doc[key] !== '';
      }).length === updrsFields.length
    );
  } else if (doc && (doc.manualScore || doc.manualScore === 0) && doc.retrospectiveData?.[0] === true) {
    return true;
  }
  return false;
};

/**
 * Counts sum from all the numeric fields in the UPDRS document (if the field isn't marked as omitted)
 * @param {object} doc - UPDRS document where the sum is counted from
 * @returns {number} Sum of the fields
 */
export const updrsScoreSum = (doc?: IUPDRSIII): number => {
  if (!doc) return 0;

  // Replace other values
  const replaceNaN = (s: number | string): number => (typeof s === 'string' ? 0 : Number.isNaN(s) ? 0 : s);

  if (doc && doc.testType === 'MDSUPDRS') {
    return doc ? sum((values(pick(mdsUPDRSFields, doc)) as number[]).map(replaceNaN)) : 0;
  } else if (doc && doc.testType === 'UPDRS') {
    return doc ? sum((values(pick(updrsFields, doc)) as number[]).map(replaceNaN)) : 0;
  }
  return 0;
};

/**
 * Check whether the UPDRS document score is MDS or retrospective
 * @param doc - UPDRS document where that is inspected
 * @returns {number} Right score or 0
 */
const retroOrMDS = (doc?: IUPDRSIII): number => {
  if (!doc) return 0;
  if (doc.retrospectiveData?.[0] !== true) return updrsScoreSum(doc);
  return doc.manualScore && doc.manualScore.valueOf() !== 0 ? doc.manualScore.valueOf() : 0;
};

/**
 * Calculate how much (percentage) the score has improved in Levodopatest/DBSEffect
 * @param {IUPDRSIII} beforeDoc Document before test
 * @param {IUPDRSIII} afterDoc Document after test
 */
export const calculateScore = (beforeDoc?: IUPDRSIII, afterDoc?: IUPDRSIII): number => {
  return retroOrMDS(beforeDoc) !== 0
    ? ((retroOrMDS(beforeDoc) - retroOrMDS(afterDoc)) / retroOrMDS(beforeDoc)) * 100
    : 0;
};
/**
 * Get UPDRS III document from all documents by id
 * @param  {string|undefined} id - Document id
 * @param  {Array<TDocument>|undefined} documents - Documents array
 * @returns {IUPDRSIII|undefined} ID matched document
 */
export const getIIIDoc = (id?: string, documents?: Array<TDocument>): IUPDRSIII | undefined =>
  id && documents ? (documents.find((d) => d._id === id) as IUPDRSIII) : undefined;

/**
 * Calculate Levodopatest or DBS Effect
 * @param {TDocument[]} allDocuments - All documents
 * @param {ILevodopatest|IDBSEffect} d - Levodopatest or DBS Effect document
 * @returns {number|undefined} Score / effect (percentage)
 */
export const calculateLevodopaDBS = (allDocuments: TDocument[], d: ILevodopatest | IDBSEffect): number | undefined =>
  getIIIDoc(d['updrsIIIBeforeID'], allDocuments) && getIIIDoc(d['updrsIIIAfterID'], allDocuments)
    ? calculateScore(getIIIDoc(d['updrsIIIBeforeID'], allDocuments), getIIIDoc(d['updrsIIIAfterID'], allDocuments))
    : undefined;

/**
 * Get first document that matches type and has some score value
 * @param {string} type - Document type
 * @param {TDocument[]|undefined} docs - All documents
 * @returns {TDocument|undefined} Matched document or undefined
 */
export const sortAndTakeFirst = (type: string, docs?: TDocument[]): TDocument | undefined => {
  const documents = docs?.filter((doc) => values(omit<TDocument, any>(excludedFields, doc)).length > 0);
  return documents && type === 'updrs_iii'
    ? documents
        .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
        .reverse()
        .find((d) => d.type == null && d._type === type && isUpdrsComplete(d as IUPDRSIII, (d as IUPDRSIII).testType))
    : documents && type === 'dbsEffect'
      ? documents
          .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
          .reverse()
          .find(
            (d) =>
              (d._type === type &&
                isUpdrsComplete(getIIIDoc(path(['updrsIIIBeforeID'], d), documents), (d as IDBSEffect).testType) &&
                isUpdrsComplete(getIIIDoc(path(['updrsIIIAfterID'], d), documents), (d as IDBSEffect).testType)) ||
              (d._type === type && !!(d as IDBSEffect).manualScore) ||
              (d._type === type && (d as IDBSEffect).manualScore === 0),
          )
      : documents && type === 'levodopatest'
        ? documents
            .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
            .reverse()
            .find(
              (d) =>
                (d._type === type &&
                  isUpdrsComplete(getIIIDoc(path(['updrsIIIBeforeID'], d), documents), (d as ILevodopatest).testType) &&
                  isUpdrsComplete(getIIIDoc(path(['updrsIIIAfterID'], d), documents), (d as ILevodopatest).testType)) ||
                (d._type === type && !!(d as ILevodopatest).manualScore) ||
                (d._type === type && (d as ILevodopatest).manualScore === 0),
            )
        : documents
          ? documents
              .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
              .reverse()
              .find((d) => d._type === type)
          : undefined;
};

/**
 * Check if the document has any filled score values
 * @param {IUPDRSIII|undefined} doc - UPDRS document
 * @returns {boolean} Does the form have any filled values
 */
export const docHasValues = (doc?: IUPDRSIII): boolean => {
  if (!doc) return false;

  for (const field in doc) {
    if (!omittedFields.includes(field)) return true;
  }
  return false;
};

/**
 * Matches editing/viewing document id to all documents and returns type for the document
 * @param  {string} editing - Editing document id
 * @param  {TDocument[]} documents - All documents
 * @param  {string|undefined} viewing - Possible viewing document id
 * @returns {string|undefined} Document type
 */
export const docType = (editing: string, documents: TDocument[], viewing?: string): string | undefined =>
  (documents && editing) || (documents && viewing)
    ? path(
        ['_type'],
        documents.find((d: TDocument) => d._id === editing || d._id === viewing),
      ) || undefined
    : undefined;

export type TDocument = IUPDRSIII | IUPDRSV | ILevodopatest | IDBSEffect;
