import { sum, values } from 'ramda';
import { controlProps } from '../../../utility/documentHandling';
import { exists } from 'neuro-utils';
import { omit, path } from 'Utility/ramdaReplacement';

export type TDocument = IUHDRSMotorRatingScale;

export const docType = (documents: Array<TDocument>, editing: string, view?: IView): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        documents.find((d: TDocument) => d._id === editing),
      ) || undefined
    : documents && view?.viewing
      ? path(
          ['_type'],
          documents.find((d: TDocument) => d._id === view?.viewing),
        ) || undefined
      : undefined;

/**
 * UHDRS Motor rating scale score - doesnt check for manual score
 * @param {object} doc - UHDRS Motor rating sacle document where the sums are counted from
 * @returns {number| 'notCounted'} Sums of the fields
 */
export const uhdrsMotorRatingScaleAutomaticScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  const fieldCount = 31;

  const omitted: Array<keyof IUHDRSMotorRatingScale> = [...controlProps, 'date', 'shortTest', 'manualScore'];
  const scoreFields = values(omit(omitted, doc)).filter((v) => v || v === 0);

  // Return either score calculated from the form or 'notCounted'
  return scoreFields.length < fieldCount ? 'notCounted' : sum(scoreFields);
};

/**
 * Count score for UHDRS Motor rating scale document
 * @param {object} doc - UHRDS Motor document where the sums are counted from
 * @returns {number| 'notCounted' | 'cantCount'} Sums of the fields, "notCounted" if shorttest is recorded and all required fields are filled or "cantCount" if some required field is missing
 */
export const uhdrsMotorScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' | 'cantCount' => {
  if (!doc) return 'cantCount';

  const returnManual = (): number | 'cantCount' => doc.manualScore ?? 'cantCount'; // Hacky, refactor :)

  const fieldCount = 31;

  const shortTest = !!(
    doc.shortTest?.[0] &&
    !doc.totalScoreOnly?.[0] &&
    exists(doc.dysarthria) &&
    exists(doc.gait) &&
    exists(doc.tandemWalking) &&
    exists(doc.retropulsionPullTest)
  );

  const omitted = [...controlProps, 'date', 'shortTest', 'manualScore', 'totalScoreOnly'] as Array<
    keyof IUHDRSMotorRatingScale
  >;
  const scoreFields = values(omit(omitted, doc)).filter((v) => v || v === 0);

  // if all fields have been filled, return the sum of the fields, else return manual score or notCounted
  return scoreFields.length === fieldCount ? sum(scoreFields) : shortTest ? 'notCounted' : returnManual();
};

export const uhdrsMotorDysarthriaScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  if (doc.dysarthria === 0) return 0;
  return doc.dysarthria ? Number(doc.dysarthria) : 'notCounted';
};

export const uhdrsMotorRetropulsionPullTestScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  if (doc.retropulsionPullTest === 0) return 0;
  return doc.retropulsionPullTest ? Number(doc.retropulsionPullTest) : 'notCounted';
};

export const uhdrsMotorGaitScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  if (doc.gait === 0) return 0;
  return doc.gait ? Number(doc.gait) : 'notCounted';
};

export const uhdrsMotorTandemWalkingScore = (doc?: IUHDRSMotorRatingScale): number | 'notCounted' => {
  if (!doc) return 'notCounted';

  if (doc.tandemWalking === 0) return 0;
  return doc.tandemWalking ? Number(doc.tandemWalking) : 'notCounted';
};
