import { fetchWithOptions } from 'Utility/fetch';
import { makeLog } from 'Utility/logger';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { TTHREAD } from '../types/TThread';
import { IThreadListItem } from '../interfaces';
import { IDefaultFetcherInterface, queryParamsBuilder } from './util';

export interface IThreadCreate extends IDefaultFetcherInterface {
  name: 'create';
  /** patientid, topicid */
  queryParams: [string, string];
}
export interface IThreadDelete extends IDefaultFetcherInterface {
  name: 'delete';
  /** threadid */
  queryParams: [string];
}
export interface IThreadListGet extends IDefaultFetcherInterface {
  name: 'listOrg' | 'listPatient';
}
export interface IThreadComplete extends IDefaultFetcherInterface {
  name: 'complete';
}
export interface IThreadAssign extends IDefaultFetcherInterface {
  name: 'assign';
  /** threadid, userid */
  queryParams: [string, string];
}
export interface IThreadUnassign extends IDefaultFetcherInterface {
  name: 'unassign';
  /** threadid */
  queryParams: [string];
}
export interface IThreadComplete extends IDefaultFetcherInterface {
  name: 'complete';
  /** threadid */
  queryParams: [string];
}
export interface IThreadUncomplete extends IDefaultFetcherInterface {
  name: 'uncomplete';
  /** threadid */
  queryParams: [string];
}

type TThreadActions =
  | IThreadCreate
  | IThreadDelete
  | IThreadListGet
  | IThreadComplete
  | IThreadAssign
  | IThreadUnassign
  | IThreadComplete
  | IThreadUncomplete;

const basePath = '/api/messaging/v1';

const threadFetchConfig = {
  create: {
    method: 'POST',
    path: basePath + '/thread',
  },
  delete: {
    method: 'DELETE',
    path: basePath + '/thread',
  },
  listOrg: {
    method: 'GET',
    path: basePath + '/threads/org',
  },
  listPatient: {
    method: 'GET',
    path: basePath + '/threads/pat',
  },
  assign: {
    method: 'PATCH',
    path: basePath + '/thread/assign',
  },
  unassign: {
    method: 'PATCH',
    path: basePath + '/thread/unassign',
  },
  complete: {
    method: 'PATCH',
    path: basePath + '/thread/complete',
  },
  uncomplete: {
    method: 'PATCH',
    path: basePath + '/thread/uncomplete',
  },
};

type TDefaultReturn<N> = {
  returnType: N extends 'list' ? IThreadListItem[] | false : N extends 'create' ? TTHREAD | false : boolean;
};

export const threadFetchBuilder = <T>(builderProps: TThreadActions): Promise<TDefaultReturn<T>['returnType']> => {
  const actionName = builderProps.name;
  const path =
    threadFetchConfig[actionName].path +
    `${builderProps.queryParams ? queryParamsBuilder(builderProps.queryParams) : ''}`;

  return fetchWithOptions(path, { neurojwt: parseJWTFromCookie() }, { method: threadFetchConfig[actionName].method })
    .then((res) => {
      if (res.status === 200) {
        // Return response body parsed, or true if it cannot be parsed
        return res.json().catch(() => true);
      } else throw res;
    })
    .catch((res) => {
      makeLog(
        'Error',
        { name: `Thread ${actionName} failed`, message: `${threadFetchConfig[actionName].method} failed` },
        res,
      );
      return null;
    });
};
