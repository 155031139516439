import { getSPMSLorscheiderDate, getSPMSModifiedExpandDate } from 'neuro-calculation-commons';
import { append } from 'ramda';
import * as React from 'react';
import { exists, formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { capitalize } from '../../../utility/string';
import {
  dmdDiagnoses,
  epilepsyDiagnoses,
  huntingtonDiagnoses,
  IDiagnosisPairs,
  keoDiagnoses,
  reoDiagnoses,
  msDiagnoses,
  nmosdDiagnoses,
  parkinsonCriteriaType,
  parkinsonCriteriaTypes,
  parkinsonDiagnoses,
  sleepApneaDiagnoses,
  smaDiagnoses,
  TDocument,
  mgravisDiagnoses,
} from './definitions';
import DocumentCreationButton from '../../../components/DocumentCreationButton';
import { isIDiagnosis, isString } from '../../../utility/typeGuards';
import { fm } from 'Components/FormatMessage';
import { ellipsis } from 'Components/DashboardTile/TileContentMaker/components/common/commonStyles';
import { INeuroDocument } from 'neuro-data-structures';
import { isEmpty, path, uniq } from 'Utility/ramdaReplacement';

export const optionFormatter =
  (opt: number | string) =>
  (name: number | string): JSX.Element =>
    fm(`diagnosis.${opt}.${name}`);

export const generalOptionFormatter = (s?: React.ReactText | boolean): JSX.Element => fm(`general.${s || 'empty'}`);

export const diagnosisOptionsFormatter = (opt: React.ReactText): JSX.Element => fm(`diagnosis.opts.${opt}`);

/** Get diagnoses that belong to given platforms. */
export const getDiagnosesForPlatforms = (platforms: Array<string>): Array<TDiagnosis> => {
  return uniq([
    ...(platforms.includes('sma') ? smaDiagnoses : []),
    ...(platforms.includes('dmd') ? dmdDiagnoses : []),
    ...(platforms.includes('parkinson') ? parkinsonDiagnoses : []),
    ...(platforms.includes('ms') ? [...msDiagnoses, ...keoDiagnoses, ...reoDiagnoses, ...nmosdDiagnoses] : []),
    ...(platforms.includes('huntington') ? huntingtonDiagnoses : []),
    ...(platforms.includes('epilepsy') ? epilepsyDiagnoses : []),
    ...(platforms.includes('sleepApnea') ? sleepApneaDiagnoses : []),
    ...(platforms.includes('mgravis') ? mgravisDiagnoses : []),
    'unspecifiedDiagnosis',
  ]);
};

export const chooseDocumentHeader = (documents: Array<TDocument>, edit?: string): string => {
  let header = 'title';
  documents.map((d) => {
    if (d._id === edit) {
      switch (d._type) {
        case 'parkinsonSymptoms':
          header = 'parkinsonSymptomOnset';
          break;
        case 'parkinsonDiagnosticsCriteria':
          header = 'parkinsonCriteria';
          break;
        case 'msSymptoms':
          header = 'msEarlySymption';
          break;
        case 'msMriAndLaboratoryFindings':
          header = 'msMRIandLaboratoryFindings';
          break;
        case 'msDiagnosticCriteria':
          header = 'msDiagnosticCriteria.criteria.mainTitle.ms';
          break;
        case 'nmosdDiagnosticCriteria':
          header = 'nmosdDiagnosticCriteria.title';
          break;
        case 'msClinicalCourse':
          header = 'msClinicalCourse.title';
          break;
        case 'pd':
          header = 'pd.header';
          break;
        case 'msa':
          header = `${
            (d._commitsLength === 1 && d._editing) || ('version' in d && d.version === '2022') ? 'msa2022' : 'msa'
          }.header`;
          break;
        case 'psp':
          header = 'psp.header';
          break;
        case 'dlb':
          header = 'dlb.header';
          break;
        case 'cbd':
          header = 'cbd.header';
          break;
        case 'ftd':
          header = 'ftd.header';
          break;
        case 'ppa':
          header = 'ppa.header';
          break;
        case 'huntingtonSymptoms':
          header = 'huntingtonSymptomOnset';
          break;
        case 'htt':
          header = 'huntington.htt.title';
          break;
        case 'uhdrsConfidenceIndex':
          header = 'huntington.uhdrs.title';
          break;
        case 'seizureType':
          header = 'epilepsy.seizureType.title';
          break;
        case 'syndrome':
          header = 'epilepsy.syndrome.title';
          break;
        case 'etiology':
          header = 'epilepsy.etiology.title';
          break;
        case 'sleepApneaFirstVisit':
          header = 'sleepApnea.sleepApneaFirstVisit.title';
          break;
        case 'respiratoryFirstVisit':
          header = 'sleepApnea.respiratoryFirstVisit.title';
          break;
        case 'sleepApneaSeverity':
          header = 'sleepApnea.sleepApneaSeverity.title';
          break;
        case 'performanceRating':
          header = 'sleepApnea.performanceRating.title';
          break;
        case 'ninmtTreatmentPeriod':
          header = 'basicTreatmentInformation';
          break;
        case 'indicationForTreatment':
          header = 'ninmt.indicationForTreatment.title';
          break;
        case 'contraIndicationsToTreatment':
          header = 'ninmt.contraIndicationsToTreatment.title';
          break;
        case 'symptomsAndLocations':
          header = 'ninmt.symptomsAndLocations.title';
          break;
        case 'referringPhysicianAndUnit':
          header = 'ninmt.referringPhysicianAndUnit.title';
          break;
        case 'symptomsDetails':
          header = 'ninmt.symptomsDetails.title';
          break;
        default:
          header = 'title';
          break;
      }
    }
  });
  return header;
};

export const arrayOfDiagnoses = (documents: Array<TDocument>): Array<TDiagnosis> => {
  const diagnoses: Array<TDiagnosis> = [];
  documents.map((d) => {
    if ('diagnosis' in d) {
      const diagnosis = d as IDiagnosis;
      if (diagnosis.diagnosis) diagnoses.push(diagnosis.diagnosis as TDiagnosis);
    }
  });
  return diagnoses;
};

const hasDiagnosis = (n: unknown): n is Required<IDiagnosis> => isString((n as IDiagnosis).diagnosis);

/**
 * Get all diagnoses allowed to add by session platforms, capabilities and whether they already exist.
 * @param args.docs All diagnosis documents of a patient.
 * @param args.platforms List of platforms in current session.
 * @param args.capabilityGroups Capability groups in current session.
 * @returns List of diagnoses allowed to add for current session.
 */
export const getAddableDiagnoses = (args: { docs: IDiagnosis[]; platforms: Platform[] }): TDiagnosis[] => {
  const { docs, platforms } = args;

  // existing diagnoses are not allowed
  const existingDiagnoses = docs
    .filter((d) => (d.diagnosis === 'unspecifiedDiagnosis' ? false : hasDiagnosis(d) && d._editing === false))
    .map((d) => d.diagnosis);

  /** All diagnoses relevant to current session's platforms. */
  const dxsOfPlatforms = getDiagnosesForPlatforms(platforms);
  // filter out existing diagnoses
  const result = dxsOfPlatforms.filter((dx) => {
    return !existingDiagnoses.includes(dx);
  });

  // prune duplicates
  return uniq(result);
};

export const newButton = (
  type: string,
  onClick: (e: React.MouseEvent<Element>) => void,
  text: string,
  width?: number,
  alt?: true,
  disabled?: boolean,
  disabledTooltip?: string,
  newStyle?: boolean,
  document?: IControlProps,
): JSX.Element => {
  // if (text === 'general.edit' && !document) {
  //   throw new Error('Document required');
  // }
  return (
    <DocumentCreationButton
      name={type}
      document={document}
      text={text}
      newStyle={newStyle}
      onClick={onClick}
      width={width ?? 15}
      height={3}
      fontSize={16}
      alternate={alt}
      disabled={disabled}
      disabledTooltip={fm(disabledTooltip ?? 'general.documentLocked')}
      uppercase={!newStyle}
    />
  );
};

export const setDiagnosis = (
  fmString: (s: string) => string,
  diagnosis: IDiagnosis,
  courses?: Array<IMSType | undefined>,
): JSX.Element | string => {
  const thisDiagnosis = diagnosis.diagnosis ?? 'UNS';
  if (thisDiagnosis === 'G35') {
    return (
      <React.Fragment>
        {diagnosis.diagnosis}
        {courses && courses.length > 0 && courses[0]?.type ? ' (' : ''}
        {courses &&
        courses.length > 0 &&
        courses[0]?.type &&
        (courses[0].type === 'cantDefine' || courses[0].type === 'unknown')
          ? 'NA'
          : courses && courses.length > 0 && courses[0]?.type
            ? fmString(
                `diagnosis.msClinicalCourse.opts.${(courses ? [...courses] : [])
                  .sort((c1, c2) => sortPartialDate(c1?.date, c2?.date))
                  .reverse()[0]?.type}`,
              )
            : ''}
        {courses && courses.length > 0 && courses[0]?.type ? ')' : ''}
      </React.Fragment>
    );
  } else if (thisDiagnosis.includes('unspecified')) {
    return fmString(`diagnosis.opts.${thisDiagnosis}`);
  } else if (parkinsonDiagnoses.includes(thisDiagnosis)) {
    return <React.Fragment>{diagnosis.diagnosis}</React.Fragment>;
  } else if (thisDiagnosis === 'G70.0') {
    const formOfDisease = diagnosis.formOfDisease?.[0];
    return (
      <React.Fragment>
        {diagnosis.diagnosis}
        {formOfDisease &&
          ` (${
            formOfDisease.formOfDisease === 'generalized'
              ? fmString(`diagnosis.mgravis.formOfDisease.generalizedShort`)
              : formOfDisease.formOfDisease === 'ocular'
                ? fmString(`diagnosis.mgravis.formOfDisease.ocularShort`)
                : ''
          })`}
      </React.Fragment>
    );
  } else {
    return thisDiagnosis;
  }
};

const appendParkinsonCriteria = (documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  const criterias = (
    documents.filter((document: TDocument) =>
      parkinsonCriteriaTypes.includes(document._type),
    ) as Array<parkinsonCriteriaType>
  )
    .sort((n1: parkinsonCriteriaType, n2: parkinsonCriteriaType) => sortPartialDate(n1.date, n2.date))
    .reverse();
  const firstSymptomsDate = formatPartialDate(
    (documents.filter((document: TDocument) => document._type === 'parkinsonSymptoms') as IParkinsonSymptoms[])?.[0]
      ?.symptomOnsetDate,
  );
  const parkinsonCriteriaArray = [
    {
      header: fm('diagnosis.symptomOnsetDate'),
      value: firstSymptomsDate !== '' ? firstSymptomsDate : fm('general.noData'),
    },
    {
      header: (
        <>
          {fm('diagnosis.parkinsonLatestDiagnosticCriteria')}
          {criterias[0] && (
            <>
              {` (`}
              {fm(`diagnosis.${criterias[0]._type}.shortTitle`)}
              {`)`}
            </>
          )}
        </>
      ),
      value: criterias[0] ? <>{formatPartialDate(criterias[0].date)}</> : fm('general.noData'),
    },
  ];
  return parkinsonCriteriaArray;
};

const getAlleleLengths = (httDoc: IHTT): { [key in 'allele1' | 'allele2']: number | '-' } => {
  return {
    allele1: path(['cagRepeatLengths', 0, 'cagLength'], httDoc) ?? '-',
    allele2: path(['cagRepeatLengths', 1, 'cagLength'], httDoc) ?? '-',
  };
};

const appendHuntingtonInfo = (documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  let huntingtonInfoArray = [
    {
      header: fm('diagnosis.symptomOnsetDate'),
      value: formatPartialDate(
        (
          documents.filter((document: TDocument) => document._type === 'huntingtonSymptoms') as IHuntingtonSymptoms[]
        )?.[0]?.symptomOnsetDate,
      ),
    } as IDiagnosisPairs,
  ];
  const httTest = (documents.filter((document: TDocument) => document._type === 'htt') as IHTT[])?.[0];
  const uhdrsIndex: IUHDRSDiagnosticConfidenceIndex = documents
    .filter((d) => d._type === 'uhdrsConfidenceIndex')
    .sort((d1: IUHDRSDiagnosticConfidenceIndex, d2: IUHDRSDiagnosticConfidenceIndex) =>
      sortPartialDate(d1.date, d2.date),
    )
    .reverse()?.[0];
  const lastHuntingtonDiagnosis = (
    documents
      .filter((d) => d._type === 'diagnosis' && huntingtonDiagnoses.includes((d as IDiagnosis).diagnosis))
      .sort((d1: IDiagnosis, d2: IDiagnosis) => sortPartialDate(d1.date, d2.date))
      .reverse()?.[0] as IDiagnosis
  )?.diagnosis;
  if (httTest) {
    huntingtonInfoArray = append(
      {
        header: fm('diagnosis.huntington.htt.title'),
        value: fm(`diagnosis.huntington.htt.opts.doneNotDoneUnknown.${httTest?.status}`),
      } as IDiagnosisPairs,
      huntingtonInfoArray,
    );
    if (httTest?.status === 'done' && httTest.cagRepeatLengths) {
      huntingtonInfoArray = append(
        {
          header: <>{fm('diagnosis.huntington.htt.cagRepeatLengths')}</>,
          value: <>{`${getAlleleLengths(httTest).allele1} / ${getAlleleLengths(httTest).allele2}`}</>,
        } as IDiagnosisPairs,
        huntingtonInfoArray,
      );
    }
  }
  if (uhdrsIndex && lastHuntingtonDiagnosis === 'Z82') {
    huntingtonInfoArray = append(
      {
        header: fm('diagnosis.huntington.uhdrs.dash.title'),
        value:
          uhdrsIndex.index && uhdrsIndex.date ? (
            <>
              <div>{formatPartialDate(uhdrsIndex.date)}</div>
              <div>{fm(`diagnosis.huntington.uhdrs.dash.opts.${uhdrsIndex.index}`)}</div>
            </>
          ) : (
            '-'
          ),
      } as IDiagnosisPairs,
      huntingtonInfoArray,
    );
  }
  return huntingtonInfoArray;
};

const appendEpilepsyInfo = (documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  let epilepsyInfoArray = [] as Array<IDiagnosisPairs>;
  type TEpilepsyDocument = ISeizureType | ISyndrome | IEtiology;

  const epilepsyDocuments: Array<TEpilepsyDocument[]> = ['seizureType', 'syndrome', 'etiology'].map((type) =>
    documents.filter((document: TDocument) => document._type === type),
  );

  const sortAndGetLatest = (documents: Array<ISyndrome | IEtiology>): ISyndrome | IEtiology =>
    documents
      .sort((n1: ISyndrome | IEtiology, n2: ISyndrome | IEtiology) => n1._cdate - n2._cdate)
      .sort((n1: ISyndrome | IEtiology, n2: ISyndrome | IEtiology) => sortPartialDate(n1.date, n2.date))
      .reverse()?.[0];

  const epilepsyType = (): string | undefined => {
    let [seizureTypes1x, seizureTypes2x, seizureTypes3x4x] = [0, 0, 0];
    if (epilepsyDocuments[0].length < 1) {
      return undefined;
    }
    epilepsyDocuments[0].forEach((s: ISeizureType): void => {
      if (path(['classification', 'classification'], s)) {
        s.classification?.classification.charAt(0) === '1'
          ? seizureTypes1x++
          : s.classification?.classification.charAt(0) === '2'
            ? seizureTypes2x++
            : seizureTypes3x4x++;
      }
    });
    if (seizureTypes1x > 0 && seizureTypes2x === 0 && seizureTypes3x4x === 0) {
      return 'generalized';
    }
    if (seizureTypes2x > 0 && seizureTypes1x === 0 && seizureTypes3x4x === 0) {
      return 'local';
    }
    if (seizureTypes2x > 0 && seizureTypes1x > 0 && seizureTypes3x4x === 0) {
      return 'localAndGeneralized';
    }
    return 'unknown';
  };
  const etiology: IEtiology = sortAndGetLatest(epilepsyDocuments[2]);

  const etiologySubClasses =
    etiology &&
    !['metabolism', 'unknown'].includes(etiology?.class ?? '') &&
    etiology.subClasses &&
    etiology.subClasses
      .map((subC) => (subC as { [key: string]: any })[`subClass${capitalize(etiology.class ?? '')}`])
      .filter((subC) => exists(subC));

  const etiologySpecificClass =
    etiology && etiology.subClasses && etiology.class === 'structural'
      ? etiology.subClasses.map((subC) => subC.specificClass)
      : undefined;

  if (epilepsyType()) {
    epilepsyInfoArray = append(
      {
        header: fm('diagnosis.epilepsy.seizureType.epilepsyType.title'),
        value: fm(`diagnosis.epilepsy.seizureType.epilepsyType.opts.${epilepsyType()}`),
      } as IDiagnosisPairs,
      epilepsyInfoArray,
    );
  }
  if (etiology && etiology.class) {
    epilepsyInfoArray = append(
      {
        header: fm('diagnosis.epilepsy.etiology.title'),
        value: (
          <>
            {fm(`diagnosis.epilepsy.etiology.opts.${etiology.class}`)}
            {etiologySubClasses && etiologySubClasses.length > 0 ? ': ' : ''}
            {etiologySubClasses &&
              etiologySubClasses.map((subC, i) =>
                etiologySubClasses.length > 1 ? (
                  <div key={subC + i}>
                    {`${i + 1}) `}
                    {subC && fm(`diagnosis.epilepsy.etiology.opts.${subC}`)}
                    {etiologySubClasses && etiologySpecificClass ? ': ' : ''}
                    {etiologySpecificClass?.[i]
                      ? fm(`diagnosis.epilepsy.etiology.opts.${etiologySpecificClass[i]}`)
                      : ''}
                  </div>
                ) : (
                  <React.Fragment key={subC + i}>
                    {subC && fm(`diagnosis.epilepsy.etiology.opts.${subC}`)}
                    {etiologySubClasses && etiologySpecificClass ? ': ' : ''}
                    {etiologySpecificClass?.[i]
                      ? fm(`diagnosis.epilepsy.etiology.opts.${etiologySpecificClass[i]}`)
                      : ''}
                  </React.Fragment>
                ),
              )}
          </>
        ),
      } as IDiagnosisPairs,
      epilepsyInfoArray,
    );
  }
  return epilepsyInfoArray;
};

const appendSleepApneaInfo = (documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  const sleepApneaSeverities: ISleepApneaSeverity[] = documents.filter((d) => d._type === 'sleepApneaSeverity');
  const performanceRatings: IPerformanceRating[] = documents.filter((d) => d._type === 'performanceRating');
  const respiratoryFailureDG = !isEmpty(
    documents.filter(
      (doc) =>
        'diagnosis' in doc &&
        (doc.diagnosis === 'J96.1' || doc.diagnosis === 'J96.9' || doc.diagnosis === 'respiratoryFailureSuspicion'),
    ),
  );
  const sortAndGetLatest = (
    documents: Array<IPerformanceRating | ISleepApneaSeverity>,
  ): IPerformanceRating | ISleepApneaSeverity =>
    documents
      .sort(
        (n1: IPerformanceRating | ISleepApneaSeverity, n2: IPerformanceRating | ISleepApneaSeverity) =>
          n1._cdate - n2._cdate,
      )
      .sort((n1: IPerformanceRating | ISleepApneaSeverity, n2: IPerformanceRating | ISleepApneaSeverity) =>
        sortPartialDate(n1.date, n2.date),
      )
      .reverse()?.[0];

  let sleepApneaInfoArray = [];
  sortAndGetLatest(performanceRatings) &&
    sleepApneaInfoArray.push({
      header: fm('diagnosis.sleepApnea.performanceRating.title'),
      value: (sortAndGetLatest(performanceRatings) as IPerformanceRating)?.rating,
      date: sortAndGetLatest(performanceRatings)?.date,
    } as IDiagnosisPairs);

  if (sleepApneaSeverities && sleepApneaSeverities.length > 0 && !respiratoryFailureDG) {
    sleepApneaInfoArray = append(
      {
        header: fm('diagnosis.sleepApnea.sleepApneaSeverity.title'),
        value: (
          <div style={{ ...ellipsis(10) } as React.CSSProperties}>
            {fm(
              `diagnosis.sleepApnea.sleepApneaSeverity.opts.${(
                sortAndGetLatest(sleepApneaSeverities) as ISleepApneaSeverity
              )?.severity}`,
            )}
          </div>
        ),
        date: sortAndGetLatest(sleepApneaSeverities)?.date,
      } as IDiagnosisPairs,
      sleepApneaInfoArray,
    );
  }
  return sleepApneaInfoArray;
};

const appendNinmtInfo = (documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  const latestTreatmentPeriod = documents
    .filter((d) => d._type === 'ninmtTreatmentPeriod')
    .sort((d1: ININMTTreatmentPeriod, d2: ININMTTreatmentPeriod) => sortPartialDate(d2.date, d1.date))[0];

  if (!latestTreatmentPeriod) return [];

  const treatmentPeriodId = latestTreatmentPeriod._id;

  const indicationForTreatment: IIndicationForTreatment | undefined = documents.find(
    (d) =>
      d._type === 'indicationForTreatment' && (d as IIndicationForTreatment).treatmentPeriodId === treatmentPeriodId,
  ) as IIndicationForTreatment;

  const contraIndicationsToTreatment: IContraIndicationToTreatment[] = documents.filter(
    (d) =>
      d._type === 'contraIndicationsToTreatment' &&
      (d as IContraIndicationToTreatment).treatmentPeriodId === treatmentPeriodId,
  ) as IContraIndicationToTreatment[];

  const sortAndGetLatest = (documents: Array<IContraIndicationToTreatment>): IContraIndicationToTreatment =>
    documents
      .sort((n1: IContraIndicationToTreatment, n2: IContraIndicationToTreatment) => n2._cdate - n1._cdate)
      .sort((n1: IContraIndicationToTreatment, n2: IContraIndicationToTreatment) =>
        sortPartialDate(n2.date, n1.date),
      )[0];

  const latestContraIndications = sortAndGetLatest(contraIndicationsToTreatment);
  const allLatestContraIndications = [
    ...(latestContraIndications?.tmsAbsoluteContraIndications ?? []),
    ...(latestContraIndications?.tmsRelativeContraIndications ?? []),
    ...(latestContraIndications?.dcsAbsoluteContraIndications ?? []),
  ];

  const ninmtInfoArray = [];

  const maxIndicationsShown = 2;

  const totalIndications = indicationForTreatment && (indicationForTreatment.indication?.length ?? 0);
  const totalContraIndications = latestContraIndications && allLatestContraIndications.length;

  indicationForTreatment &&
    !isEmpty(indicationForTreatment.indication) &&
    ninmtInfoArray.push({
      header: fm('diagnosis.ninmt.indicationForTreatment.title'),
      value: (
        <React.Fragment>
          {indicationForTreatment.indication?.slice(0, maxIndicationsShown).map(
            (s, i) =>
              i < maxIndicationsShown && (
                <div key={i} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {fm(`diagnosis.ninmt.indicationForTreatment.opts.${s}`)}
                </div>
              ),
          )}
          {exists(totalIndications) &&
            totalIndications > maxIndicationsShown &&
            fm('diagnosis.ninmt.indicationForTreatment.otherIndications', {
              amount: totalIndications - maxIndicationsShown,
            })}
        </React.Fragment>
      ),
    } as IDiagnosisPairs);
  sortAndGetLatest(contraIndicationsToTreatment) &&
    ninmtInfoArray.push({
      header: fm('diagnosis.ninmt.contraIndicationsToTreatment.title'),
      value: (
        <React.Fragment>
          <div>{formatPartialDate(sortAndGetLatest(contraIndicationsToTreatment)?.date)}</div>
          {allLatestContraIndications?.slice(0, maxIndicationsShown).map(
            (s, i) =>
              i < maxIndicationsShown && (
                <div key={i} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {fm(`diagnosis.ninmt.contraIndicationsToTreatment.opts.${s}`)}
                </div>
              ),
          )}
          {exists(totalContraIndications) &&
            totalContraIndications > maxIndicationsShown &&
            fm('diagnosis.ninmt.contraIndicationsToTreatment.otherContraIndications', {
              amount: totalContraIndications - maxIndicationsShown,
            })}
        </React.Fragment>
      ),
    } as IDiagnosisPairs);
  return ninmtInfoArray;
};

const setValue = (platform: string, diagnosis?: IDiagnosis): JSX.Element | string | number => {
  switch (platform) {
    case 'dmd':
      return ![undefined, null, 'c.'].includes(diagnosis?.dmdMutationName) ? diagnosis?.dmdMutationName ?? '' : '';
    default:
      return '';
  }
};

export const setCriteria = (documents: Array<TDocument>, platform: string): IPD | undefined => {
  if (platform === 'parkinson')
    return (documents as IPD[])
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .find((document: IPD) => parkinsonCriteriaTypes.includes(document._type));
  return documents.find((document: TDocument) => document._type === `${platform}DiagnosticsCriteria`);
};

export const setCourses = (documents: Array<TDocument>, platform: string): Array<IMSType | undefined> | undefined =>
  platform === 'ms' && documents.length > 0
    ? (documents.filter((d) => d._type === 'diagnosis') as IDiagnosis[]).find((d) => d.diagnosis === 'G35')
        ?.typeOfDisease
    : undefined;

const renderMsInfo = (docs: Array<INeuroDocument>): boolean =>
  getSPMSLorscheiderDate(docs) || getSPMSModifiedExpandDate(docs) ? true : false;

export const setDiagnosisInfo = (
  platform: string,
  documents: Array<TDocument>,
  reduxDocuments: Array<INeuroDocument>,
  diagnosis?: IDiagnosis,
): Array<IDiagnosisPairs> => {
  switch (platform) {
    case 'dmd':
      return [
        {
          header: fm('diagnosis.dmdMutationNameShort'),
          value: setValue(platform, diagnosis),
        },
      ];
    case 'parkinson':
      return documents ? appendParkinsonCriteria(documents) : [];
    case 'ms': {
      const secondDiagnosis = documents?.filter(
        (d): d is IDiagnosis =>
          isIDiagnosis(d) && d.diagnosis && ['G35', 'G36.0', 'G36.9', 'G37.9'].includes(d.diagnosis),
      )[1];

      return renderMsInfo(reduxDocuments)
        ? [
            // Check if there are more MS diagnosis than one
            secondDiagnosis
              ? // Then render another row to show the second latest diagnosis
                {
                  header: secondDiagnosis.diagnosis,
                  value: formatPartialDate(secondDiagnosis.date) ?? '',
                }
              : { header: '', value: '' },
            // Again check if there are more than one MS diagnosis
            secondDiagnosis
              ? // Then don't render early symptoms starting date due to space issues (OK according to requirements)
                {
                  header: '',
                  value: '',
                }
              : documents?.find((d) => d._type === 'msSymptoms')
                ? {
                    header: fm('diagnosis.symptomOnsetDate'),
                    value:
                      formatPartialDate(
                        (documents?.find((d) => d._type === 'msSymptoms') as IMsSymptoms).symptomOnsetDate,
                      ) ?? '',
                  }
                : {
                    header: '',
                    value: '',
                  },
            reduxDocuments && getSPMSModifiedExpandDate(reduxDocuments)
              ? {
                  header: (
                    <>
                      {fm('diagnosis.msType.possibleChangeOfMsType')}
                      {`: `}
                      {fm('diagnosis.msType.modifiedExpand')}
                    </>
                  ),
                  value: isPartialDate(getSPMSModifiedExpandDate(reduxDocuments))
                    ? formatPartialDate(getSPMSModifiedExpandDate(reduxDocuments) as PartialDate)
                    : '',
                }
              : {
                  header: '',
                  value: '',
                },
            reduxDocuments && getSPMSLorscheiderDate(reduxDocuments)
              ? {
                  header: (
                    <>
                      {fm('diagnosis.msType.possibleChangeOfMsType')}
                      {`: `}
                      {fm('diagnosis.msType.lorscheider')}
                    </>
                  ),
                  value: isPartialDate(getSPMSLorscheiderDate(reduxDocuments))
                    ? formatPartialDate(getSPMSLorscheiderDate(reduxDocuments) as PartialDate)
                    : '',
                }
              : {
                  header: '',
                  value: '',
                },
          ]
        : [
            // Check if there are more MS diagnosis than one
            secondDiagnosis
              ? {
                  header: secondDiagnosis.diagnosis,
                  value: formatPartialDate(secondDiagnosis.date) ?? '',
                }
              : {
                  header: '',
                  value: '',
                },
            documents?.find((d) => d._type === 'msSymptoms')
              ? {
                  header: fm('diagnosis.symptomOnsetDate'),
                  value:
                    formatPartialDate(
                      (documents?.find((d) => d._type === 'msSymptoms') as IMsSymptoms).symptomOnsetDate,
                    ) ?? '',
                }
              : {
                  header: '',
                  value: '',
                },
          ];
    }
    case 'huntington':
      return documents ? appendHuntingtonInfo(documents) : [];
    case 'epilepsy':
      return documents ? appendEpilepsyInfo(documents) : [];
    case 'sleepApnea':
      return documents ? appendSleepApneaInfo(documents) : [];
    case 'ninmt':
      return documents ? appendNinmtInfo(documents) : [];
    case 'mgravis': {
      const symptomsDoc = documents.find((d) => d._type === 'mgravisSymptoms') as IMGravisSymptoms | undefined;
      return symptomsDoc
        ? [
            {
              header: fm('diagnosis.mgravis.symptoms.date'),
              value: isPartialDate(symptomsDoc.date) ? formatPartialDate(symptomsDoc.date) : '-',
              key: 'symptomsDate',
            },
            {
              header: fm('diagnosis.mgravis.symptoms.age'),
              value: symptomsDoc.age ? symptomsDoc.age : '-',
              key: 'symptomsAge',
            },
          ]
        : [];
    }
    default:
      return [];
  }
};

export const setBasicTreatmentInfo = (platform: string, documents: Array<TDocument>): Array<IDiagnosisPairs> => {
  switch (platform) {
    case 'ninmt':
      return documents ? appendNinmtInfo(documents) : [];
    default:
      return [];
  }
};

export const drawHeader = (documents: Array<parkinsonCriteriaType>, type: string): boolean =>
  !!documents.find((d: parkinsonCriteriaType) => d._type === type);
