import { MenuItem, Select } from '@mui/material';
import * as React from 'react';
import colors from '../../../config/theme/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/system';

import { field, historyValue } from '../../../config/theme/componentTheme';
import { isEmpty } from 'Utility/ramdaReplacement';

const StyledDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ width }: { width?: number }) => ({
  maxWidth: `${width ? width + 'rem' : field.width}`,
}));

const StyledListItem = styled('div')({
  display: 'flex',
  padding: '0rem 0rem 0rem 1.4rem',
  border: `1px solid ${colors.mediumGray}`,
  borderRadius: '1.5rem',
  color: colors.darkGray,
  fontWeight: 600,
  fontSize: '1.4rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '3rem',
});

const StyledListTitle = styled('div')({
  paddingBottom: '0.22rem',
});

const StyledListIcon = styled('div')({
  fontSize: '2rem',
  color: colors.mediumGray,
  height: '3rem',
  '&:hover': {
    color: `${colors.mediumGray}90`,
    cursor: 'pointer',
  },
});

const textElements = (
  value?: Array<string | number>,
  optionFormatter?: (name: string | number) => JSX.Element | string,
  width?: number,
): JSX.Element => (
  <StyledDiv width={width} style={{ whiteSpace: 'pre-line' }}>
    {Array.isArray(value) && value.length > 0
      ? value.map(
          (v, i) =>
            (optionFormatter && typeof v === 'string' ? optionFormatter(v) : `${v}`) + (i < value.length ? '\n' : ''),
        )
      : value ?? '-'}
  </StyledDiv>
);

const SelectElements = ({
  name,
  value = [],
  options,
  onChange,
  optionFormatter,
  placeholder,
  width,
  minWidth,
  open,
  onOpen,
  onClose,
  autoSelect,
  autoSelectSingle,
  disabled,
  limit,
  tabulationTools,
}: Partial<IInputBasics & ISelectAndAdd>): JSX.Element => {
  const [selected, setSelected] = React.useState<string | number>('');

  // Inject value into options if its not there
  const optionsToString = options?.map((o) => `${o}`);
  if (value && !optionsToString?.includes(value.toString())) {
    options = value[0] || value[0] === 0 ? [...(options || []), value[0]] : options;
  }

  const handleSelection = (event: { target: { name?: string | undefined; value: any } }): void | undefined => {
    event.target.name && event.target.value && setSelected(event.target.value);
    handleAdd({ target: { name: name, value: event.target.value } });
  };

  const handleAdd = (event: { target: { name?: string | undefined; value: any } }): void | undefined => {
    setSelected('');
    const newValue = value.slice();
    newValue.push(event.target.value);
    event.target.name && onChange && onChange({ [event.target.name as string]: newValue });
  };

  const handleDelete = (index: number) => {
    const newValue = value.slice();
    newValue.splice(index, 1);
    name && onChange && onChange({ [name]: newValue });
  };

  React.useEffect(() => {
    // Automatically select the value if only one value, and prop is enabled
    if ((autoSelect || autoSelectSingle) && !value && options && (autoSelect || options.length === 1)) {
      handleSelection({ target: { name, value: options[0] } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const optionsFiltered = Array.isArray(options) ? options.filter((o) => !value.includes(o)) : [];

  const isDisabled = disabled || optionsFiltered.length === 0 || (typeof limit === 'number' && value.length === limit);

  const { tabIndex, inputRefs, identifier } = tabulationTools ?? {};

  React.useEffect(() => {
    if (inputRefs && typeof inputRefs === 'object' && identifier) {
      if (identifier && !inputRefs?.current[identifier]) {
        inputRefs.current[identifier] = React.createRef();
      }
    }
  });

  return (
    <React.Fragment>
      {Array.isArray(value) && value[0] && !isEmpty(value[0]) && (
        <StyledDiv width={width} style={{ marginBottom: '1rem' }}>
          {value.map((v, i) => (
            <StyledListItem key={i} style={{ marginBottom: i === value.length - 1 ? undefined : '1rem' }}>
              <StyledListTitle>{optionFormatter ? optionFormatter(v) : v}</StyledListTitle>
              <StyledListIcon onClick={() => handleDelete(i)}>
                <CancelIcon style={{ width: '3rem', height: '3rem' }} />
              </StyledListIcon>
            </StyledListItem>
          ))}
        </StyledDiv>
      )}
      <StyledDiv width={width}>
        <Select
          inputRef={identifier ? inputRefs?.current[identifier] : undefined}
          name={name}
          value={selected}
          onChange={
            isDisabled
              ? undefined
              : (e) => {
                  handleSelection(e);
                  setTimeout(() => {
                    (document.activeElement as HTMLDivElement | null)?.blur();
                  }, 0);
                }
          }
          fullWidth={true}
          displayEmpty={true}
          style={
            disabled || !selected
              ? {
                  color: colors.inputPlaceholder,
                  minWidth: minWidth || minWidth === 0 ? `${minWidth}rem` : '15rem',
                  maxWidth: width ? `${width}rem` : '100%',
                }
              : {
                  minWidth: minWidth || minWidth === 0 ? `${minWidth}rem` : '15rem',
                  maxWidth: width ? `${width}rem` : '100%',
                }
          }
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          sx={
            isDisabled
              ? {
                  pointerEvents: 'none',
                  ':before': { borderBottomColor: colors.lightGray },
                  ':after': { borderBottomColor: colors.lightGray },
                  '& .MuiSvgIcon-root': {
                    color: colors.lightGray,
                  },
                }
              : undefined
          }
          inputProps={{ tabIndex: disabled ? -1 : tabIndex, 'data-is-disabled': disabled }}
        >
          {placeholder && (
            <MenuItem key={name + 'placeholder'} value={''} disabled={true} style={{ fontStyle: 'italic' }}>
              {placeholder}
            </MenuItem>
          )}
          {optionsFiltered.map((o) => (
            <MenuItem key={o} value={o}>
              {optionFormatter ? optionFormatter(o) : o}
            </MenuItem>
          ))}
        </Select>
      </StyledDiv>
    </React.Fragment>
  );
};

const SelectAndAdd = ({
  editing = false,
  name,
  onChange,
  value,
  options,
  optionFormatter,
  placeholder,
  width,
  minWidth,
  open,
  onOpen,
  onClose,
  autoSelect = false,
  autoSelectSingle = false,
  disabled,
  limit,
  tabulationTools,
}: IInputBasics & ISelectAndAdd): JSX.Element =>
  !editing ? (
    <div style={historyValue}>{textElements(value, optionFormatter, width)}</div>
  ) : (
    <SelectElements
      name={name}
      value={Array.isArray(value) ? value : []}
      options={options}
      onChange={onChange}
      optionFormatter={optionFormatter}
      placeholder={placeholder}
      width={width}
      minWidth={minWidth}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      autoSelect={autoSelect}
      autoSelectSingle={autoSelectSingle}
      disabled={disabled}
      limit={limit}
      tabulationTools={tabulationTools}
    />
  );

export default SelectAndAdd;
