/**
 * Copied the old QuestionStepper and removed a lot of junk.
 * This component should be used for most simple question stepping needs.
 * If some special stuff is required try to implement it mostly in configs so
 * that this file doesn't get bloated with custom page/form specific things.
 */

import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { StepIconProps } from '@mui/material/StepIcon';
import { CheckCircle, Lens } from '@mui/icons-material';
import { path, values, keys } from 'ramda';
import * as React from 'react';
import { styled } from '@mui/system';

import { Container, Item } from '../Grid';

import colors from '../../config/theme/colors';
import { isIE } from '../../utility/isIE';

import QuestionItem from './QuestionItem';
import ActionButton from '../ActionButton';
import FormRow from 'Components/FormRow';

const StepIconComponent = () => <span />;
type TStepLabelProps = React.ComponentProps<typeof StepLabel>;

const StyledStepLabel = styled(({ ...other }: TStepLabelProps) => (
  <StepLabel StepIconComponent={StepIconComponent} {...other} />
))({
  padding: '0 3rem',
  height: '5rem',
});

const StyledSubStepLabel = styled(({ ...other }: TStepLabelProps) => <StepLabel {...other} />)({
  height: '3rem',
  cursor: 'pointer',
});

const StyledLabelText = styled('div')({
  color: `${colors.white} !important`,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledSubLabelText = styled('div')({
  color: `${colors.primaryText} !important`,
  fontSize: '2rem',
  fontWeight: 600,
});

const StepTitleQuestionScalable = styled('td')({
  maxWidth: '90rem',
  '@media only screen and (max-width: 1400px)': {
    maxWidth: '40rem',
  },
});

const instructionElement = (element: JSX.Element): JSX.Element => <div style={{ padding: '2rem 0' }}>{element}</div>;

// Some dynamic settings for QuestionItem sizes for them to scale better based on how many there are
const itemSize = (opts: []): number => (opts.length < 4 ? 3 : opts.length < 5 ? 2 : opts.length < 6 ? 2 : 3);
const itemSpacing = (opts: []): number => (opts.length < 4 ? 4 : opts.length < 5 ? 2 : 1);

// Choose icon for substep headers
const IconChooser =
  (value?: string | number, color?: string) =>
  (props: StepIconProps): JSX.Element => {
    const { active } = props;
    return value || value === 0 ? (
      <CheckCircle style={{ color: color ? color : colors.primary }} />
    ) : active ? (
      <Lens style={{ color: color ? color : colors.primary }} />
    ) : (
      <Lens color="disabled" />
    );
  };

// Make references for steps which are used with automatic scrolling
const makeRefsForSteps = (steps: IOwnProps['steps']): React.RefObject<HTMLDivElement>[] =>
  steps.map(() => React.useRef(null));

// Note: this can only work after transition is over
const elementInViewport = (el: HTMLDivElement, parent?: HTMLElement | null): boolean => {
  const bb = el.getBoundingClientRect();
  const lowerBound = 80;
  return (
    bb.top >= lowerBound &&
    bb.top <=
      ((parent
        ? parseInt(getComputedStyle(parent).getPropertyValue('height'))
        : window.innerHeight || document.documentElement.clientHeight) -
        lowerBound) /
        3
  );
};

const QuestionStepperSimpler = ({
  formData,
  steps,
  stepsLocales,
  getStepContent,
  viewing = false,
  parent,
  simpleViewing,
}: IOwnProps): JSX.Element => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [subStep, setSubStep] = React.useState(0);
  const [previousWasSubStep, setPreviousWasSubStep] = React.useState<boolean>(false);

  const refs = makeRefsForSteps(steps);

  const subRefs = React.useRef<HTMLDivElement[]>([]);

  React.useEffect(() => {
    subRefs.current = subRefs.current.slice(
      0,
      typeof steps[activeStep] === 'object' ? (values(steps[activeStep] as object)[0] as Array<string>).length : 0,
    );
  });

  const handleStepAndScroll = (nextIndex?: number, headerClicked?: boolean): void => {
    // const currentNode = refs[activeStep]?.current || undefined;
    const nextNode =
      refs[
        nextIndex || nextIndex === 0
          ? nextIndex - (previousWasSubStep && !headerClicked ? 2 : 0)
          : activeStep + (previousWasSubStep && !headerClicked ? 0 : 1)
      ]?.current || undefined;
    setActiveStep(nextIndex || nextIndex === 0 ? nextIndex : activeStep + 1);
    setSubStep(0);

    if (nextNode && !elementInViewport(nextNode)) {
      const scroll = (): void => {
        const scrollOffset = parent
          ? previousWasSubStep && !headerClicked
            ? 0
            : 50
          : previousWasSubStep && !headerClicked
            ? 50
            : 150;
        if (!isIE()) {
          (parent ? parent : window).scrollTo({ behavior: 'smooth', top: nextNode.offsetTop - scrollOffset });
        }
        // currentNode.removeEventListener('transitionend', scroll, false);
        // nextNode.removeEventListener('transitionend', scroll, false);
      };
      // currentNode.addEventListener('transitionend', scroll, false);
      // nextNode.addEventListener('transitionend', scroll, false);
      setTimeout(scroll, previousWasSubStep && !headerClicked ? 0 : 600);
    }
    setPreviousWasSubStep(false);
  };

  const handleNextStep = (): void => {
    handleStepAndScroll();
  };

  const handleNextSubStep = (nextIndex?: number): void => {
    const nextNode = subRefs.current[subStep + 1] || undefined;
    if (typeof steps[activeStep] === 'object') {
      const optValues = values(steps[activeStep] as object)[0] as string[];
      if (subStep < optValues.length - 1) {
        // If not the last sub question
        setSubStep((prevSubStep) => prevSubStep + 1);
        if (nextNode && nextNode.offsetParent !== null && !elementInViewport(nextNode, parent)) {
          const scroll = (): void => {
            const scrollOffset = parent ? 150 : 250;
            if (!isIE()) {
              (parent ? parent : window).scrollTo({ behavior: 'smooth', top: nextNode.offsetTop - scrollOffset });
            }
          };
          setTimeout(scroll, 600);
        }
      } else {
        handleStepAndScroll(nextIndex);
      }
    }
    setPreviousWasSubStep(true);
  };

  const onClickHeader = (index: number) => (): void => {
    handleStepAndScroll(index, true);
  };
  const onClickSubHeader = (index: number) => (): void => {
    setSubStep(index);
  };

  const onClickItem = (
    name: string,
    qNumber: number | string | undefined,
    onChange?: IOwnProps['formData']['onChange'],
    disabledNextIndex?: number,
    dontAdvance = false, // Dont advance to next question when item is clicked
  ): void => {
    if (!onChange) return;

    if (qNumber === formData.document[name]) {
      onChange({ [name]: '' });
    } else if (dontAdvance) {
      onChange({ [name]: qNumber });
    } else {
      if (disabledNextIndex && (disabledNextIndex > 0 || disabledNextIndex === -1)) {
        onChange({ [name]: qNumber });
        handleStepAndScroll(disabledNextIndex);
      } else {
        onChange({ [name]: qNumber });
        handleNextStep();
      }
    }
  };

  const onClickSubItem = (
    name: string,
    qNumber: number | string | undefined,
    onChange?: IOwnProps['formData']['onChange'],
    disabledNextIndex?: number,
  ): void => {
    if (!onChange) return;
    if (qNumber === formData.document[name]) {
      onChange({ [name]: '' });
    } else {
      onChange({ [name]: qNumber });
      handleNextSubStep(disabledNextIndex);
    }
  };

  // For finding next non-disabled question. Returns undefined if next one is not disabled. Returns last index when there are only disabled indexes left.
  const findNextNonDisabledIndex = (array: any[], currentIndex: number): number | undefined => {
    for (let i = 0; i < array.length; i++) {
      if (
        i > currentIndex &&
        ((typeof array[i] === 'string' && getStepContent(array[i]).disabled !== true) ||
          (typeof array[i] === 'object' && getStepContent(keys(array[i])[0] as string).disabled !== true))
      ) {
        if (i === currentIndex + 1) {
          return undefined;
        }
        return i;
      }
      if (
        i === array.length - 1 &&
        ((typeof array[i] === 'string' && getStepContent(array[i]).disabled === true) ||
          (typeof array[i] === 'object' && getStepContent(keys(array[i])[0] as string).disabled === true))
      ) {
        return -1;
      }
    }
    return undefined;
  };

  if (viewing && simpleViewing) {
    return (
      <div>
        {steps.map((step, i) => {
          if (typeof step === 'string') {
            return (
              <FormRow key={i} title={stepsLocales(step)} formatTitle={false}>
                <span style={{ fontWeight: 600 }}>
                  {formData?.document?.[step] || formData?.document?.[step] === 0
                    ? `${formData?.document?.[step]} - ${getStepContent(step).optsLocale(formData?.document?.[step])}`
                    : '-'}
                </span>
              </FormRow>
            );
          }
          // Not used anywhere yet, implement better if needed
          else {
            return (
              <FormRow
                key={i}
                title={stepsLocales(Object.keys(step)?.[0])}
                description={
                  <div>
                    {Object.values(step)[0].map((subStep) => {
                      return (
                        <div key={subStep} style={{ marginBottom: '0.5rem' }}>
                          {stepsLocales(subStep)}
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <div style={{ paddingTop: `${1 + 2.4}rem` }}>
                  {Object.values(step)[0].map((subStep) => {
                    return (
                      <div key={subStep} style={{ marginBottom: '0.5rem', fontWeight: 600, fontSize: '14px' }}>
                        {formData?.document?.[subStep] || formData?.document?.[subStep] === 0
                          ? `${formData?.document?.[subStep]} - ${getStepContent(subStep).optsLocale(
                              formData?.document?.[subStep],
                            )}`
                          : '-'}
                      </div>
                    );
                  })}
                </div>
              </FormRow>
            );
          }
        })}
      </div>
    );
  }

  return (
    <Stepper activeStep={activeStep} orientation="vertical" style={{ margin: '0', padding: '0' }} nonLinear={true}>
      {steps.map((name: string | object, index: number) =>
        typeof name === 'string' ? (
          <Step key={name} ref={refs[index]}>
            <StyledStepLabel
              style={
                getStepContent(name).disabled && getStepContent(name).disabled === true
                  ? { backgroundColor: colors.gray, cursor: 'default' }
                  : { backgroundColor: colors.primary, cursor: 'pointer' }
              }
              onClick={onClickHeader(index)}
              icon={undefined}
            >
              <StyledLabelText>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <StepTitleQuestionScalable
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          paddingRight: '5rem',
                        }}
                      >
                        {stepsLocales(name)}
                      </StepTitleQuestionScalable>
                      <td
                        style={{
                          textAlign: 'right',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          maxWidth: '20rem',
                          minWidth: '10rem',
                        }}
                      >
                        {getStepContent(name).customValue
                          ? getStepContent(name).customValue?.(formData)
                          : formData.document[name] &&
                              typeof formData.document[name] === 'string' &&
                              !getStepContent(name).treatValuesAsNumbers
                            ? getStepContent(name).optsLocale(formData.document[name])
                            : formData.document[name]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </StyledLabelText>
            </StyledStepLabel>
            <StepContent style={{ padding: '2rem 4rem 0rem 4rem' }}>
              {getStepContent(name).instruction && instructionElement(getStepContent(name).instruction || <span />)}
              <Container
                justifyContent={getStepContent(name).opts.length > 3 ? 'space-between' : 'space-around'}
                style={{ padding: '3rem 0' }}
                spacing={itemSpacing(getStepContent(name).opts as [])}
              >
                {getStepContent(name).opts.map((qNumber: number | string, i) => (
                  <Item
                    key={name + qNumber + i}
                    xs={itemSize(getStepContent(name).opts as [])}
                    style={{ marginBottom: getStepContent(name).opts.length > 5 ? '2rem' : undefined }}
                  >
                    <QuestionItem
                      qNumber={qNumber}
                      text={getStepContent(name).optsLocale(qNumber, i)}
                      onClick={
                        getStepContent(name).disabled && getStepContent(name).disabled === true
                          ? undefined
                          : !viewing
                            ? (): void =>
                                onClickItem(
                                  name,
                                  qNumber,
                                  formData.onChange,
                                  findNextNonDisabledIndex(steps, index) || undefined,
                                  getStepContent(name).nextButton, // Use next button instead of automatically going to next question
                                )
                            : undefined
                      }
                      selected={path(['document', name], formData) === qNumber}
                      height={getStepContent(name)?.height || undefined}
                      viewing={viewing}
                      disabled={getStepContent(name).disabled && getStepContent(name).disabled === true ? true : false}
                      showValue={getStepContent(name).treatValuesAsNumbers}
                    />
                  </Item>
                ))}
              </Container>
              {getStepContent(name).customElements?.(formData)}
              {getStepContent(name).nextButton && (
                <Container justifyContent="flex-end">
                  <Item>
                    <ActionButton text="general.next" onClick={handleNextStep} width={20} height={4} fontSize={16} />
                  </Item>
                </Container>
              )}
            </StepContent>
          </Step>
        ) : (
          // For steps with substeps. New stepper for them
          <Step key={Object.keys(name)[0]} ref={refs[index]}>
            <StyledStepLabel
              style={
                getStepContent(keys(name)[0]).disabled && getStepContent(keys(name)[0]).disabled === true
                  ? { backgroundColor: colors.gray, cursor: 'default' }
                  : { backgroundColor: colors.primary, cursor: 'pointer' }
              }
              onClick={onClickHeader(index)}
              icon={undefined}
            >
              <StyledLabelText>
                <Container alignItems="center">
                  <Item xs={12}>{stepsLocales(Object.keys(name)[0])}</Item>
                </Container>
              </StyledLabelText>
            </StyledStepLabel>
            <StepContent>
              {getStepContent(values(name)[0][0]).instruction &&
                instructionElement(getStepContent(values(name)[0][0]).instruction || <span />)}

              <Stepper activeStep={subStep} orientation="vertical">
                {values(name as { [key: string]: string[] })[0].map((n: string, i: number) => (
                  <Step
                    key={n}
                    ref={(el: HTMLDivElement): void => {
                      el && el !== null ? (subRefs.current[i] = el) : undefined;
                    }}
                  >
                    <StyledSubStepLabel
                      onClick={onClickSubHeader(i)}
                      StepIconComponent={IconChooser(
                        path(['document', n], formData),
                        getStepContent(keys(name)[0]).disabled && getStepContent(keys(name)[0]).disabled === true
                          ? colors.gray
                          : undefined,
                      )}
                    >
                      <StyledSubLabelText>
                        <Container alignItems="center">
                          <Item xs={12}>{n}</Item>
                        </Container>
                      </StyledSubLabelText>
                    </StyledSubStepLabel>
                    <StepContent>
                      <Container
                        justifyContent={isIE() ? 'space-around' : 'space-evenly'}
                        style={{ padding: '3rem 0' }}
                        spacing={itemSpacing(getStepContent(n).opts as [])}
                      >
                        {getStepContent(n).opts.map((qNumber: number | string, i) => (
                          <Item key={n + qNumber} xs={itemSize(getStepContent(n).opts as [])}>
                            <QuestionItem
                              qNumber={qNumber}
                              text={getStepContent(n).optsLocale(qNumber, i)}
                              onClick={
                                !viewing
                                  ? (): void =>
                                      onClickSubItem(
                                        n,
                                        qNumber,
                                        formData.onChange,
                                        findNextNonDisabledIndex(steps, index) || undefined,
                                      )
                                  : undefined
                              }
                              selected={path(['document', n], formData) === qNumber}
                              height={getStepContent(n).height || undefined}
                              viewing={viewing}
                              disabled={
                                getStepContent(keys(name)[0]).disabled === true ||
                                (getStepContent(n)?.disabledOptions || []).includes(qNumber)
                              }
                              disabledMessage={getStepContent(n)?.disabledMessage}
                            />
                          </Item>
                        ))}
                      </Container>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </StepContent>
          </Step>
        ),
      )}
    </Stepper>
  );
};

interface IOwnProps {
  formData: IFormData;
  steps: Array<string | { [key: string]: string[] }>;
  stepsLocales: (step: number | string) => string;
  getStepContent: (step: string) => {
    opts: (number | string)[]; // Option number or string that is saved as value
    optsLocale: (o: number | string, i?: number) => string; // Localizer for question string
    height?: number;
    instruction?: JSX.Element;
    disabled?: boolean;
    disabledOptions?: (number | string)[];
    disabledMessage?: JSX.Element | string;
    customElements?: (formData: IFormData) => JSX.Element; // Custom elements shown under/instead of the question items
    nextButton?: boolean; // Show next button instead of moving to next question when answer is clicked
    customValue?: (formData: IFormData) => JSX.Element; // Show as value instead of selected answer
    treatValuesAsNumbers?: true; // Show question values even if they are strings
  };
  viewing?: boolean;
  parent?: HTMLElement | null;
  simpleViewing?: boolean; // If true, viewing mode uses basic FormRow look instead of QuestionStepper look
}

export default QuestionStepperSimpler;
