import { IAddon, IData, IDataPoint, IEvent, TDescriptionTable } from 'Components/sq-graphics/interfaces';
import { getLEDDTimeseries } from 'neuro-calculation-commons';
import { exists, formatPartialDate, isPartialDate } from 'neuro-utils';
import { flatten } from 'ramda';
import {
  getCdrClass,
  getCdrTotalScore,
  getFtldCdrClass,
  getFtldCdrTotalScore,
  mmseTotalScore,
  mocaTotalScore,
} from 'Routes/Cognition/utils';
import { isUpdrsComplete, updrsScoreSum } from 'Routes/Updrs/utils';
import { calculateFBIMODScore, fbiModFields } from 'Routes/DiagnosticSurvey/utils';
import { isEmpty } from 'Utility/ramdaReplacement';

export const convertGeneTestToTimeline = (
  docs: Array<IGeneTest>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return undefined;
    const events: IEvent[] = [];
    events.push({
      date: dateFromPartialUpdateTimeFrame(doc.date),
      eventType: 'study',
      title: fm('geneTest.shortTitle'),
      description: [
        {
          title: fm('geneTest.geneTestType'),
          values: doc.geneTestType ? fm(`geneTest.opts.${doc.geneTestType}`) : undefined,
          condition: exists(doc.geneTestType),
        },
      ],
    });
    addons.push({
      id: 'geneTest',
      title: fm('geneTest.title'),
      titleDescription: undefined,
      items: [],
      events: events,
    });

    return;
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertThalamotomyToTimeline = (
  docs: Array<IThalamotomy>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: IAddon[] = [];
  const events: IEvent[] = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    events.push({
      date: dateFromPartialUpdateTimeFrame(doc.date),
      eventType: 'treatment',
      title: fm('thalamotomyHifu.thalamotomy.title'),
      description: [
        {
          title: fm('thalamotomyHifu.thalamotomy.treatmentHospital'),
          values: doc.placeOfOperation,
        },
        {
          title: fm('thalamotomyHifu.thalamotomy.treatmentType'),
          values: doc.typeOfOperation ? fm(`thalamotomyHifu.thalamotomy.opts.${doc.typeOfOperation}`) : undefined,
        },
      ],
    });
    doc.adverseEffects?.forEach((ae) => {
      if (!isPartialDate(ae.date)) return;
      events.push({
        date: dateFromPartialUpdateTimeFrame(ae.date),
        eventType: 'adverseEffect',
        title: fm('thalamotomyHifu.adverseEffect'),
        description: [
          {
            title: fm('thalamotomyHifu.adverseEffects'),
            values: (ae.adverseEffects || []).map((a) => fm(`thalamotomyHifu.opts.${a}`)).join(', '),
          },
          {
            title: fm('thalamotomyHifu.adverseEffectAdditionalInformation'),
            values: ae.additionalInformation,
          },
        ],
      });
    });
  });
  events.length &&
    addons.push({
      id: 'thalamotomy',
      title: fm('thalamotomyHifu.thalamotomy.title'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  return addons.length > 0 ? addons : undefined;
};

export const convertHifuToTimeline = (
  docs: Array<IHifuTreatment>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: IAddon[] = [];
  const events: IEvent[] = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    events.push({
      date: dateFromPartialUpdateTimeFrame(doc.date),
      eventType: 'treatment',
      title: fm('thalamotomyHifu.hifu.title'),
      description: [
        {
          title: fm('thalamotomyHifu.hifu.treatmentIndication'),
          values: doc.treatmentIndication ? fm(`thalamotomyHifu.hifu.opts.${doc.treatmentIndication}`) : undefined,
        },
        {
          title: fm('thalamotomyHifu.hifu.whatOther'),
          values: doc.treatmentIndicationOther,
          condition: doc.treatmentIndication === 'other',
        },
        {
          title: fm('thalamotomyHifu.hifu.treatmentTarget'),
          values: doc.treatmentTarget ? fm(`thalamotomyHifu.hifu.opts.${doc.treatmentTarget}`) : undefined,
        },
      ],
    });
    doc.adverseEffects?.forEach((ae) => {
      if (!isPartialDate(ae.date)) return;
      events.push({
        date: dateFromPartialUpdateTimeFrame(ae.date),
        eventType: 'adverseEffect',
        title: fm('thalamotomyHifu.adverseEffect'),
        description: [
          {
            title: fm('thalamotomyHifu.adverseEffects'),
            values: (ae.adverseEffects || []).map((a) => fm(`thalamotomyHifu.opts.${a}`)).join(', '),
          },
          {
            title: fm('thalamotomyHifu.adverseEffectAdditionalInformation'),
            values: ae.additionalInformation,
          },
        ],
      });
    });
  });
  events.length &&
    addons.push({
      id: 'hifu',
      title: fm('thalamotomyHifu.hifu.title'),
      titleDescription: undefined,
      items: [],
      events: events,
    });
  return addons.length > 0 ? addons : undefined;
};

export const convertUpdrsVToTimeline = (
  docs: Array<IUPDRSV>,
  fm: (id: string) => string,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  const events: Array<IEvent> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    (doc.value || doc.value === 0) &&
      doc.date &&
      events.push({
        date: dateFromPartialUpdateTimeFrame(doc.date),
        eventType: 'text',
        title: `${doc.value}`,
        description: { value: `${fm('updrs.opts.v.' + doc.value)}` },
      });
  });
  addons.push({
    id: 'topdata',
    title: 'Hoehn & Yahr',
    titleDescription: undefined,
    items: [],
    events: events,
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertLeddToGraph = (
  docs: Array<IMedication>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  // LEDD upper boundary values
  const secondaryDataPoints: IDataPoint[] = [];

  const leddDataPoints = getLEDDTimeseries(docs as any);
  if (!leddDataPoints.length) return undefined;
  leddDataPoints.forEach((med: [PartialDate, number, number, boolean], i) => {
    dataPoints.push({
      date: dateFromPartialUpdateTimeFrame(med[0]),
      value: med[1],
      id: 'ledd',
      title: med[1].toString(),
    });
    let drawLeddMaxPoint = med[1] !== med[2];
    // If ledd and leddMax are both 0, draw leddMax point if there has been some previously
    if (i > 0 && med[1] === 0 && med[2] === 0) {
      for (let j = 0; j < i; j++) {
        if (leddDataPoints[j][1] !== leddDataPoints[j][2]) {
          drawLeddMaxPoint = true;
        }
      }
    }
    (drawLeddMaxPoint || med[3]) &&
      secondaryDataPoints.push({
        date: dateFromPartialUpdateTimeFrame(med[0]),
        value: med[2],
        id: 'leddMax',
        title: med[2].toString(),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'ledd',
      type: 'lineGraph',
      legend: 'LEDD',
      linegraphProps: {
        stepLine: true,
        doNotMergeOverlappingDataPoints: true,
      },
    },
    {
      dataPoints: secondaryDataPoints,
      id: 'leddMax',
      type: 'lineGraph',
      legend: fm('graph.leddHigh'),
      linegraphProps: {
        stepLine: true,
        doNotMergeOverlappingDataPoints: true,
      },
    },
  ];
};

const getUPDRSIIIScore = (data: IUPDRSIII): number | 'notCounted' => {
  if (data.retrospectiveData?.[0] === true && (data.manualScore || data.manualScore === 0)) {
    return data.manualScore ?? 'notCounted';
  } else {
    return isUpdrsComplete(data, data.testType) ? updrsScoreSum(data) : 'notCounted';
  }
};

export const convertUpdrsIIIToGraph = (
  docs: Array<IUPDRSIII>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  const secondaryDataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date) || d.type === 'dbs' || d.type === 'levodopa') return;
    const score = getUPDRSIIIScore(d);
    if (
      (score || (typeof score === 'number' && score === 0)) &&
      score !== 'notCounted' &&
      !Number.isNaN(Number(score))
    ) {
      const value = Number(score);
      if (d.testType === 'UPDRS') {
        dataPoints.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: value,
          id: 'updrsiii',
          title: value.toString(),
        });
      }
      if (d.testType === 'MDSUPDRS') {
        secondaryDataPoints.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: value,
          id: 'mdsupdrsiii',
          title: value.toString(),
        });
      }
    }
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'updrsiii',
      type: 'lineGraph',
      legend: 'UPDRS III (0-108)',
    },
    {
      dataPoints: secondaryDataPoints,
      id: 'mdsupdrsiii',
      type: 'lineGraph',
      legend: 'MDS-UPDRS III (0-132)',
      useAdditionalScale: true,
    },
  ];
};

/**
 * Push data to dataPoints corresponding the speciality of the given value
 * @param value value of the datapoint e.g. 12 or 'X'
 * @param dataPoints Array of datapoints in which data is pushed
 * @param data latest data of the documents
 * @param dateFromPartialUpdateTimeFrame function to get js Date from Partial Date
 * @param fm formatting message function
 */
const specialValueChecker = (
  value: string | number,
  dataPoints: Array<IDataPoint>,
  data: { date?: PartialDate } & IControlProps,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): void => {
  if (data.date && (value || (typeof value === 'number' && value === 0))) {
    // Here special value is checked, adding more if's allows more special values checking
    if (value === '❌') {
      dataPoints.push({
        date: dateFromPartialUpdateTimeFrame(data.date),
        value: '❌',
        id: 'special',
        title: fm('cognition.unableToPerform'),
      });
    } else {
      dataPoints.push({
        date: dateFromPartialUpdateTimeFrame(data.date),
        value: value,
        id: 'normal',
        title: value.toString(),
      });
    }
  }
};

export const convertMocaToGraph = (
  docs: Array<IMOCA>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const value = d.unableToPerform ? '❌' : mocaTotalScore(d);
    if (!d.unableToPerform && Number.isNaN(Number(value))) return;
    specialValueChecker(value, dataPoints, d, dateFromPartialUpdateTimeFrame, fm);
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'moca',
      type: 'lineGraph',
      legend: 'MOCA',
    },
  ];
};

export const convertMmseToGraph = (
  docs: Array<IMMSE>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const value = d.unableToPerform ? '❌' : mmseTotalScore(d);
    if (!d.unableToPerform && Number.isNaN(Number(value))) return;
    specialValueChecker(value, dataPoints, d, dateFromPartialUpdateTimeFrame, fm);
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'mmse',
      type: 'lineGraph',
      legend: 'MMSE',
    },
  ];
};

export const convertTmtToGraph = (
  docs: Array<ITMT>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string, values?: { [key: string]: string }) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsA: IDataPoint[] = [];
  const dataPointsB: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const valueA =
      (d?.tmtaUnableToPerform && !isEmpty(d.tmtaUnableToPerform)) || (d.tmtaTime && d.tmtaTime > 240)
        ? '❌'
        : Number(d?.tmtaTime ?? undefined);
    const valueB =
      (d?.tmtbUnableToPerform && !isEmpty(d.tmtbUnableToPerform)) || (d.tmtbTime && d.tmtbTime > 240)
        ? '❌'
        : Number(d?.tmtbTime ?? undefined);

    if (valueA === '❌' || !Number.isNaN(valueA))
      specialValueChecker(valueA, dataPointsA, d, dateFromPartialUpdateTimeFrame, fm);
    if (valueB === '❌' || !Number.isNaN(valueB))
      specialValueChecker(valueB, dataPointsB, d, dateFromPartialUpdateTimeFrame, fm);
  });
  dataPointsA.forEach((dp) => {
    dp.value !== '❌' ? (dp.title = fm('cognition.tmt.tmtSeconds', { seconds: dp.title || '-' })) : dp.title;
  });
  dataPointsB.forEach((dp) => {
    dp.value !== '❌' ? (dp.title = fm('cognition.tmt.tmtSeconds', { seconds: dp.title || '-' })) : dp.title;
  });
  return [
    {
      dataPoints: dataPointsA,
      id: 'tmtA',
      type: 'lineGraph',
      legend: 'TMT-A',
    },
    {
      dataPoints: dataPointsB,
      id: 'tmtB',
      type: 'lineGraph',
      legend: 'TMT-B',
    },
  ];
};

export const convertCdrToGraph = (
  docs: Array<ICDR>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsCdrClass: IDataPoint[] = [];
  const dataPointsFtldCdrClass: IDataPoint[] = [];
  docs.forEach((d) => {
    const valueCdrClass = getCdrClass(d) ?? undefined;
    const valueFtldCdrClass = getFtldCdrClass(d) ?? undefined;
    if ((valueCdrClass || valueCdrClass === 0) && d.date) {
      dataPointsCdrClass.push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: valueCdrClass.toString(),
        id: 'cdrClass',
        title: `${valueCdrClass} ${fm(`cognition.cdr.cdrClassDesc.${valueCdrClass === 0.5 ? '05' : valueCdrClass}`)}`,
        description: [
          {
            title: fm('cognition.cdr.cdrTotalScore'),
            values: getCdrTotalScore(d) ?? '-',
          },
        ],
      });
    }
    if ((valueFtldCdrClass || valueFtldCdrClass === 0) && d.date) {
      dataPointsFtldCdrClass.push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: valueFtldCdrClass.toString(),
        id: 'ftldCdrClass',
        title: `${valueFtldCdrClass} ${fm(
          `cognition.cdr.ftldCdrClassDesc.${valueFtldCdrClass === 0.5 ? '05' : valueFtldCdrClass}`,
        )}`,
        description: [
          {
            title: fm('cognition.cdr.ftldCdrTotalScore'),
            values: getFtldCdrTotalScore(d) ?? '-',
          },
        ],
      });
    }
  });
  return [
    {
      dataPoints: dataPointsCdrClass,
      id: 'cdrClass',
      type: 'lineGraph',
      legend: fm('cognition.cdr.cdrClass'),
    },
    {
      dataPoints: dataPointsFtldCdrClass,
      id: 'ftldCdrClass',
      type: 'lineGraph',
      legend: fm('cognition.cdr.ftldCdrClass'),
    },
  ];
};

export const convertDbsEffectToGraph = (
  docs: Array<IDBSEffect>,
  updrsDocs: IUPDRSIII[],
  dbsDocs: IDBS[],
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsUpdrs: IDataPoint[] = [];
  const dataPointsMDS: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    // Assign the updrs test done before dbs treatment to variable called 'before'
    const before = updrsDocs.find((doc) => doc._id === d.updrsIIIBeforeID) || null;
    // Assign the updrs test done after the dbs treatment to variable called 'after'
    const after = updrsDocs.find((doc) => doc._id === d.updrsIIIAfterID) || null;

    const dbsOldSettingsId = d.dbsOldSettings || '';
    const dbsOldSettings = flatten(dbsDocs.map((doc) => doc?.settings ?? [])).filter((s) =>
      (s.programs ? s.programs.map((p: IProgram) => p.id) : []).includes(dbsOldSettingsId),
    )[0] as IDBSSetting;
    const dbsOldProgram = `${
      dbsOldSettings?.programs?.find((p) => p.id === dbsOldSettingsId)?.programName
        ? dbsOldSettings.programs.find((p) => p.id === dbsOldSettingsId)?.programName
        : fm('graph.programNotChosen')
    } - ${
      dbsOldSettings?.generatorAdjustedDate ? '(' + formatPartialDate(dbsOldSettings.generatorAdjustedDate) + ')' : ''
    }`;

    const dbsNewSettingsId = d.dbsNewSettings || '';
    const dbsNewSettings = flatten(dbsDocs.map((d) => d?.settings ?? [])).filter((s) =>
      (s.programs ? s.programs.map((p: IProgram) => p.id) : []).includes(dbsOldSettingsId),
    )[0] as IDBSSetting;
    const dbsNewProgram = `${
      dbsNewSettings?.programs?.find((p) => p.id === dbsNewSettingsId)?.programName
        ? dbsNewSettings.programs.find((p) => p.id === dbsNewSettingsId)?.programName
        : fm('graph.programNotChosen')
    } - ${
      dbsNewSettings?.generatorAdjustedDate ? '(' + formatPartialDate(dbsNewSettings.generatorAdjustedDate) + ')' : ''
    }`;

    const beforeScore: number | 'notCounted' | undefined = before ? getUPDRSIIIScore(before) : undefined;
    const afterScore: number | 'notCounted' | undefined = after ? getUPDRSIIIScore(after) : undefined;
    if (beforeScore && beforeScore !== 'notCounted' && afterScore && afterScore !== 'notCounted') {
      const beforeValue = Number(beforeScore);
      const afterValue = Number(afterScore);
      if (Number.isNaN(beforeValue) || Number.isNaN(afterValue)) return;
      const change =
        afterValue > beforeValue
          ? Math.round((1 - afterValue / beforeValue) * 100)
          : afterValue < beforeValue
            ? Math.round((1 - afterValue / beforeValue) * 100)
            : 0;
      const title = `${change}%`;
      const description: TDescriptionTable = [
        {
          title: `${fm('updrs.dbsOldSettings')}: `,
          values: dbsOldProgram,
        },
        {
          title:
            d.testType === 'UPDRS'
              ? `${fm('updrs.updrsIIIscoreBeforeDBS')}: `
              : `${fm('updrs.MDSupdrsIIIscoreBeforeDBS')}: `,
          values: beforeValue,
        },
        {
          title: `${fm('updrs.dbsNewSettings')}: `,
          values: dbsNewProgram,
        },
        {
          title:
            d.testType === 'UPDRS'
              ? `${fm('updrs.updrsIIIscoreAfterDBS')}: `
              : `${fm('updrs.MDSupdrsIIIscoreAfterDBS')}: `,
          values: afterValue,
        },
      ];
      if (d.testType === 'UPDRS') {
        dataPointsUpdrs.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: beforeValue,
          additionalValue: afterValue,
          id: 'dbsEffect',
          title: title,
          description: description,
        });
      } else if (d.testType === 'MDSUPDRS') {
        dataPointsMDS.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: beforeValue,
          additionalValue: afterValue,
          id: 'dbsEffect',
          title: title,
          description: description,
        });
      }
    }
  });
  return [
    {
      dataPoints: dataPointsUpdrs,
      id: 'dbsEffect',
      type: 'boxGraph',
      legend: fm('updrs.dbsTestEffect'),
    },
    {
      dataPoints: dataPointsMDS,
      id: 'dbsEffect',
      type: 'boxGraph',
      legend: fm('updrs.dbsTestEffect'),
      useAdditionalScale: true,
    },
  ];
};

export const convertLevodopaTestToGraph = (
  docs: Array<ILevodopatest>,
  updrsDocs: IUPDRSIII[],
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPointsUpdrs: IDataPoint[] = [];
  const dataPointsMDS: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const before = updrsDocs.find((doc) => doc._id === d.updrsIIIBeforeID) || null;
    // Assign the updrs test done after the dbs treatment to variable called 'after'
    const after = updrsDocs.find((doc) => doc._id === d.updrsIIIAfterID) || null;
    const beforeScore: number | 'notCounted' | undefined = before ? getUPDRSIIIScore(before) : undefined;
    const afterScore: number | 'notCounted' | undefined = after ? getUPDRSIIIScore(after) : undefined;
    if (beforeScore && beforeScore !== 'notCounted' && afterScore && afterScore !== 'notCounted') {
      const beforeValue = Number(beforeScore);
      const afterValue = Number(afterScore);
      if (Number.isNaN(beforeValue) || Number.isNaN(afterValue)) return;
      const change =
        afterValue > beforeValue
          ? Math.round((1 - afterValue / beforeValue) * 100)
          : afterValue < beforeValue
            ? Math.round((1 - afterValue / beforeValue) * 100)
            : 0;
      const title = `${change}%`;
      const description: TDescriptionTable = [
        {
          title:
            d.testType === 'UPDRS' ? `${fm('updrs.updrsIIIscoreBefore')}: ` : `${fm('updrs.MDSupdrsIIIscoreBefore')}: `,
          values: beforeValue,
        },
        {
          title:
            d.testType === 'UPDRS' ? `${fm('updrs.updrsIIIscoreAfter')}: ` : `${fm('updrs.MDSupdrsIIIscoreAfter')}: `,
          values: afterValue,
        },
      ];
      if (d.testType === 'UPDRS') {
        dataPointsUpdrs.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: beforeValue,
          additionalValue: afterValue,
          title: title,
          id: 'levodopaTest',
          description: description,
        });
      } else if (d.testType === 'MDSUPDRS') {
        dataPointsMDS.push({
          date: dateFromPartialUpdateTimeFrame(d.date),
          value: beforeValue,
          additionalValue: afterValue,
          title: title,
          id: 'levodopaTest',
          description: description,
        });
      }
    }
  });
  return [
    {
      dataPoints: dataPointsUpdrs,
      type: 'boxGraph',
      id: 'levodopaTest',
      legend: fm('updrs.levodopaTestEffect'),
    },
    {
      dataPoints: dataPointsMDS,
      type: 'boxGraph',
      id: 'levodopaTest',
      legend: fm('updrs.levodopaTestEffect'),
      useAdditionalScale: true,
    },
  ];
};

export const convertFbiModToGraph = (
  docs: Array<IFBIMOD>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    const value = calculateFBIMODScore(d);
    const description: TDescriptionTable = Object.entries(d)
      .map(([key, value]) => {
        if (fbiModFields.includes(key as keyof IFBIMOD) && ['1', '2', '3'].includes(value)) {
          const localizedShortenedKey = fm(`diagnosticSurvey.fbimod.${key}`).split(':')[0];
          return { title: localizedShortenedKey, values: fm(`diagnosticSurvey.fbimod.opts.${value}`) };
        } else return { values: 'filter' };
      })
      .filter((desc) => desc.values !== 'filter');
    if (value || value === 0) {
      dataPoints.push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: value,
        title: `${value} / 66`,
        id: 'fbiMod',
        description: description,
      });
    }
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'fbiMod',
      type: 'lineGraph',
      legend: 'FBI-Mod',
    },
  ];
};
