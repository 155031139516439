import { keys } from 'ramda';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { controlProps } from '../../../utility/documentHandling';
import { omit, path } from 'Utility/ramdaReplacement';

export const motorFunctionFields = [
  'sittingWithoutSupport',
  'crawlingOnHandsAndKnees',
  'standingWithAssistance',
  'standingWithoutAssistance',
  'walkingWithAssistance',
  'walkingWithoutAssistance',
];

export interface IFieldWithDates {
  name: string;
  startDate?: PartialDate;
  endDate?: PartialDate;
}

/**
 * Get latest changes in Motor Funcitions by sorting them by latest date
 * @param d: IMotorFunction
 */
export const getLatestChanges = (d: IMotorFunction): Array<IFieldWithDates> => {
  const fieldsArray = [] as Array<IFieldWithDates>;
  motorFunctionFields.forEach((f: string): void => {
    if (path([`${f}Start`], d) || path([`${f}End`], d)) {
      fieldsArray.push({ name: f, startDate: path([`${f}Start`], d), endDate: path([`${f}End`], d) });
    }
  });
  // Sort fields by endDate, or startDate if endDate not entered
  return fieldsArray
    .sort((d1: IFieldWithDates, d2: IFieldWithDates) =>
      sortPartialDate(d1.endDate || d1.startDate, d2.endDate || d2.startDate),
    )
    .reverse();
};

const omitedFields = controlProps;

/**
 * Filter empty fields from documents
 * @param documents: Array<TDocument>
 */
export const filterEmptyFields = (documents: Array<TDocument>): Array<IMotorFunction> => {
  return documents?.length
    ? (documents
        .filter((d: TDocument) => d._type === 'motorfunction')
        .filter((d: TDocument) => !!keys(omit(omitedFields, d)).find((key) => d[key]?.[0])) as Array<IMotorFunction>)
    : [];
};

export type TDocument = IMotorFunction | IAid;

/**
 * Format given PartialDates to suitable string form
 * @param d1: PartialDate
 * @param d2: PartialDate
 */
export const formatTheDates = (d1: PartialDate | undefined, d2: PartialDate | undefined): string =>
  `${d1 ? formatPartialDate(d1) : ''}${d1 || d2 ? ' - ' : ''}${d2 ? formatPartialDate(d2) : ''}`;

/**
 * Get proper string for localization of aid title message
 * @param aid: IAid
 */
export const getAidTitleMessageId = (aid: IAid): string => {
  return aid.aidType ? 'motorFunctionAndMobilityAid.aids.' + aid.aidType + 'Use' : 'general.empty';
};

/**
 * Get proper string for localization of aid usage message
 * @param aid: IAid
 */
export const getAidUsageMessageId = (aid: IAid): string => {
  return aid.use ? 'motorFunctionAndMobilityAid.aids.opts.' + aid.use : 'general.empty';
};

/**
 * Clean up any invalid data of IMotorFunction item
 * Example: If start PartialDate has values [null, null, null]
 * @param {IMotorFunction} motorFunction - IMotorFunction item
 * @returns {IMotorFunction} Motorfunction items
 */
const cleanEmptyFields = (motorFunction: IMotorFunction): IMotorFunction => {
  const omited = omit(omitedFields, motorFunction);
  const omitedKeys = keys(omited);
  omitedKeys.forEach((key) => {
    if (!isPartialDate(motorFunction[key])) {
      motorFunction = omit([key], motorFunction);
    }
  });
  return motorFunction as IMotorFunction;
};

/**
 * Make an object of gained and lost motor functions
 * @param { Array<IMotorFunctionEvent> } events - Array of IMotorFunction items
 * @returns { { gained: Array<IMotorFunctionEvent>; lost: Array<IMotorFunctionEvent> } } Gained and lost abilities
 */
export const abilitiesToArray = (
  events: Array<IMotorFunction>,
): { gained: Array<IMotorFunctionEvent>; lost: Array<IMotorFunctionEvent> } => {
  const gainedArray: Array<IMotorFunctionEvent> = [];
  const lostArray: Array<IMotorFunctionEvent> = [];

  motorFunctionFields.forEach((field: string) => {
    const startEvent = `${field}` + 'Start';
    const endEvent = `${field}` + 'End';
    if (events)
      events.forEach((event: IMotorFunction) => {
        const cleanedEvents = cleanEmptyFields(event);
        const start: PartialDate | undefined = path([startEvent], cleanedEvents);
        const end: PartialDate | undefined = path([endEvent], cleanedEvents);
        if (start?.[0] && !end?.[0]) {
          gainedArray.push({ name: field, start: start, end: end } as IMotorFunctionEvent);
        }
        if (end?.[0]) {
          lostArray.push({ name: field, start: start, end: end } as IMotorFunctionEvent);
        }
      });
  });
  return { gained: gainedArray, lost: lostArray };
};

export interface IMotorFunctionEvent {
  name: string;
  start: PartialDate;
  end: PartialDate;
}
