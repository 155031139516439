import * as React from 'react';
import { styled } from '@mui/system';
import { IMyServiceContext, MyServiceContext } from '../../..';
import colors from '../../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import {
  episodeFields,
  gicAndAdditionalInformationFields,
  medicationFields,
  sleepFields,
  symptomFields,
} from './config';
import TableContent from '../components/TableContent';
import InputHandler from 'Components/InputHandler';
import { exists, isPartialDate, nowPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import { isLocaleKey, ITreatmentMonitoringInquiry, treatmentMonitoringInquiry } from 'neuro-schemas';
import { equals } from 'ramda';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import FormRow from 'Components/FormRow';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import { TSurveyType } from 'Routes/MyService/util';
import { useSelector } from 'react-redux';
import MedicationWindow, { AddButton } from '../components/MedicationWindow';
import Unit from 'Components/Unit';
import { isEmpty } from 'Utility/ramdaReplacement';

const StyledHeader = styled('div')({
  color: `${colors.primary}`,
  fontSize: '2rem',
  fontWeight: 600,
});

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const InlineLabel = styled('div')({
  display: 'inline-flex',
  width: '9rem',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '1rem',
  backgroundColor: colors.info.lightest,
  borderRadius: 30,
  color: colors.info.default,
  fontSize: '1.4rem',
  fontWeight: 600,
});

const PrimaryLabel = ({ fm }: { fm: IMyServiceContext['fm'] }): JSX.Element => (
  <InlineLabel>{fm('diagnosis.ninmt.symptomsAndLocations.primary')}</InlineLabel>
);

type TSymptom = NonNullable<ITreatmentMonitoringInquiry['symptom']>;

// Extracted symptom types
type TPain = NonNullable<TSymptom['pain']>;
type TTinnitus = NonNullable<TSymptom['tinnitus']>;
type TOther = NonNullable<TSymptom['other']>[0];

const TreatmentMonitoringInquiry = ({ documents }: ITreatmentMonitoringInquiryProps): JSX.Element => {
  const treatmentPeriodsSorted = (
    documents.filter((d) => d._type === 'ninmtTreatmentPeriod') as Array<ININMTTreatmentPeriod & IControlProps>
  ).sort((a, b) => sortPartialDate(b.date, a.date));

  const symptomsAndLocationsDocs = documents.filter((d) => d._type === 'symptomsAndLocations') as Array<
    ISymptomsAndLocations & IControlProps
  >;

  const treatmentMonitoringInquiryLocales = treatmentMonitoringInquiry.localizations;
  const treatmentMonitoringInquiryUtils = treatmentMonitoringInquiry.utils;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServiceContext;
  const [dateChangeDialog, setDateChangeDialog] = React.useState(false);
  const [dateChangeDialogData, setDateChangeDialogData] = React.useState<
    { prev: PartialDate | undefined; new: PartialDate | undefined } | undefined
  >(undefined);

  const [medicationDialogOpen, setMedicationDialogOpen] = React.useState<boolean>(false);
  const [copiedMeds, setCopiedMeds] = React.useState<ITreatmentMonitoringInquiry['medication'] | undefined>(undefined);
  const [symptomsAndLocations, setSymptomsAndLocations] = React.useState<
    | Array<{
        symptom: 'other' | 'pain' | 'tinnitus';
        location: string;
      }>
    | undefined
  >(undefined);

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmTreatmentMonitoringInquiry = (id: string | number) => {
    if (id === 'sleepQuantity') {
      return (
        treatmentMonitoringInquiryLocales[useLocale][id] +
        ` ${fm('myService.ninmt.averageInADay').toLocaleLowerCase()} ${fm(
          'myService.ninmt.withinAWeek',
        ).toLocaleLowerCase()}`
      );
    }
    if (id === 'sleepQuality') {
      return treatmentMonitoringInquiryLocales[useLocale][id] + ` ${fm('myService.ninmt.withinAWeek').toLowerCase()}`;
    }
    return treatmentMonitoringInquiryLocales[useLocale][id];
  };

  const treatmentMonitoringInquiryData = (editing?.data ?? viewing?.data ?? {}) as ITreatmentMonitoringInquiry &
    IControlProps;

  const surveyData = {
    ...treatmentMonitoringInquiryData,
  };

  const currentTreatmentPeriodId = treatmentPeriodsSorted.find(
    (tp) => partialDateToValue(tp.date) <= partialDateToValue(surveyData.date),
  )?._id;

  if (treatmentMonitoringInquiryData.symptom) surveyData.symptom = treatmentMonitoringInquiryData.symptom;
  if (treatmentMonitoringInquiryData.sleep) surveyData.sleep = treatmentMonitoringInquiryData.sleep;

  const myappDocs = useSelector((s: IState) => s.myapp.sortedAndMergedDocuments) ?? [];
  const treatmentMonitoringInqDocs = myappDocs
    .filter((d) => d._type === 'treatmentMonitoringInquiry')
    .sort((a, b) => sortPartialDate(b.date, a.date));
  const ninmtPreInqDocs = myappDocs
    .filter((d) => d._type === 'ninmtPreInquiry')
    .sort((a, b) => sortPartialDate(b.date, a.date));

  const getFieldValue = (data: TAnyObject, name: string, subName?: 'min' | 'max') => {
    const value = data[name];
    const subValue = value && typeof value === 'object' && subName && value[subName as keyof typeof value];
    return exists(subValue) ? subValue : !subName && exists(value) ? value : null;
  };

  const handleFieldChange =
    (key?: string) =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      const value = e[name];
      if (key && typeof surveyData[key as keyof typeof surveyData] === 'object') {
        setEditingData?.({
          ...surveyData,
          [key]: { ...(surveyData[key as keyof typeof surveyData] as Record<string, unknown>), [name]: value },
        });
      } else setEditingData?.({ ...surveyData, [name]: value });
    };

  const handleDateChange = (value: PartialDate) => {
    const prevVal = surveyData.date;
    const currentTreatmentPeriodId = treatmentPeriodsSorted.find(
      (tp) => partialDateToValue(tp.date) <= partialDateToValue(value),
    )?._id;

    const symptomsAndLocationsPairs = treatmentMonitoringInquiryUtils.getSymptomAndLocationPairs(
      symptomsAndLocationsDocs.filter(
        (d) =>
          d.treatmentPeriodId === currentTreatmentPeriodId &&
          d.mentalSymptom !== true &&
          partialDateToValue(d.startDate) <= partialDateToValue(value) &&
          (!d.endDate || partialDateToValue(value) <= partialDateToValue(d.endDate)),
      ),
    );

    if (
      (symptomsAndLocations?.every((p) => symptomsAndLocationsPairs.some((p2) => equals(p, p2))) &&
        symptomsAndLocationsPairs.every((p) => symptomsAndLocations?.some((p2) => equals(p, p2)))) ||
      (editing?.type === 'treatmentMonitoringInquiry' && (editing?.data as TSurveyType)?._commitsLength)
    ) {
      setEditingData?.({ ...surveyData, date: value });
    } else {
      setDateChangeDialog(true);
      setDateChangeDialogData({ prev: prevVal, new: value });
    }
  };

  const onAcceptDateChange = () => {
    setEditingData?.({ ...surveyData, date: dateChangeDialogData?.new });
    setDateChangeDialog(false);
  };

  const onCancelDateChange = () => {
    setEditingData?.({ ...surveyData, date: dateChangeDialogData?.prev });
    setDateChangeDialog(false);
  };

  /** Symptom fields = the nested data in { symptom: { pain: { ... }, other: [{ ... }] } } for example
   * @param {string} key - Symptom type, which should be 'pain', 'tinnitus' or 'other'
   * @param {'symptomWeek' | 'episodes'} type - Type of field changed, which is either 'symptomWeek' or 'episodes'
   * @param {number | undefined} i - Index for array-typed symptoms (concerns 'other')
   * @param {number | undefined} j - Index for array-typed symptom weeks (concerns 'pain')
   * @param {'min' | 'max' | undefined} subName - Indicates that the field being changed is a NumberRange so it is handled accordingly
   * @returns {void}
   */
  const handleSymptomFieldChange =
    (key: string, type: 'symptomWeek' | 'episodes', i?: number, j?: number, subName?: 'min' | 'max') =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      let value = e[name];
      if (name === 'episodes') {
        const nulledFields = ['episodesDuration', 'episodesInAWeek'].reduce((current, field) => {
          (current as { [field: string]: any })[field] = null;
          return current;
        }, {});

        if (key === 'pain' && value === 'no') {
          setEditingData?.({
            ...surveyData,
            symptom: {
              ...surveyData.symptom,
              pain: { ...surveyData?.symptom?.pain, episodes: { episodes: value, ...nulledFields } },
            },
          });
          return;
        } else if (key === 'other' && value === 'no' && (i || i === 0)) {
          const otherSymptomsCopied = structuredClone(surveyData?.symptom?.other);
          if (Array.isArray(otherSymptomsCopied) && otherSymptomsCopied[i]) {
            otherSymptomsCopied[i] = {
              ...otherSymptomsCopied[i],
              episodes: { episodes: value, ...nulledFields },
            };
            setEditingData?.({
              ...surveyData,
              symptom: {
                ...surveyData.symptom,
                other: otherSymptomsCopied,
              },
            });
          }
          return;
        }
      }

      const baseValue = JSON.parse(JSON.stringify(surveyData.symptom?.[key as keyof typeof surveyData.symptom] ?? {}));
      const symptomIsArray = key === 'other' && Array.isArray(baseValue) && exists(i);
      const newValue = symptomIsArray ? baseValue[i ?? 0]?.[type] : baseValue?.[type];

      if (!newValue) return;

      const currentValue = Array.isArray(newValue) && typeof j === 'number' ? newValue[j][name] : newValue[name];
      if (subName)
        value = {
          [subName === 'min' ? 'max' : 'min']: currentValue?.[subName === 'min' ? 'max' : 'min'] ?? null,
          [subName]: value,
        };

      if (Array.isArray(newValue) && typeof j === 'number') newValue[j][name] = value;
      else newValue[name] = value;

      const updateValue = {
        ...surveyData.symptom,
        [key]: symptomIsArray
          ? baseValue
          : { ...surveyData?.symptom?.[key as keyof typeof surveyData.symptom], [type]: newValue },
      };

      setEditingData?.({ ...surveyData, symptom: updateValue });
    };

  // Set date if editing and no date found
  React.useEffect(() => {
    if (!surveyData.date && isEditing) {
      setEditingData?.({ ...surveyData, date: nowPartialDate() });
    }
  }, []);

  // Initialize document data based on symptomsAndLocationsPairs (when creating a new survey doc or date is changed)
  React.useEffect(() => {
    if (viewing) return;
    if (!surveyData.date) return;
    if (editing?.type === 'treatmentMonitoringInquiry' && (editing?.data as TSurveyType)?._commitsLength) return;

    const latestTreatmentMonitorinInqWithMeds = treatmentMonitoringInqDocs.find(
      (d) =>
        d.medication &&
        !isEmpty(d.medication) &&
        d?.date &&
        partialDateToValue(d.date) <= partialDateToValue(surveyData?.date),
    );
    const latestPreInqWithMeds = ninmtPreInqDocs.find(
      (d) =>
        d.medication &&
        !isEmpty(d.medication) &&
        d?.date &&
        partialDateToValue(d.date) <= partialDateToValue(surveyData?.date),
    );
    const latestMeds: ITreatmentMonitoringInquiry['medication'] =
      latestTreatmentMonitorinInqWithMeds?.medication ?? latestPreInqWithMeds?.medication;

    const symptomsAndLocationsPairs = treatmentMonitoringInquiryUtils.getSymptomAndLocationPairs(
      symptomsAndLocationsDocs.filter(
        (d) =>
          d.treatmentPeriodId === currentTreatmentPeriodId &&
          d.mentalSymptom !== true &&
          partialDateToValue(d.startDate) <= partialDateToValue(surveyData.date) &&
          (!d.endDate ||
            (surveyData.date &&
              isPartialDate(surveyData.date) &&
              partialDateToValue(surveyData.date) <= partialDateToValue(d.endDate))),
      ),
    );

    if (!equals(latestMeds, copiedMeds)) {
      setCopiedMeds(latestMeds);
    }
    if (!equals(symptomsAndLocationsPairs, symptomsAndLocations)) {
      setSymptomsAndLocations(symptomsAndLocationsPairs);
    }
  }, [surveyData.date]);

  React.useEffect(() => {
    if (!symptomsAndLocations && !copiedMeds) return;
    const defaultDataCopiedMeds: ITreatmentMonitoringInquiry['medication'] = [];
    if (copiedMeds && copiedMeds.length > 0) {
      copiedMeds.forEach((m) => {
        defaultDataCopiedMeds.push({
          medicationDosage: m?.medicationDosage,
          medicationName: m?.medicationName,
          id: m.id,
        });
      });
    }
    if (symptomsAndLocations && symptomsAndLocations.length > 0) {
      const symptomWeekFields: { [key: string]: unknown } = {
        averageInAWeek: null,
        lowestHighestInAWeek: null,
        averageHarmInAWeek: null,
        lowestHighestHarmInAWeek: null,
      };
      const episodeFields: { [key: string]: unknown } = {
        episodes: null,
        episodesInAWeek: null,
        episodesDuration: null,
      };
      const newSymptom: typeof surveyData.symptom = {};

      const newPain: TPain = {};
      const newTinnitus: TTinnitus = {};
      const newOther: Array<TOther> = [];

      symptomsAndLocations.forEach((sl) => {
        switch (sl.symptom) {
          case 'pain':
            if (Array.isArray(newPain.symptomWeek))
              newPain.symptomWeek.push({ location: sl.location, ...symptomWeekFields });
            else newPain.symptomWeek = [{ location: sl.location, ...symptomWeekFields }];
            newPain.episodes = episodeFields;
            break;
          case 'tinnitus':
            newTinnitus.symptomWeek = symptomWeekFields;
            break;
          case 'other': {
            newOther.push({ symptomDescription: sl.location, symptomWeek: symptomWeekFields, episodes: episodeFields });
          }
        }
      });

      const newValues: Record<string, unknown> = { pain: newPain, tinnitus: newTinnitus, other: newOther };

      ['pain', 'tinnitus', 'other'].forEach((s) => {
        const newValue = newValues[s];
        if (newValue && typeof newValue === 'object' && !isEmpty(newValue)) {
          if (s === 'other' && Array.isArray(newValue)) {
            newSymptom[s as keyof typeof newSymptom] = newValue;
          } else {
            newSymptom[s as 'pain' | 'tinnitus'] = newValue;
          }
        }
      });

      if (!isEmpty(newSymptom))
        setEditingData?.({
          ...surveyData,
          symptom: newSymptom,
          treatmentEffectivenessEstimate: null as any,
          sleep: { sleepQuality: null as any, sleepQuantity: null as any },
          medication: defaultDataCopiedMeds.length > 0 ? defaultDataCopiedMeds : [],
        });
    } else {
      setEditingData?.({
        ...surveyData,
        treatmentEffectivenessEstimate: null as any,
        sleep: { sleepQuality: null as any, sleepQuantity: null as any },
        medication: defaultDataCopiedMeds.length > 0 ? defaultDataCopiedMeds : [],
      });
    }
  }, [symptomsAndLocations, copiedMeds]);

  const isPrimary = (symptom: string, locOrDesc: string | undefined): boolean => {
    return symptomsAndLocationsDocs.some((d) => {
      return (
        d.treatmentPeriodId === currentTreatmentPeriodId &&
        (symptom === 'other' ? d.symptomDescription : d.symptomLocation) === locOrDesc &&
        d.primarySymptom
      );
    });
  };

  const isEditing = !viewing && !!editing;

  return (
    <>
      <FormRow title={'general.date'} headerWidth={!editing ? 1 : 3.9}>
        <InputHandler
          name="date"
          type="PartialDate"
          editing={!!editing}
          isNotCancellable={true}
          formData={{
            document: { date: getFieldValue(surveyData, 'date') },
            onChange: (e: TOnChangeValues) => {
              const value = e.date;
              if (value && isPartialDate(value)) {
                handleDateChange(value);
              }
            },
          }}
        />
      </FormRow>
      <BlockWrapper>
        {/**
         * Symptoms & episodes
         */}
        <Container style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '2.5rem' }}>
          <Item xs={10}>
            <StyledHeader>{fm('myService.ninmt.symptoms')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        {['pain', 'tinnitus', 'other']
          .filter((key) => surveyData.symptom && Object.keys(surveyData.symptom).includes(key))
          .map((key) => {
            const s = surveyData?.symptom?.[key as keyof typeof surveyData.symptom];
            if (!s) return;
            // Symptoms are an array only when they are 'other', so "array-ify" the remaining ('pain', 'tinnitus') here so they can be treated the same
            const sArr: Array<Exclude<typeof s, Array<unknown>> & TOther> = Array.isArray(s) ? s : [s];

            return sArr.map((symptom, ind) => {
              // 'symptomWeek' is an array for 'pain', single object for 'tinnitus' and 'other'
              const symptomWeek = symptom?.symptomWeek;
              // 'tinnitus' does not have episodes
              const episodes = ['pain', 'other'].includes(key) ? (symptom as TPain | TOther)?.episodes : undefined;

              // The table can fit 5 locations (concerns 'symptomWeek' of 'pain' - 'tinnitus' and 'other' do not have locations)
              const width = {
                1: 38.5,
                2: 53.8,
                3: 69.1,
                4: 84.5,
                5: 100,
              }[Array.isArray(symptomWeek) ? symptomWeek.length : 1];

              return (
                <div key={key + ind} style={{ marginBottom: '2.5rem' }}>
                  <div style={{ fontSize: '1.8.rem', fontWeight: 600, color: colors.darkGray }}>
                    {fm(`diagnosis.ninmt.symptomsAndLocations.opts.${key}`) +
                      (key === 'other' && (symptom as TOther)?.symptomDescription
                        ? `: ${(symptom as TOther)?.symptomDescription}`
                        : '')}
                    {isPrimary(key, symptom.symptomDescription) && <PrimaryLabel fm={fm} />}
                  </div>
                  <TableContent
                    width={width}
                    titleRow={{
                      title: { content: '' },
                      content:
                        key === 'pain' && Array.isArray(symptomWeek)
                          ? symptomWeek.map((sw) => ({
                              content: sw.location ? (
                                <React.Fragment>
                                  {fmTreatmentMonitoringInquiry(`opts.${sw.location}`)}
                                  {isPrimary(key, sw.location) && <PrimaryLabel fm={fm} />}
                                </React.Fragment>
                              ) : (
                                '-'
                              ),
                            }))
                          : [{ content: '' }],
                    }}
                    bottomMargin={false}
                  >
                    {[
                      ...symptomFields.map((field) => ({
                        title: {
                          content: fmTreatmentMonitoringInquiry(
                            `${key}.${
                              {
                                averageInAWeek: 'averageIntensity',
                                lowestHighestInAWeek: 'minMax',
                                averageHarmInAWeek: 'harmAverage',
                                lowestHighestHarmInAWeek: 'harmMinMax',
                              }[field.name]
                            }`,
                          ),
                        },
                        content: (Array.isArray(symptomWeek) ? symptomWeek : [symptomWeek]).map((sw, i) => ({
                          content: (
                            tabIndex: TTabulationTools['tabIndex'],
                            inputRefs: TTabulationTools['inputRefs'],
                            id: TTabulationTools['identifier'],
                          ) =>
                            field.type === 'NumberRange' ? (
                              <React.Fragment>
                                <InlineDiv>
                                  <InputHandler
                                    type="NumberField"
                                    name={field.name}
                                    editing={isEditing}
                                    formData={{
                                      document: {
                                        [field.name]: getFieldValue((sw as TAnyObject) ?? {}, field.name, 'min'),
                                      },
                                      onChange: (e) => handleSymptomFieldChange(key, 'symptomWeek', ind, i, 'min')(e),
                                    }}
                                    width={6}
                                    placeholder="0.0"
                                    disablePlaceholderFormatting
                                    min={0}
                                    max={10}
                                    precision={1}
                                    enableTrailingZero
                                    tabulationTools={{
                                      tabIndex,
                                      inputRefs,
                                      identifier: id + 0,
                                    }}
                                  />
                                </InlineDiv>
                                <InlineDiv style={{ padding: '0 1rem' }}>...</InlineDiv>
                                <InlineDiv>
                                  <InputHandler
                                    type="NumberField"
                                    name={field.name}
                                    editing={isEditing}
                                    formData={{
                                      document: {
                                        [field.name]: getFieldValue((sw as TAnyObject) ?? {}, field.name, 'max'),
                                      },
                                      onChange: (e) => handleSymptomFieldChange(key, 'symptomWeek', ind, i, 'max')(e),
                                    }}
                                    width={6}
                                    placeholder="10.0"
                                    disablePlaceholderFormatting
                                    min={0}
                                    max={10}
                                    precision={1}
                                    enableTrailingZero
                                    tabulationTools={{
                                      tabIndex,
                                      inputRefs,
                                      identifier: id + 1,
                                    }}
                                  />
                                </InlineDiv>
                              </React.Fragment>
                            ) : (
                              <InputHandler
                                type={field.type as any}
                                name={field.name}
                                editing={isEditing}
                                formData={{
                                  document: {
                                    [field.name]: getFieldValue((sw as TAnyObject) ?? {}, field.name),
                                  },
                                  onChange: (e) => handleSymptomFieldChange(key, 'symptomWeek', ind, i)(e),
                                }}
                                options={field.options}
                                optionFormatter={
                                  field.name === 'sleepQuantity'
                                    ? (id: string | number): string => fm('general.hours', { N: id })
                                    : undefined
                                }
                                placeholder={field.placeholder}
                                disablePlaceholderFormatting
                                width={field.type === 'NumberField' ? 18 : undefined}
                                min={0}
                                max={10}
                                precision={1}
                                enableTrailingZero
                                tabulationTools={{
                                  tabIndex,
                                  inputRefs,
                                  identifier: id,
                                }}
                              />
                            ),
                          style: { textOverflow: 'clip' },
                        })),
                      })),
                      {
                        title: {
                          content: `0.0 = ${fm('myService.ninmt.treatmentMonitoringInquiry.fieldMinInfo')} 10.0 = ${fm(
                            'myService.ninmt.treatmentMonitoringInquiry.fieldMaxInfo',
                          )} (NRS)`,
                          isInfo: true,
                        },
                        content: [
                          {
                            content: '',
                          },
                        ],
                      },
                    ]}
                  </TableContent>
                  <div style={{ marginTop: '1rem' }}>
                    {episodes && (
                      <React.Fragment>
                        <div
                          style={{
                            fontSize: '1.8.rem',
                            fontWeight: 600,
                            color: colors.darkGray,
                            marginBottom: '0.5rem',
                          }}
                        >
                          {fm('myService.ninmt.episodes')}
                        </div>
                        <div style={{ paddingLeft: '1.5rem', marginBottom: '-1rem' }}>
                          <FormRow
                            title={fm(`myService.ninmt.${key}IsEpisodes`)}
                            headerWidth={2.65}
                            bottomMargin={false}
                            formatTitle={false}
                          >
                            <InputHandler
                              name="episodes"
                              editing={isEditing}
                              type="Radio"
                              options={['yes', 'no']}
                              optionFormatter={(o) => fm(`general.${o}`)}
                              formData={{
                                document: {
                                  ['episodes']: getFieldValue((episodes as TAnyObject) ?? {}, 'episodes'),
                                },
                                onChange: (e) => handleSymptomFieldChange(key, 'episodes', ind)(e),
                              }}
                              dependentFieldsList={() => ['episodesInAWeek', 'episodesDuration']}
                            />
                          </FormRow>
                        </div>
                        {getFieldValue((episodes as TAnyObject) ?? {}, 'episodes') === 'yes' && (
                          <TableContent width={38.5}>
                            {
                              episodeFields.map((field) => ({
                                title: {
                                  content: fmTreatmentMonitoringInquiry(
                                    field.name === 'episodesInAWeek'
                                      ? `${key}.episodesQuantity`
                                      : `${key}.episodesDuration`,
                                  ),
                                },
                                content: {
                                  content: (
                                    <InputHandler
                                      type={field.type as any}
                                      name={field.name}
                                      editing={isEditing}
                                      formData={{
                                        document: {
                                          [field.name]: getFieldValue((episodes as TAnyObject) ?? {}, field.name),
                                        },
                                        onChange: (e) => handleSymptomFieldChange(key, 'episodes', ind)(e),
                                      }}
                                      options={field.options}
                                      optionFormatter={
                                        field.type === 'Select'
                                          ? (id: string | number) => fmTreatmentMonitoringInquiry(`opts.${id}`)
                                          : undefined
                                      }
                                      placeholder={field.placeholder}
                                    />
                                  ),
                                },
                              })) as []
                            }
                          </TableContent>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </div>
              );
            });
          })}
        {/**
         * Sleep
         */}
        <Container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Item xs={10}>
            <StyledHeader>{fm('myService.ninmt.sleep')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        <TableContent>
          {[
            ...sleepFields.map((field) => {
              return {
                title: { content: fmTreatmentMonitoringInquiry(field.name) },
                content: [
                  {
                    content: (
                      <Unit
                        unit={field.name === 'sleepQuantity' ? fm('general.hours', { N: 2 }).split(' ')[1] : undefined}
                      >
                        <InputHandler
                          type={field.type as any}
                          name={field.name}
                          editing={isEditing}
                          formData={{
                            onChange: (e) => handleFieldChange('sleep')(e),
                            document: { [field.name]: getFieldValue(surveyData?.sleep ?? {}, field.name) },
                          }}
                          options={field.options}
                          optionFormatter={
                            field.name === 'sleepQuantity'
                              ? (id: string | number): string => fm('general.hours', { N: id })
                              : undefined
                          }
                          width={field.name === 'sleepQuantity' ? 6 : undefined}
                          placeholder={field.placeholder}
                          disablePlaceholderFormatting
                          min={0}
                          max={10}
                        />
                      </Unit>
                    ),
                  },
                ],
              };
            }),
            ...[
              {
                title: {
                  content: `0 = ${fm('myService.ninmt.treatmentMonitoringInquiry.sleepMinInfo')} 10 = ${fm(
                    'myService.ninmt.treatmentMonitoringInquiry.sleepMaxInfo',
                  )} (NRS)`,
                  isInfo: true,
                },
                content: [
                  {
                    content: '',
                  },
                ],
              },
            ],
          ]}
        </TableContent>
        {/**
         * GIC
         */}
        <Container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Item xs={10}>
            <StyledHeader>{fm('myService.ninmt.gicAndAdditionalInformation')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        <TableContent width={70}>
          {gicAndAdditionalInformationFields.map((field) => ({
            title: {
              content: fmTreatmentMonitoringInquiry(
                {
                  treatmentEffectivenessEstimate: 'treatmentEffectiveness',
                  gic: 'gicTitle',
                }[field.name ?? ''] ?? '',
              ),
              xs: 1.5,
            },
            content: [
              {
                content: (
                  <InputHandler
                    type={field.type as any}
                    name={field.name}
                    editing={isEditing}
                    formData={{
                      onChange: (e) => handleFieldChange()(e),
                      document: { [field.name]: getFieldValue(surveyData, field.name) },
                    }}
                    options={field.options}
                    placeholder={field.placeholder}
                    optionFormatter={
                      field.type === 'Select'
                        ? (id: string | number) => fmTreatmentMonitoringInquiry(`opts.${id}`)
                        : undefined
                    }
                  />
                ),
              },
            ],
          }))}
        </TableContent>
        <div style={{ width: '90%' }}>
          <div
            style={{
              marginBottom:
                Array.isArray(surveyData?.medication) && surveyData?.medication?.length > 0 ? undefined : '3.5rem',
            }}
          >
            <StyledHeader>{fm('myService.ninmt.medication')}</StyledHeader>
            {isEditing && (
              <div style={{ color: colors.tertiaryText, marginBottom: '1rem' }}>
                {fm('myService.ninmt.treatmentMonitoringInquiry.medicationInfo')}
              </div>
            )}
            <div style={{ display: viewing ? 'none' : 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
              <div onClick={() => setMedicationDialogOpen(true)}>
                <AddButton fm={fm} disabled={false} text="myService.ninmt.addMedication" />
              </div>
            </div>
          </div>
        </div>
        <MedicationWindow
          parentDocument={surveyData}
          document={surveyData?.medication}
          dialogOpen={medicationDialogOpen}
          setDialogOpen={setMedicationDialogOpen}
          fields={medicationFields}
          formType={'treatmentMonitoringInquiry'}
        />
        {/**
         * Additional info
         */}
        <Container
          style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '2rem', marginTop: '2rem' }}
        >
          <Item xs={10}>
            <StyledHeader>{fmTreatmentMonitoringInquiry('additionalDetails')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        <FormRow title={fmTreatmentMonitoringInquiry('additionalDetails')} headerWidth={3.6} formatTitle={false}>
          <InputHandler
            type="TextArea"
            name="additionalDetails"
            editing={isEditing}
            formData={{
              onChange: (e) => handleFieldChange()(e),
              document: { additionalDetails: getFieldValue(surveyData, 'additionalDetails') },
            }}
            width={42}
            maxLength={500}
          />
        </FormRow>
      </BlockWrapper>
      <ConfirmationDialog
        open={dateChangeDialog}
        text={fm('general.dependentFieldsRemovalWarning')}
        confirm={{ callback: onAcceptDateChange, text: 'general.continue' }}
        cancel={{ callback: onCancelDateChange }}
      />
    </>
  );
};

interface ITreatmentMonitoringInquiryProps {
  documents: Array<ININMTTreatmentPeriod | ISymptomsAndLocations>;
}

export default TreatmentMonitoringInquiry;
