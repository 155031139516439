import { sortPartialDate } from 'neuro-utils';
import { LoadableDocConfig } from '../generalSettings';
import { msDiagnoses, keoDiagnoses, reoDiagnoses, nmosdDiagnoses } from 'Routes/Diagnosis/utils/definitions';

export const msDocumentTypes: LoadableDocConfig[] = [
  {
    name: 'diagnosis',
    subTypes: [
      'diagnosis',
      'msSymptoms',
      'msMriAndLaboratoryFindings',
      'msDiagnosticCriteria',
      'nmosdDiagnosticCriteria',
      'msClinicalCourse',
    ],
  },
  {
    name: 'medication',
    subTypes: ['medication', 'adverseEffect', 'otherTreatment', 'periodWithoutMedication', 'procedure'],
    subTypesFiltering: { procedure: (d) => d?.code?.code === '20720000' || d?.code?.code === '1269349006' },
  },
  { name: 'neurologicalStatusAndEDSS' },
  { name: 'relapse' },
  { name: 'imaging', subTypes: ['mri'] },
  {
    name: 'inflammatoryDiseaseActivity',
    subTypes: ['inflammatoryDiseaseActivityStart', 'inflammatoryDiseaseActivityMonitoring'],
    secondaryDocs: ['relapse', 'mri'],
  },
  { name: 'cognition', subTypes: ['sdmt'] },
  { name: 'examination' },
  {
    name: 'clinicalstudy',
    subTypes: ['vep', 'sep', 'baep', 'octscan', 'ecg', 'echocardiography', 'bloodPressure', 'heartRate'],
  },
  { name: 'comorbidity', subTypes: ['comorbidity'], title: 'comorbidities' },
  { name: 'therapy' },
  { name: 'background', subTypes: ['background', 'measurement'] },
  { name: 'myService', title: 'titleShort' },
];

export const msGraphDocTypes = [
  'diagnosis',
  'medication',
  'adverseEffect',
  'periodWithoutMedication',
  'otherTreatment',
  'procedure',
  'neurologicalStatusAndEDSS',
  'mri',
  'sdmt',
  'relapse',
  'msSymptoms',
  'inflammatoryDiseaseActivityStart',
  'inflammatoryDiseaseActivityMonitoring',
  'vep',
  'sep',
  'baep',
  'octscan',
  'ecg',
  'echocardiography',
  'bloodPressure',
  'heartRate',
  'comorbidity',
  'background',
];

const msDiagnosisCodes = [...msDiagnoses, ...keoDiagnoses, ...reoDiagnoses, ...nmosdDiagnoses];

// Filter these routes/types from showing in page navigations based on these rules
export const msFilteredTypes = (docs?: document[]): string[] => {
  let latestMSDiagnosis = undefined;
  let idaDocs = [] as document[];

  if (docs && docs.length > 0) {
    // Get all MS diagnoses
    const msDiagnoses = docs.filter((d) => msDiagnosisCodes.includes((d as IDiagnosis).diagnosis || ''));
    msDiagnoses.sort((n1, n2) => sortPartialDate(n1.date, n2.date)).reverse();

    latestMSDiagnosis = msDiagnoses[0]?.diagnosis;
    idaDocs = docs.filter(
      (d) => d._type === 'inflammatoryDiseaseActivityStart' || d._type === 'inflammatoryDiseaseActivityMonitoring',
    );
  }

  return [
    ...(latestMSDiagnosis !== 'G36.0' || idaDocs.length > 0 ? [] : ['inflammatoryDiseaseActivity']), // Filter inflammatoryDiseaseActivity if latest diagnosis is G36.0 and no docs exist
  ];
};

export const msAlerts: IAlert[] = [
  {
    // Notification to the user if a diagnosis document is recorded before a msSymptoms document
    content: 'alerts.ms.symptomOnsetDateAfterDiagnosisDate',
    id: 'msSymptomOnsetDateAfterDiagnosisDate',
    severity: 'warning',
    popup: true,
    rule: (docs?: Array<IControlProps & Record<string, unknown>>): boolean => {
      // Find first MS diagnosis document (docs should be sorted descending by commitDate)
      const msDiagnosisDoc: IDiagnosis | Record<string, never> =
        (docs || []).find(
          (d) =>
            d._type === 'diagnosis' &&
            'diagnosis' in d &&
            [...msDiagnoses, ...nmosdDiagnoses].includes((d as IDiagnosis).diagnosis ?? ''),
        ) || {};
      const msDiagnosisDate = msDiagnosisDoc.date;

      // Find first MS symptoms document (docs should be sorted descending by commitDate)
      const msSymptomsDoc: IMsSymptoms | Record<string, never> =
        (docs || []).find((d) => d._type === 'msSymptoms') || {};
      const msSymptomOnsetDate = msSymptomsDoc.symptomOnsetDate;

      if (!msDiagnosisDate || !msSymptomOnsetDate) return false;
      return sortPartialDate(msSymptomOnsetDate, msDiagnosisDate) > 0;
    },
  },
];
