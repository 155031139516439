import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, isPartialDate, sortPartialDate } from 'neuro-utils';
import { styled } from '@mui/system';
import { Theme } from '@mui/material';

import { getActiveTreatments, getEndedTreatments, getRightDocumentsForPlatform, TDocument } from '../utils';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';
import { Container, Item } from 'Components/Grid';
import Icon from 'Components/_NewElements/Icon';
import { fetchAvxPatientLink } from '../Document/Form/PAPTherapy/functions';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { assertCapabilities, useAppSelector } from 'Store/index';
import { path } from 'Utility/ramdaReplacement';

const IntegrationStatus = styled(Container)(({ theme }: { theme?: Theme }) => ({
  height: '100%',
  borderTop: `0.1rem solid ${theme?.palette.grey[500]}`,
}));

const IntegrationStatusText = styled(Item)(({ theme }: { theme?: Theme }) => ({
  color: theme?.customComponents.tile.fonts.normalText.secondaryColor,
  fontSize: '1.6rem',
  lineHeight: '1.6rem',
  fontWeight: 600,
}));

const TreatmentDash = ({ documents, capabilityGroups = {} }: IOwnProps): JSX.Element => {
  const treatmentDocs = getRightDocumentsForPlatform(documents);

  const sortedArr = treatmentDocs
    ?.sort((n1, n2) => sortPartialDate('date' in n1 ? n1.date : n1.date, 'date' in n2 ? n2.date : n2.date))
    .reverse();

  const activeTreatments = getActiveTreatments(sortedArr);
  const endedTreatments = getEndedTreatments(sortedArr);

  const resMedIntegrationAvailable = assertCapabilities(['RESMED_AVX_INTEGRATION'], capabilityGroups);
  const [resMedIntegrationEnabled, setResMedIntegrationEnabled] = React.useState<boolean | undefined>(undefined);
  const [totalUsageHours, setTotalUsageHours] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (resMedIntegrationAvailable) {
      fetchAvxPatientLink()
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            setResMedIntegrationEnabled(true);
            setTotalUsageHours(res[0].totalUsageHours);
          }
          setResMedIntegrationEnabled(!!(Array.isArray(res) && res.length > 0));
        })
        .catch(() => {
          return;
        });
    }
  }, []);

  const readonlyDocuments = useAppSelector((s: IState) => s.readonly.documents);

  const resMedDevices = readonlyDocuments.filter((d) => d._type === 'resMedDevice') as IPapDevice[];
  const resMedDevice =
    Array.isArray(resMedDevices) && resMedDevices.length > 0 ? resMedDevices[0].deviceModel : undefined;

  const treatmentsToBeShown = Array.isArray(activeTreatments)
    ? activeTreatments.slice(0, resMedIntegrationAvailable ? (totalUsageHours && resMedDevice ? 3 : 4) : 5)
    : undefined;

  return (
    <React.Fragment>
      {treatmentsToBeShown && (
        <TileContentMaker
          type="recentList"
          data={treatmentsToBeShown.map((t) => ({
            date: isPartialDate(t.date) ? formatPartialDate(t.date) : '',
            title: (
              <>
                {t._type === 'oxygenTherapy' && (t as IOxygenTherapy).oxygenTherapyType ? (
                  <FormattedMessage id={`treatment.oxygenTherapy.opts.${(t as IOxygenTherapy).oxygenTherapyType}`} />
                ) : (
                  <FormattedMessage id={`treatment.${t._type}.title`} />
                )}
                {'otherTreatmentType' in t ? (
                  <>
                    <span>: </span>
                    <FormattedMessage id={`treatment.otherTreatment.opts.${t.otherTreatmentType}`} />
                  </>
                ) : (
                  ''
                )}
                {t._type === 'papTherapy' && (resMedDevice || path(['devices', 0, 'type'], t)) ? (
                  <>
                    <span>: </span>
                    {resMedDevice ? (
                      resMedDevice
                    ) : (
                      <FormattedMessage id={`treatment.papTherapy.opts.${path(['devices', 0, 'type'], t)}`} />
                    )}
                  </>
                ) : (
                  ''
                )}
              </>
            ),
            endDate: 'endDate' in t && isPartialDate(t.endDate) ? formatPartialDate(t.endDate) : '',
            key: t._id,
          }))}
        />
      )}
      {totalUsageHours && resMedDevice && activeTreatments.some((at) => at._type === 'papTherapy') && (
        <TileContentMaker
          type="specificListNoDate"
          data={[
            {
              title: <FormattedMessage id="treatment.papTherapy.totalUsageHours" />,
              value: `${totalUsageHours}\u00A0\u00A0h`,
              key: 'totalUsageHours',
            },
          ]}
        />
      )}
      <TileContentMaker
        type="specificListNoDate"
        data={[
          {
            title: <FormattedMessage id="treatment.endedTreatmentsAmount" />,
            value: `${endedTreatments.length}`,
            key: 'endedTreatmentsAmount',
          },
        ]}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          visibility: typeof resMedIntegrationEnabled === 'boolean' ? 'visible' : 'hidden',
        }}
      >
        <IntegrationStatus alignItems={'center'}>
          {resMedIntegrationEnabled ? (
            <Item xs={1}>
              <Icon icon="mergeType" />
            </Item>
          ) : (
            <Item xs={1.1} style={{ marginLeft: '-0.32rem' }}>
              <Icon icon="sqicon" size={3.1} />
            </Item>
          )}
          <IntegrationStatusText>
            <span>
              <FormattedMessage id="treatment.papTherapy.resMedIntegration" />
              <span style={{ textTransform: 'lowercase' }}>
                {' '}
                <FormattedMessage
                  id={`treatment.papTherapy.resMed${resMedIntegrationEnabled ? 'InUse' : 'NotInUse'}`}
                />
              </span>
            </span>
          </IntegrationStatusText>
        </IntegrationStatus>
      </div>
    </React.Fragment>
  );
};

interface IOwnProps extends ICapabilityContextProps {
  documents: (TDocument & IDiagnosis)[];
}

export default withCapabilities(TreatmentDash);
