import { actions as sessionActions } from '../../session';
import { actions as formActions } from '../../form';
import { reduxActions } from '../actions';

import { fetchWithOptions } from '../../../utility/fetch';
import { parseJWTFromCookie, getJWTData } from '../../../utility/jwtAuthTools';
import { makeLog } from '../../../utility/logger';
import { omitControlProps } from '../../../utility/documentHandling';
import { lensPath, set } from 'ramda';
import { INeuroCommit, INeuroDocument } from 'neuro-data-structures';
import { path, take } from 'Utility/ramdaReplacement';

const refreshJWT = (): void => {
  // Refresh JWT if hour or less left on expiry
  const refreshWhen = 60 * 60 * 1000; // Minutes, seconds, milliseconds => 2 hrs
  const jwt = getJWTData();
  if (jwt && jwt.exp - refreshWhen < Date.now()) {
    sessionActions.refreshJWT();
  }
};

const createDummyDocument = (
  documentType: string,
  documentId: string,
  creatorId: string,
  creatorOrg: string,
): Partial<INeuroDocument> => ({
  documentId,
  documentType,
  createDate: Date.now(),
  creatorId,
  creatorOrg,
  ownerOrg: creatorOrg,
  removeTS: null,
  commits: [],
});
const createDummyCommit = (
  commitId: string,
  creatorId: string,
  creatorOrg: string,
  data: TAnyObject,
  commitDate?: number,
): Partial<INeuroCommit> => ({
  commitId,
  createDate: Date.now(),
  creatorId,
  commitDate: commitDate ? commitDate : null,
  creatorOrg,
  data,
});

/**
 * Create a new commit
 */
export const createNewCommit = async (
  editingDocument: { name: string; id: string },
  dispatch: any,
  formData?: TAnyObject & IControlProps,
): Promise<string> => {
  const useruuid = getJWTData()?.useruuid || null;
  const orgid = getJWTData()?.orgid || null;

  const data = formData && omitControlProps(formData);

  return await fetchWithOptions(
    `/api/documents/${editingDocument.name}/${editingDocument.id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { name: 'Commit creation', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(async (json) => {
      if (json !== false) {
        const newCommitData = createDummyCommit(json.commitId, useruuid || '', orgid || '', data || {});

        // Create new commit in redux
        dispatch(reduxActions.newCommitAction(editingDocument.id, newCommitData));
        return json.commitId;
      }
      throw { name: 'Commit creation', message: 'No commit id' };
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Update existing commit
 */
export const updateExistingCommit = async (
  editingDocument: { name: string; id: string },
  commitId: string,
  dispatch: any,
  formData: TAnyObject & IControlProps,
  newCommitCreation = false,
): Promise<string | void> => {
  refreshJWT();
  // Save commit

  const data = omitControlProps(formData);

  return await fetchWithOptions(
    `/api/documents/${editingDocument.name}/${editingDocument.id}/${commitId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(data) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res;
      } else {
        throw { name: 'Commit update', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(() => {
      // Update redux store
      !newCommitCreation && dispatch(reduxActions.updateCommitAction(editingDocument.id, commitId, false, data));
      return 'Update successful';
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Close / finish commit
 * 1. Close commit with data (body)
 * 2. Dispatch changes to redux
 */
export const updateCommitAndClose = async (
  editingDocument: { name: string; id: string },
  commitId: string,
  dispatch: any,
  formData: TAnyObject & IControlProps,
): Promise<void> => {
  const data = omitControlProps(formData);

  // Close commit
  return await fetchWithOptions(
    `/api/documents/${editingDocument.name}/${editingDocument.id}/${commitId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: JSON.stringify(data) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        // Update redux store
        dispatch(reduxActions.updateCommitAction(editingDocument.id, commitId, true, data));
      } else {
        throw { name: 'Commit closing with data', message: 'Response code not 200', fullResponse: res };
      }
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Cancels open commit based on commit id
 */
export const deleteExistingCommit = async (
  editingDocument: { name: string; id: string },
  commitId: string,
  dispatch: any,
): Promise<void> => {
  return await fetchWithOptions(
    `/api/documents/${editingDocument.name}/${editingDocument.id}/${commitId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res;
      } else {
        throw { name: 'Commit deletion', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(() => {
      // Update docs in redux
      dispatch(reduxActions.cancelCommitAction(editingDocument.id, commitId));
    })
    .catch((err) => makeLog('Error', err));
};

/**
 * Delete document
 */
export const deleteExistingDocument = async (
  editingDocument: { name: string; id: string },
  dispatch: any,
  allDocuments: INeuroDocument[],
  sortedAndMergedDocuments: Array<{ [key: string]: any } & IControlProps>,
  reason: { [key: string]: string | PartialDate },
  forced = false,
): Promise<string | void> => {
  return await fetchWithOptions(
    `/api/documents/${editingDocument.name}/${editingDocument.id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE', body: JSON.stringify({ reason, date: Date.now(), forced }) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res;
      } else {
        throw { name: 'Document deletion', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(() => {
      // Update docs in redux
      dispatch(reduxActions.deleteDocumentAction(editingDocument.id));
      return 'Document deleted';
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Create a new document
 */
export const createNewDocument = async (
  name: string,
  dispatch: any,
  /** Use noFormUpdate if we want to create a new document, but not have it affect any currently being edited forms etc */
  noFormUpdate = false,
  formData?: TAnyObject & IControlProps,
): Promise<{ _id?: string; _cid?: string } | void> => {
  const useruuid = getJWTData()?.useruuid || null;
  const orgid = getJWTData()?.orgid || null;

  return await fetchWithOptions(`/api/documents/${name}`, { neurojwt: parseJWTFromCookie() }, { method: 'POST' })
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { name: 'Document creation', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(async (json) => {
      if (json !== false) {
        // Update document store as well with new document
        const data = createDummyDocument(name, json.documentId, useruuid || '', orgid || '');

        dispatch(reduxActions.newDocumentAction(data));
        // Create new commit
        const cid = await createNewCommit({ name, id: json.documentId }, dispatch, formData);

        // We can set editing document now that we have document id
        if (!noFormUpdate && json.documentId && cid) {
          dispatch(formActions.setEditing(name, json.documentId));
        }
        return { _id: json.documentId, _cid: cid };
      } else {
        throw new Error('Document was not created');
      }
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Put a new document with data
 * This doesn't really work at the moment as the put fetch should return at least the document id for the newly created document
 * Currently it returns no JSON and procudes an error, though the document is created
 */
export const putNewDocument = async (
  name: string,
  dispatch: any,
  formData: TAnyObject,
): Promise<{ _id?: string; _cid?: string } | void> => {
  const useruuid = getJWTData()?.useruuid || null;
  const orgid = getJWTData()?.orgid || null;

  return await fetchWithOptions(
    `/api/documents/${name}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PUT', body: JSON.stringify(formData) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { name: 'Document put', message: 'Response code not 200', fullResponse: res };
      }
    })
    .then(async (json) => {
      if (json !== false) {
        // Update document store as well with new document
        let data = createDummyDocument(name, json.documentId, useruuid || '', orgid || '');
        data = {
          ...data,
          commits: [
            createDummyCommit(json.commitId, useruuid || '', orgid || '', formData, Date.now()) as INeuroCommit,
          ],
        };

        dispatch(reduxActions.newDocumentAction(data));
      } else {
        throw new Error('Document was not put');
      }
    })
    .catch((error) => makeLog('Error', error));
};

/**
 * Insert medication end reason
 */
export const insertMedicationEndReason = (
  editingDocument: { name: string; id: string },
  dispatch: any,
  endData: IMedicationEndingReason,
  formData: IMedication,
): void => {
  fetchWithOptions(
    `api/medication/endreason/${editingDocument.id}`,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'PATCH',
      body: JSON.stringify(endData),
    },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        const newCommitData = {
          commitId: 'newMedicationCommitID',
          createDate: Date.now(),
          creatorId: '',
          commitDate: Date.now(),
          organizationId: '',
          data: omitControlProps({ ...formData, ...endData }),
          creatorOrg: '',
          source: null,
          lockedFor: null,
        };
        dispatch(reduxActions.medicationEndReasonAction(editingDocument.id, newCommitData));
      } else throw new Error('Medication end reason could not be inserted');
    })
    .catch((err) => makeLog('Error', err));
};

const parseFieldPathAndPatch = (
  formData: TAnyObject,
  fieldPath: string,
  updateValue: any,
  collectionInfo?: { id: string; idFieldName: string },
) => {
  const fields = fieldPath.split('.');
  const collectionFieldNameIdx = fields.findIndex((f) => f === '$') - 1;

  let lensPathArr: Array<string | number> = fields;
  if (collectionInfo && (collectionFieldNameIdx || collectionFieldNameIdx === 0)) {
    const collection = path(take(collectionFieldNameIdx + 1, fields), formData) as [{ [key: string]: string }];
    const collectionItemIndex = collection.findIndex((c) => c[collectionInfo?.idFieldName] === collectionInfo.id);
    lensPathArr = fields.map((f) => (f === '$' ? collectionItemIndex : f));
  }
  const newFormData = set(lensPath(lensPathArr), updateValue, formData);
  return newFormData;
};

/**
 * Change a field in a document through different api (no need to create a commit)
 * This should only be used to change fields in locked documents
 */
export const changeFieldInDocument = (
  editingDocument: { name: string; id: string },
  dispatch: any,
  formData: TAnyObject & IControlProps,
  newData: TAnyObject,
  fieldLocked: boolean,
  collectionInfo?: { id: string; idFieldName: string },
): void => {
  const useruuid = getJWTData()?.useruuid || null;
  const orgid = getJWTData()?.orgid || null;
  if (!useruuid || !orgid) return;

  const newDataKeys = Object.keys(newData);

  let body: TAnyObject | TAnyObject[] = {};
  if (newDataKeys.length === 1) {
    body = {
      fieldPath: newDataKeys[0], // Field path to update (e.g. "foo.bar.$.test"")
      updateValue: newData[newDataKeys[0]], // The value to which the field should be set
      lookupField: collectionInfo?.idFieldName, // Field to use for looking up correct object in a collection (e.g. "id")
      lookupValue: collectionInfo?.id, // Value to match (e.g. "123")
    };
  } else {
    body = newDataKeys.map((key) => ({ fieldPath: key, updateValue: newData[key] }));
  }

  fetchWithOptions(
    `api/fieldpatch/${editingDocument.name}/${editingDocument.id}`,
    { neurojwt: parseJWTFromCookie() },
    {
      method: 'PATCH',
      body: JSON.stringify(body),
    },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        const newCommitData: INeuroCommit = {
          commitId: 'newDocumentCommitID',
          createDate: Date.now(),
          creatorId: useruuid,
          commitDate: Date.now(),
          creatorOrg: orgid,
          source: null,
          lockedFor: null,
          data:
            newDataKeys.length === 1
              ? omitControlProps({
                  ...(parseFieldPathAndPatch(
                    formData,
                    newDataKeys[0],
                    newData[newDataKeys[0]],
                    collectionInfo,
                  ) as TAnyObject & IControlProps),
                })
              : omitControlProps({ ...formData, ...newData }),
        };
        if (fieldLocked) dispatch(reduxActions.modifyLockedFieldAction(editingDocument.id, newCommitData));
        else dispatch(reduxActions.modifyFieldAction(editingDocument.id, newCommitData));
      } else throw new Error('Field value could not be updated with api/fieldpatch');
    })
    .catch((err) => makeLog('Error', err));
};

/**
 * Lifecare sync
 */
export const lifecareSync = async (): Promise<boolean> => {
  return await fetchWithOptions(`/api/v1/lifecare/diagnosis`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res: Response) => {
      if (res.status === 200) {
        return true;
      } else {
        throw { name: 'Lifecare sync', message: 'Response code not 200', fullResponse: res };
      }
    })
    .catch((error) => {
      makeLog('Error', error);
      return false;
    });
};

/**
 * Get single document by type and id
 */
export const getDocument = async (documentType: string, documentId: string): Promise<INeuroDocument | false> => {
  return await fetchWithOptions(
    `/api/documents/${documentType}/${documentId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else {
        throw { name: 'Document update', message: 'Response code not 200', fullResponse: res };
      }
    })
    .catch((error) => {
      makeLog('Error', error);
      return false;
    });
};
