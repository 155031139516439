import { IAddon, IData, IDataPoint, IEvent, IItem, TDescriptionTable } from 'Components/sq-graphics/interfaces';
import {
  addDays,
  calculateDaysDifference,
  exists,
  formatPartialDate,
  isPartialDate,
  partialDateFromDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import { equals, flatten } from 'ramda';
import { formatSeizureClassification } from 'Routes/Seizure/utils';
import {
  infoFields,
  neurologicalComplications,
  otherComplications,
  surgicalComplications,
} from 'Routes/SurgicalTreatment/Document/Form/Complications';
import createGraphDescriptions from './createGraphDescriptions';
import { uniq } from 'Utility/ramdaReplacement';

export const convertContraceptionToTimeline = (
  docs: Array<IContraception>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    const items: IItem[] = [];
    if (doc.contraceptiveClassification === 'noContraception') {
      items.push({
        start: dateFromPartialUpdateTimeframe(doc.date),
        end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
        title: fm('comorbidity.opts.contraceptiveClassification.noContraception'),
        description: [
          {
            title: fm('comorbidity.cause'),
            values: doc.cause,
          },
        ],
      });
      addons.push({
        id: 'noContraception',
        title: fm('comorbidity.opts.contraceptiveClassification.noContraception'),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    } else {
      items.push({
        start: dateFromPartialUpdateTimeframe(doc.date),
        end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
        title: fm('comorbidity.methodOfContraception'),
        description: [
          {
            title: fm('comorbidity.classification'),
            values: doc.contraceptiveClassification
              ? fm(`comorbidity.opts.contraceptiveClassification.${doc.contraceptiveClassification}`)
              : undefined,
          },
          {
            title: fm('comorbidity.methodOfContraception'),
            values: doc.contraceptive ? fm(`comorbidity.opts.contraceptive.${doc.contraceptive}`) : undefined,
          },
        ],
      });
      addons.push({
        id: 'contraception',
        title: fm('comorbidity.methodOfContraception'),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    }
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertPregnancyToTimeline = (
  docs: Array<IPregnancy>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.date)) return;
    const items: IItem[] = [];
    items.push({
      start: dateFromPartialUpdateTimeframe(doc.date),
      end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
      title: fm('comorbidity.pregnancy'),
    });
    addons.push({
      id: 'pregnancy',
      title: fm('comorbidity.pregnancies'),
      titleDescription: undefined,
      items: items,
      events: [],
    });
  });
  return addons.length > 0 ? addons : undefined;
};

export const convertDietTherapyToTimeline = (
  docs: Array<IDietTherapy>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.startDate)) return;
    const items: IItem[] = [];
    items.push({
      start: dateFromPartialUpdateTimeframe(doc.startDate),
      end: isPartialDate(doc.endDate) ? dateFromPartialUpdateTimeframe(doc.endDate) : undefined,
      title: fm('dietTherapy.title'),
      description: [
        {
          title: fm('dietTherapy.reason'),
          values:
            Array.isArray(doc.reason) && doc.reason.length > 0
              ? doc.reason.map((r) => fm(`dietTherapy.opts.${r}`)).join(', ')
              : undefined,
        },
        {
          title: fm('dietTherapy.diet'),
          values: doc.diet !== undefined && doc.diet.length > 0 ? fm(`dietTherapy.opts.${doc.diet}`) : undefined,
        },
      ],
    });
    addons.push({
      id: 'dietTherapy',
      title: fm('dietTherapy.title'),
      titleDescription: undefined,
      items: items,
      events: [],
    });
  });
  return addons.length > 0 ? addons : undefined;
};

const symptoms: { [key in NonNullable<IOtherSymptoms['classification']>]: Array<IOtherSymptoms['symptoms']> } = {
  mentalSymptoms: ['anxiety', 'moodSymptoms', 'delusions', 'suicidalThoughts', 'obsessions', 'other'],
  cognitiveSymptoms: [
    'recallDegradation',
    'learningDisabilities',
    'skillLossOrDegradation',
    'difficultyConcentrating',
    'socialInteractionProblems',
    'other',
  ],
  developmentDeceleration: ['motoric', 'linguistic', 'wide', 'other'],
  neurologicalSymptoms: ['vertigo', 'headache', 'visualImpairment', 'tremor', 'other'],
  behavioralDisorders: ['aggressiveness', 'impulsiveness', 'hyperactivity', 'defiance', 'other'],
  eatingProblems: ['lackOfAppetite', 'increaseOfAppetite', 'swallowingProblems', 'other'],
  otherSymptoms: ['difficultySleeping', 'fatigue', 'other'],
};

const otherSymptomsDescriptionGenerator = (
  c: IOtherSymptoms['classification'],
  docs: IOtherSymptoms[],
  fm: (id: string) => string,
): IEvent['description'] => {
  return [
    {
      title: `${fm('graph.otherSymptomsInfo')}:`,
    },
  ].concat(
    c
      ? (symptoms?.[c] || []).map((s) => {
          const dates =
            docs.filter((d) => d.symptoms === s).length > 0
              ? docs
                  .filter((d) => d.symptoms === s)
                  .map((d) => {
                    return isPartialDate(d.endDate)
                      ? `${formatPartialDate(d.date)} - ${formatPartialDate(d.endDate)}`
                      : `${formatPartialDate(d.date)} ->`;
                  })
              : undefined;
          return {
            title: `${fm(`otherSymptoms.${c}.opts.${s}`)}`,
            values: dates,
            condition: exists(dates),
          };
        })
      : [],
  );
};

export const convertOtherSymptomToTimeline = (
  docs: Array<IOtherSymptoms>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];

  // Assign all the classifications a patient has into a variable
  const classifications = uniq(docs.map((d) => d.classification));
  // Iterate through classifications
  classifications.forEach((c) => {
    const classificationDocs = docs.filter((d) => d.classification === c && Array.isArray(d.date) && d.date[0]);
    classificationDocs.sort((n1, n2) => sortPartialDate(n1.date, n2.date));
    const startDate: PartialDate | undefined =
      Array.isArray(classificationDocs) && classificationDocs.length > 0 && classificationDocs[0]
        ? classificationDocs[0].date
        : undefined;
    // Check if there are any docs where endDate has been set
    const anyEndedDocs = !!classificationDocs.find((d) => isPartialDate(d.endDate));
    const items: IItem[] = [];
    // If there are no docs with enddates, this is easy
    // We can also handle here the case where we have only 1 document
    if (!anyEndedDocs || classificationDocs.length === 1) {
      const endDate =
        classificationDocs.length === 1 && isPartialDate(classificationDocs?.[0].endDate)
          ? dateFromPartialUpdateTimeframe(classificationDocs?.[0].endDate)
          : undefined;
      const localizedClassification = c ? fm(`otherSymptoms.${c}.title`) : fm('otherSymptoms.unclassified');
      startDate &&
        items.push({
          start: dateFromPartialUpdateTimeframe(startDate),
          end: endDate,
          title: localizedClassification,
          alternativeDateString: '',
          description: otherSymptomsDescriptionGenerator(c, classificationDocs, fm),
        });
      addons.push({
        id: localizedClassification,
        title: localizedClassification,
        titleDescription: undefined,
        items: items,
        events: [],
      });
    } else {
      // Otherwise...
      let index = 0;
      classificationDocs
        .slice()
        .sort((a, b) => sortPartialDate(a.date, b.date))
        .forEach((d, i, arr) => {
          if (i === index) {
            const docsInTimeframe = !isPartialDate(d.endDate)
              ? arr.slice(index + 1)
              : arr.slice(index + 1).filter((d1) => sortPartialDate(d.endDate, d1.date) > 0);
            const mergedDocs = [d].concat(docsInTimeframe);
            const endDate: PartialDate | undefined = mergedDocs[mergedDocs.length - 1].endDate;
            d.date &&
              items.push({
                start: dateFromPartialUpdateTimeframe(d.date),
                end: endDate ? dateFromPartialUpdateTimeframe(endDate) : undefined,
                alternativeDateString: '',
                title: fm(`otherSymptoms.${c}.title`),
                description: otherSymptomsDescriptionGenerator(c, mergedDocs, fm),
              });
            index += mergedDocs.length;
          }
        });
      addons.push({
        id: fm(`otherSymptoms.${c}.title`),
        title: fm(`otherSymptoms.${c}.title`),
        titleDescription: undefined,
        items: items,
        events: [],
      });
    }
  });
  return addons.length > 0 ? addons : undefined;
};

const locationsDescription = (operation: any, fm: (id: string) => string): IEvent['description'] => {
  const locPath = 'surgicalTreatment';

  const typeOfOperation = operation.typeOfOperation;
  const locations = operation.locations;

  const locationsList =
    typeOfOperation && locations && locations.length > 0
      ? locations.map((location: any) => {
          const loc = location.location
            ? fm(`${locPath}.${typeOfOperation}.locations.location.opts.${location.location}`)
            : '';
          const side = location.sideOfRemoval
            ? fm(`${locPath}.${typeOfOperation}.locations.sideOfRemoval.opts.${location.sideOfRemoval}`)
            : undefined;
          const area = location.areaOfRemoval
            ? fm(`${locPath}.${typeOfOperation}.locations.areaOfRemoval.opts.${location.areaOfRemoval}`)
            : undefined;
          const info = location.otherInfo ?? undefined;

          const additionalInfo =
            location.sideOfRemoval || location.areaOfRemoval || location.otherInfo
              ? ' (' + [side, area, info].filter((a) => !!a).join(', ') + ')'
              : '';
          return loc + additionalInfo;
        }, locations)
      : [];
  const locationTitle = typeOfOperation ? fm(`${locPath}.${typeOfOperation}.locations.header`) : '-';
  const locationDescription = [
    {
      title: locationTitle,
      values: locationsList.length > 0 ? locationsList.join(', ') + '.' : undefined,
    },
  ];
  return locationDescription;
};

const disconnectionsDescription = (operation: IDisconnection, fm: (id: string) => string): IItem['description'] => {
  const typeOfOperation = operation.typeOfOperation;

  const locPath = `surgicalTreatment.${typeOfOperation}`;
  const disconnections = operation.disconnections;

  const disconnectionsList =
    disconnections && disconnections.length > 0
      ? disconnections.map((disconnection: IDisconnectionType) => {
          const type = disconnection.type;
          const typeTitle = disconnection.type ? fm(`${locPath}.disconnections.type.opts.${disconnection.type}`) : '';

          const block =
            'block' in disconnection && (disconnection as IBlockDisconnetion).block && type
              ? fm(`${locPath}.disconnections.${type}.block.opts.${(disconnection as IBlockDisconnetion).block}`)
              : undefined;
          const disc =
            'disconnection' in disconnection && (disconnection as IHypothalamicHamartoma).disconnection && type
              ? fm(
                  `${locPath}.disconnections.${type}.disconnection.opts.${
                    (disconnection as IHypothalamicHamartoma).disconnection
                  }`,
                )
              : undefined;
          const scope =
            'scope' in disconnection && (disconnection as ISkullsotomy).scope && type
              ? fm(`${locPath}.disconnections.${type}.scope.opts.${(disconnection as ISkullsotomy).scope}`)
              : '';
          const side =
            'side' in disconnection && (disconnection as IBlockDisconnetion).side && type
              ? fm(`${locPath}.disconnections.${type}.side.opts.${(disconnection as IBlockDisconnetion).side}`)
              : undefined;
          const loc =
            'location' in disconnection && (disconnection as IFunctionalPartiaHemispherectomy).location && type
              ? fm(
                  `${locPath}.disconnections.${type}.location.opts.${
                    (disconnection as IFunctionalPartiaHemispherectomy).location
                  }`,
                )
              : undefined;
          const info =
            'otherInfo' in disconnection && (disconnection as IBlockDisconnetion).otherInfo
              ? (disconnection as IBlockDisconnetion).otherInfo
              : undefined;

          const additionalInfo =
            block || disc || scope || side || loc || info
              ? ' (' + [block, disc, scope, side, loc, info].filter((a) => !!a).join(', ') + ')'
              : '';

          return typeTitle + additionalInfo;
        })
      : [];

  const disconnectionsTitle = fm(`${locPath}.disconnections.type.title`);
  const disconnectionsDescription = [
    {
      title: disconnectionsTitle,
      values: disconnectionsList.length > 0 ? disconnectionsList.join(', ') + '.' : undefined,
    },
  ];

  return disconnectionsDescription;
};

const cyberKnifeArea = (operation: ICyberKnife, fm: (id: string) => string): IEvent['description'] => {
  const typeOfOperation = operation.typeOfOperation;

  const locPath = `surgicalTreatment.${typeOfOperation}`;
  const areas = operation.areas;

  const areasList =
    areas && areas.length > 0
      ? areas.map((area: ICyberKnifeArea) => {
          const ar = area.area ? fm(`${locPath}.areas.area.opts.${area.area}`) : '';
          const side = area.side ? fm(`${locPath}.areas.side.opts.${area.side}`) : undefined;
          const other = area.other;
          const info = side || other ? ' (' + [side, other].filter((a) => !!a).join(', ') + ')' : '';
          return ar + info;
        })
      : [];

  const areasTitle = fm(`${locPath}.areas.header`);
  const areasDescription = [
    {
      title: areasTitle,
      values: areasList.length > 0 ? areasList.join(', ') + '.' : undefined,
    },
  ];

  return areasDescription;
};

const coagulationArea = (operation: IThermocoagulation, fm: (id: string) => string): IEvent['description'] => {
  const typeOfOperation = operation.typeOfOperation;

  const locPath = `surgicalTreatment.${typeOfOperation}.coagulations`;
  const coagulations = operation.coagulations;

  const areasList =
    coagulations && coagulations.length > 0
      ? coagulations.map((coagulation: ICoagulation) => {
          const ar = coagulation.coagulationArea
            ? fm(`${locPath}.coagulationArea.opts.${coagulation.coagulationArea}`)
            : '';
          const side = coagulation.side ? fm(`${locPath}.side.opts.${coagulation.side}`) : '';
          const seegLocation = coagulation.seegLocation
            ? fm(`${locPath}.seegLocation.opts.${coagulation.seegLocation}`)
            : '';
          const stimulationAssist = coagulation.stimulationAssist
            ? fm(`${locPath}.stimulationAssist.opts.${coagulation.stimulationAssist}`)
            : '';

          const additionalInfo =
            side || seegLocation || stimulationAssist
              ? ' (' + [side, seegLocation, stimulationAssist].filter((a) => !!a).join(', ') + ')'
              : '';

          return ar + additionalInfo;
        })
      : [];

  const areasTitle = fm(`${locPath}.coagulationArea.title`);
  const areasDescription = [
    {
      title: areasTitle,
      values: areasList.length > 0 ? areasList.join(', ') + '.' : undefined,
    },
  ];

  return areasDescription;
};

const intracranialImaging = (operation: IIntracranialImaging, fm: (id: string) => string): IEvent['description'] => {
  const imagingType =
    operation.imagingType && fm(`surgicalTreatment.intracranialImaging.opts.${operation.imagingType}`);
  const number = operation.electrodeNumber;
  const location =
    operation.electrodeLocation &&
    fm(`surgicalTreatment.stereoEEG.electrodeLocation.opts.${operation.electrodeLocation}`);

  const registerDescription = [
    {
      title: fm('surgicalTreatment.intracranialImaging.title'),
      values: imagingType,
      condition: exists(imagingType),
    },
    {
      title: fm('surgicalTreatment.stereoEEG.electrodeNumber.title'),
      values: number,
      condition: exists(number),
    },
    {
      title: fm('surgicalTreatment.stereoEEG.electrodeLocation.title'),
      values: location,
      condition: exists(location),
    },
  ];

  return registerDescription.length > 0 ? registerDescription : undefined;
};

const pad = (pad: IPad, fm: (id: string) => string): string | undefined => {
  const locPath = 'surgicalTreatment.pad';

  const findings = pad.findings ? fm(`${locPath}.findings.opts.${pad.findings}`) : '';
  const subType = pad.subType ? ` (${fm(`${locPath}.subType.opts.${pad.subType}`)})` : '';
  const otherInfo = pad.otherInfo ? ` (${pad.otherInfo})` : '';

  const padInfo = `${findings}${subType}${otherInfo}`;

  return padInfo.length > 0 ? padInfo : undefined;
};

const complicationDescription = (complications: IComplications, fm: (id: string) => string): IEvent['description'] => {
  const locPath = 'surgicalTreatment.complications';

  const date = complications.date;
  const complication = complications.complication;
  const complicationCededDate = complications.complicationCededDate;

  const dateAndComplications = [
    {
      title: fm('general.date'),
      values: isPartialDate(date) ? formatPartialDate(date) : undefined,
    },
    {
      title: fm(`${locPath}.complication.title`),
      values: complication
        ? surgicalComplications.includes(complication)
          ? fm(`${locPath}.surgical.opts.${complication}`)
          : neurologicalComplications.includes(complication)
            ? fm(`${locPath}.neurological.opts.${complication}`)
            : otherComplications.includes(complication)
              ? fm(`${locPath}.other.opts.${complication}`)
              : '-'
        : undefined,
      condition: exists(complication),
    },
  ];
  const info = infoFields
    .map((f) => {
      const key = f as keyof IComplications;
      return complications[key] ? { title: fm(`${locPath}.specification`), values: complications[key] } : undefined;
    })
    .filter((el) => el) as Array<{ title: string; values: string }>;
  const cededDate = [
    {
      title: fm(`${locPath}.complicationCededDate`),
      values: formatPartialDate(complicationCededDate),
      condition: isPartialDate(complicationCededDate),
    },
  ];

  return [...dateAndComplications, ...info, ...cededDate];
};

const operationEvent = (
  surgicalTreatment: ISurgicalTreatment,
  date: PartialDate,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IEvent => {
  const locPath = 'surgicalTreatment.operation';
  const operations = surgicalTreatment.operations ?? [];
  const pads = surgicalTreatment.pads ?? [];
  const complications = surgicalTreatment.complications ?? [];

  let title =
    operations.length > 0 && operations[0].typeOfOperation
      ? fm(`${locPath}.typeOfOperation.opts.${operations[0].typeOfOperation}`)
      : fm('surgicalTreatment.operation.unspecifiedOperation');

  if (operations.length > 1) {
    title =
      title +
      ' / ' +
      (operations[1].typeOfOperation
        ? fm(`${locPath}.typeOfOperation.opts.${operations[1].typeOfOperation}`)
        : fm('surgicalTreatment.operation.unspecifiedOperation'));
  }

  const operationsDescriptions = operations
    .map((o) => {
      switch (o.typeOfOperation) {
        case 'localResection':
        case 'lesionectomy':
          return 'locations' in o ? locationsDescription(o, fm) : undefined;
        case 'disconnection':
          return 'disconnections' in o ? disconnectionsDescription(o, fm) : undefined;
        case 'cyberKnife':
          return 'areas' in o ? cyberKnifeArea(o, fm) : undefined;
        case 'thermoCoagulation':
          return 'coagulations' in o ? coagulationArea(o, fm) : undefined;
        case 'intracranialImaging':
          return intracranialImaging(o, fm);
        default:
          return undefined;
      }
    })
    .filter((el) => el);

  const padDescriptions = [
    {
      title: fm('surgicalTreatment.pad.header'),
      condition: Array.isArray(pads) && pads.length > 0,
      style: { fontWeight: 'bold' },
    },
    {
      title: fm('surgicalTreatment.pad.findings.title'),
      values: pads
        .map((p: IPad, i: number, a: Array<IPad>) => `${pad(p, fm)}${i < a.length - 1 ? ', ' : '.'}`)
        .join('\n'),
      condition: Array.isArray(pads) && pads.length > 0,
    },
  ];

  const complicationDescriptions = [
    {
      title: fm('surgicalTreatment.complications.header'),
      condition: Array.isArray(complications) && complications.length > 0,
      style: { fontWeight: 'bold' },
    },
    ...complications.map(
      (c: IComplications, i: number) =>
        (complicationDescription(c, fm) as TDescriptionTable)?.concat(
          i > 0 && i === complications?.length - 1 ? [{ title: '' }] : [],
        ),
    ),
  ];

  const allDescriptions = flatten([
    operationsDescriptions,
    padDescriptions,
    complicationDescriptions,
  ]) as IEvent['description'];

  const event: IEvent = {
    date: dateFromPartialUpdateTimeframe(date),
    title: title,
    eventType: 'treatment',
    description: allDescriptions,
  };
  return event;
};

export const convertSurgicalTreatmentToTimeline = (
  docs: Array<ISurgicalTreatment>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IAddon> | undefined => {
  if (docs.length === 0) return undefined;
  const addOns: Array<IAddon> = [];
  docs.forEach((doc) => {
    const events: Array<IEvent> = [];
    if (doc.operations && doc.operations.length > 0 && doc.date) {
      const opEvent: IEvent = operationEvent(doc, doc.date, dateFromPartialUpdateTimeframe, fm);
      events.push(opEvent);
    }
    events.length > 0 &&
      addOns.push({
        id: 'surgicalTreatment',
        title: fm('surgicalTreatment.title'),
        events: events,
      });
  });
  return addOns.length > 0 ? addOns : undefined;
};

export const convertModifiedRankinScaleToGraph = (
  docs: Array<IModifiedRankinScale>,
  dateFromPartialUpdateTimeFrame: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!d || !isPartialDate(d.date)) return;
    d.date &&
      (d.rating || d.rating === 0) &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeFrame(d.date),
        value: d.rating,
        id: 'mrs',
        title: d.rating.toString(),
        description: fm(`functionalPerformance.opts.modifiedRankinScale.rating.${d.rating}`),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'mrs',
      type: 'lineGraph',
      legend: fm('functionalPerformance.modifiedRankinScale'),
    },
  ];
};

export const convertModifiedChildrensGlobalAssessmentScaleToGraph = (
  docs: Array<IModifiedChildrensGlobalAssessmentScale>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date) || !exists(d.rating)) return;
    d.date &&
      (d.rating || d.rating === 0) &&
      dataPoints.push({
        date: dateFromPartialUpdateTimeframe(d.date),
        value: d.rating,
        id: 'mcgas',
        description: fm(`functionalPerformance.opts.modifiedChildrensGlobalAssessmentScale.rating.${d.rating}`),
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'mcgas',
      legend: fm('functionalPerformance.modifiedChildrensGlobalAssessmentScaleShort'),
      type: 'lineGraph',
    },
  ];
};

export const convertEngelClassificationToGraph = (
  docs: Array<ISurgicalTreatment>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): Array<IData> | undefined => {
  if (docs.length === 0) return undefined;
  const dataPoints: IDataPoint[] = [];
  docs.forEach((d) => {
    if (!isPartialDate(d.date)) return;
    d.date &&
      d.engelClassification &&
      d.engelClassification.forEach((e) => {
        if (!e.classification) return;
        const description: TDescriptionTable = createGraphDescriptions<
          Required<ISurgicalTreatment>['engelClassification'][number]
        >(e, [
          {
            dataKey: 'timeFromOperation',
            localizers: {
              title: () => fm('surgicalTreatment.engelClassification.timeFromOperation.title'),
              values: (d) => fm(`surgicalTreatment.engelClassification.timeFromOperation.opts.${d.timeFromOperation}`),
            },
          },
          {
            dataKey: 'classification',
            localizers: {
              title: (d) => fm(`surgicalTreatment.engelClassification.graph.value.opts.${d.classification}`),
            },
          },
        ]);
        e.date &&
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(e.date),
            value: fm(`surgicalTreatment.engelClassification.graph.scale.opts.${e.classification}`),
            id: 'engel',
            description: description,
          });
      });
  });
  return [
    {
      dataPoints: dataPoints,
      id: 'engel',
      type: 'lineGraph',
      legend: fm('surgicalTreatment.engelClassification.title'),
    },
  ];
};

const getDocDate = (doc: ISeizure | (IPatientReportedSeizure & IControlProps)): PartialDate | undefined => {
  if ('date' in doc && isPartialDate(doc.date)) {
    return doc.date;
  } else {
    if ('startDate' in doc && isPartialDate(doc.startDate)) return doc.startDate;
  }
  return undefined;
};

const getDocumentClassification = (
  doc: ISeizure | (IPatientReportedSeizure & IControlProps),
): TSeizureClassification | undefined => {
  if ('classification' in doc) {
    return doc.classification;
  } else if ('seizureType' in doc) {
    return doc.seizureType;
  }
  return undefined;
};

const getYearAndMonth = (pdate: PartialDate): [number, number] => {
  return [pdate[0], pdate[1] ?? 1];
};

const getAlternativeDateStringSeizure = (month: [number, number]): string => {
  return `${month?.[0]}-${month?.[1] && month[1] < 10 ? '0' + month[1] : month[1]}`;
};

export const convertSeizuresToGraph = (
  docs: Array<ISeizure | (IPatientReportedSeizure & IControlProps)>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
  type: 'total' | 'byType' | 'daily',
): Array<IData> | undefined => {
  if (docs.length === 0 || !fm) return undefined;

  const inputDocs = docs.filter((d) => getDocDate(d)).sort((d1, d2) => sortPartialDate(getDocDate(d1), getDocDate(d2)));

  // Calculate seizures / day -datapoints
  if (type === 'daily') {
    const dataPoints: IDataPoint[] = [];

    const firstInputDoc = inputDocs[0];
    let sd = getDocDate(firstInputDoc);
    let todaySeizures = 0;
    const remainingInterval: Array<{ [key: number]: number }> = []; // key = date and value = amount of seizures from interval
    inputDocs.forEach((d, i) => {
      const comparisonArray = sd?.map((n, ind) => {
        const inpDoc = inputDocs[i];
        const inpDocDate = getDocDate(inpDoc);
        return n === inpDocDate?.[ind];
      });
      const isSame = comparisonArray?.every((boolean) => boolean);
      if (isSame) {
        if (d?.endDate && isPartialDate(d.endDate)) {
          const daysInInterval = (calculateDaysDifference(getDocDate(d), d.endDate) ?? 0) + 1; // + 1 as we want to include the endDate
          const averageSeizuresPerDayInterval = parseFloat(((d?.count ?? 0) / (daysInInterval ?? 1)).toFixed(1));
          todaySeizures += averageSeizuresPerDayInterval;

          const remainingDays = daysInInterval ? daysInInterval - 1 : 0;
          for (let j = 1; j <= remainingDays; j++) {
            const loopDay = partialDateToValue(getDocDate(d)) + j * 86400000;
            if (remainingInterval.find((ri) => Object.keys(ri)[0] === String(loopDay))) {
              const loopDayIndex = remainingInterval.findIndex((ri) => Object.keys(ri)[0] === String(loopDay));
              remainingInterval.splice(loopDayIndex, 1, {
                [loopDay]: remainingInterval[loopDayIndex][loopDay] + averageSeizuresPerDayInterval,
              });
            } else {
              remainingInterval.push({ [loopDay]: averageSeizuresPerDayInterval });
            }
          }
        } else todaySeizures += d?.count ?? 0;
      }
      const nextDoc = inputDocs[i + 1];
      const nextDocDate = nextDoc ? getDocDate(nextDoc) : undefined;
      const comparisonArrayNext = sd?.map((n, ind) => n === nextDocDate?.[ind]);
      const truth = comparisonArrayNext?.every((boolean) => boolean);
      if (!truth) {
        todaySeizures > 0 &&
          !!sd &&
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(sd),
            value: todaySeizures,
            title: todaySeizures.toString(),
            id: 'todaySeizures',
          });
        sd = nextDocDate;
        todaySeizures = 0;
      }
    });
    remainingInterval.forEach((ri) => {
      Object.entries(ri).forEach(([date, val]) => {
        const remainingDateToPartial = partialDateFromDate(new Date(Number(date)));
        const dataPointIndex = dataPoints.findIndex(
          (dp) => dp.date.getTime() === dateFromPartialUpdateTimeframe(remainingDateToPartial).getTime(),
        );
        if (dataPointIndex > -1) {
          dataPoints.splice(dataPointIndex, 1, {
            date: dateFromPartialUpdateTimeframe(remainingDateToPartial),
            value: Number(dataPoints[dataPointIndex]?.value) + val,
            title: (Number(dataPoints[dataPointIndex]?.value) + val).toString(),
            id: 'todaySeizures',
          });
        } else {
          dataPoints.push({
            date: dateFromPartialUpdateTimeframe(remainingDateToPartial),
            value: val,
            title: val.toString(),
            id: 'todaySeizures',
          });
        }
      });
    });
    return [
      {
        dataPoints: dataPoints,
        id: 'seizuresPerDay',
        legend: fm('seizure.seizuresPerDay'),
        type: 'lineGraph',
        linegraphProps: { defaultToZero: true },
      },
    ];
  } else {
    const dataPointsTotalMonth: IDataPoint[] = [];

    const seizureCounts: Array<{
      month: [number, number];
      valueTotal: number;
      valueClassifications: Array<{
        classification: TSeizureClassification;
        value: number;
      }>;
    }> = [];

    inputDocs.forEach((d) => {
      const date = getDocDate(d);
      const docClassification = getDocumentClassification(d);
      if (!date || !d?.count || d.count <= 0 || !docClassification || docClassification === 'notSeizure') return;
      const endDate = d?.endDate ? d.endDate : date;
      const daysInInterval = (calculateDaysDifference(date, endDate) ?? 0) + 1; // + 1 as we want to include the endDate
      const averageSeizuresPerDay = d.count / daysInInterval;

      let loopDay = date;
      while (sortPartialDate(loopDay, endDate) <= 0) {
        const loopingMonth = getYearAndMonth(loopDay);
        const seizureCountIndex = seizureCounts.findIndex((sc) => equals(sc.month, loopingMonth));
        if (seizureCountIndex === -1) {
          seizureCounts.push({
            month: loopingMonth,
            valueTotal: averageSeizuresPerDay,
            valueClassifications: [{ classification: docClassification, value: averageSeizuresPerDay }],
          });
        } else {
          const currentSeizureCount = seizureCounts[seizureCountIndex];
          const newTotalCount = currentSeizureCount.valueTotal + averageSeizuresPerDay;
          currentSeizureCount.valueTotal = newTotalCount;
          const classificationIndex = currentSeizureCount.valueClassifications.findIndex(
            (vc) => vc.classification === docClassification,
          );
          if (classificationIndex === -1) {
            currentSeizureCount.valueClassifications.push({
              classification: docClassification,
              value: averageSeizuresPerDay,
            });
          } else {
            const currentClassificationValue = currentSeizureCount.valueClassifications[classificationIndex];
            const newClassificationValue = currentClassificationValue.value + averageSeizuresPerDay;
            currentSeizureCount.valueClassifications[classificationIndex].value = newClassificationValue;
          }
          seizureCounts[seizureCountIndex] = currentSeizureCount;
        }
        loopDay = addDays(loopDay, 1);
      }
    });

    const dataPointsClassification: Array<{ id: TSeizureClassification; datapoints: IDataPoint[] }> = [];

    seizureCounts.forEach((sc) => {
      dataPointsTotalMonth.push({
        date: new Date(sc.month?.[0], sc.month?.[1] - 1),
        value: Number(sc.valueTotal.toFixed(1)),
        id: 'total',
        title: sc.valueTotal.toFixed(1),
        alternativeDateString: getAlternativeDateStringSeizure(sc.month),
      });

      sc.valueClassifications.forEach((vc) => {
        const dataPointIndex = dataPointsClassification.findIndex((dpcm) => dpcm.id === vc.classification);
        if (dataPointIndex === -1) {
          dataPointsClassification.push({
            id: vc.classification,
            datapoints: [
              {
                date: new Date(sc.month?.[0], sc.month?.[1] - 1),
                value: Number(vc.value.toFixed(1)),
                id: vc.classification,
                title: vc.value.toFixed(1),
                alternativeDateString: getAlternativeDateStringSeizure(sc.month),
              },
            ],
          });
        } else {
          dataPointsClassification[dataPointIndex].datapoints.push({
            date: new Date(sc.month?.[0], sc.month?.[1] - 1),
            value: Number(vc.value.toFixed(1)),
            id: vc.classification,
            title: vc.value.toFixed(1),
            alternativeDateString: getAlternativeDateStringSeizure(sc.month),
          });
        }
      });
    });

    if (type === 'total') {
      return [
        {
          dataPoints: dataPointsTotalMonth,
          id: 'seizuresPerMonth',
          type: 'stackedBarChart',
          legend: fm('seizure.seizuresTotal'),
        },
      ];
    }

    const classificationData: IData[] = dataPointsClassification.map((dp) => {
      return {
        dataPoints: dp.datapoints,
        id: dp.id,
        legend: dp.id,
        type: 'stackedBarChart',
        legendDescription: { title: dp.id, description: formatSeizureClassification(dp.id, 'long', false, true) },
      };
    });

    if (type === 'byType') {
      return [
        {
          dataPoints: dataPointsTotalMonth,
          id: 'monthlyTotal',
          legend: '',
          type: 'boxGraph',
        },
        ...classificationData,
      ];
    }
  }

  return;
};

export const convertSeizureMedicationToTimeline = (
  docs: Array<IPatientReportedSeizure>,
  dateFromPartialUpdateTimeframe: (date: PartialDate, time?: Time, marker?: true) => Date,
  fm: (id: string) => string,
): IAddon[] | undefined => {
  if (docs.length === 0) return undefined;
  const addons: Array<IAddon> = [];
  const items: IItem[] = [];
  const events: IEvent[] = [];
  docs.forEach((doc) => {
    if (!isPartialDate(doc.startDate) || !doc.seizureMedicationGiven) return;
    events.push({
      date: dateFromPartialUpdateTimeframe(doc.startDate),
      title: fm('seizure.seizureMedicationGiven'),
      eventType: 'selfReport',
    });
  });
  addons.push({
    id: 'seizureMedication',
    title: fm('seizure.seizureMedication'),
    titleDescription: undefined,
    items: items,
    events: events,
  });
  return addons.length > 0 ? addons : undefined;
};
