import * as React from 'react';
import { styled, Theme } from '@mui/system';
import { alpha } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { useIntl } from 'react-intl';
import { Key, PlayArrow } from '@mui/icons-material';
import OTPDialog from 'Routes/MyService/Dash/OTPDialog';
import { makeLog } from 'Utility/logger';
import colors from '../../../../../config/theme/colors';
import { take } from 'Utility/ramdaReplacement';
import { useAppSelector } from 'Store/index';
import TileContentMaker from 'Components/DashboardTile/TileContentMaker';

const TileHeader = styled(Item)(({ theme }: { theme?: Theme }) => ({
  height: '5rem',
  color: theme?.customPalette.myms.primary.main,
  cursor: 'default',
}));

const TileFooter = styled(Item)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.customPalette.myms.primary.main && alpha(theme?.customPalette.myms.primary.main, 0.75),
  margin: `0 -2rem`,
  padding: `0 2rem`,
  height: '5rem',
  cursor: 'default',
}));

const HeaderText = styled('div')({
  alignItems: 'center',
  display: 'flex',
  fontWeight: 600,
  fontSize: '1.6rem',
});

const ButtonHeader = styled('div')(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme?.customPalette.myms.primary.main,
  fontWeight: 600,
  fontSize: '1.4rem',
  cursor: 'pointer',
}));

const ButtonHeaderText = styled('div')(({ theme }: { theme?: Theme }) => ({
  color: theme?.customPalette.myms.primary.main && alpha(theme?.customPalette.myms.primary.main, 0.5),
}));

const Button = styled('div')(({ theme }: { theme?: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme?.palette.common.white,
  fontWeight: 600,
  fontSize: '1.4rem',
  cursor: 'pointer',
}));

const onClickButtonArea = async (e: React.MouseEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const onClickButtonHeader = async () => {
  const response = await fetchWithOptions('/api/redirect/myms', { neurojwt: parseJWTFromCookie() }, { method: 'GET' });
  const parsedBody = await response.json();
  window.open(parsedBody.redirectLocation);
};

const MsServiceButtons = () => {
  const [OPTDialogOpen, setOTPDialogOpen] = React.useState(false);
  const [otpData, setOtpData] = React.useState<{ code: string; expiration: number } | undefined>(undefined);

  const { formatMessage } = useIntl();
  const getOTP = () => {
    fetchWithOptions(`/api/otpCreate`, { neurojwt: parseJWTFromCookie() }, { method: 'POST' })
      .then((res: Response) => {
        if (res.status === 200) return res.json();
        else throw res;
      })
      .then((data) => {
        setOtpData({ code: data.code, expiration: data.expires });
      })
      .catch((err) => {
        makeLog('Error', { name: 'OPT creation', message: 'Failed to create OTP' }, err);
      });
  };

  return (
    <React.Fragment>
      <Container alignContent={'center'}>
        <Item xs={6}>
          <Button
            onClick={() => {
              setOTPDialogOpen(true);
              getOTP();
            }}
          >
            <Key style={{ display: 'block', marginRight: '0.7rem' }} />
            {formatMessage({ id: 'myService.createOTP' })}
          </Button>
        </Item>
      </Container>
      <OTPDialog dialogOpen={OPTDialogOpen} setDialogOpen={setOTPDialogOpen} otpData={otpData} />
    </React.Fragment>
  );
};

const HeaderComponent = () => {
  const { formatMessage } = useIntl();
  return (
    <TileHeader onClick={onClickButtonArea}>
      <Container direction="row" alignItems="center" style={{ height: '100%' }}>
        <Item xs={true}>
          <HeaderText>{'StellarQ MyMS'}</HeaderText>
        </Item>
        <Item style={{ width: '13.5rem' }}>
          <ButtonHeader onClick={onClickButtonHeader}>
            <Container justifyContent={'flex-end'}>
              <ButtonHeaderText>{formatMessage({ id: 'myService.viewInfo' })}</ButtonHeaderText>
              <PlayArrow style={{ display: 'block' }} viewBox={'0 0 14 24'} />
            </Container>
          </ButtonHeader>
        </Item>
      </Container>
    </TileHeader>
  );
};

const FooterComponent = () => (
  <TileFooter onClick={onClickButtonArea}>
    <Container direction={'row'} alignItems="center" style={{ height: '100%' }}>
      <Item style={{ width: '100%' }}>
        <MsServiceButtons />
      </Item>
    </Container>
  </TileFooter>
);

/**
 * Represents MyMS data. Types don't exist in that project! :D
 *
 * Not to be confused with `IRelapse` defined in `src/config/definitions/documents/ms.d.ts`.
 */
type TMyMSRelapse = {
  /**
   * ISO format date string.
   *
   * @example "2024-03-31T21:00:00.000Z"
   */
  relapseDate: string;
};

const isTMyMSRelapse = (n: unknown): n is TMyMSRelapse & IControlProps => {
  return typeof n === 'object' && !Array.isArray(n) && n !== null && '_type' in n && n._type === 'relapse';
};

const MyMSBox = (): JSX.Element => {
  const { formatMessage } = useIntl();

  const myDocuments: IControlProps[] =
    useAppSelector<IControlProps[]>((s: IState) => s.myapp.sortedAndMergedDocuments) || [];
  const locale: Locale = useAppSelector<Locale>((s) => s.settings.userSettings.uiLanguage ?? 'fi');

  const questionnaires = myDocuments
    .filter(
      (d) =>
        d._type !== 'relapse' && ['fatigue', 'msis', 'relapse', 'eq5d', 'fsmc', 'msnq', 'status'].includes(d._type),
    )
    .sort((a, b) => b._cdate - a._cdate);
  const relapses = (myDocuments as unknown[])
    .filter<TMyMSRelapse & IControlProps>(isTMyMSRelapse)
    .sort((a: TMyMSRelapse, b: TMyMSRelapse) => +new Date(b.relapseDate) - +new Date(a.relapseDate));

  const twoMostRecentQuestionnaires = take(2, questionnaires);
  const mostRecentRelapse = take(1, relapses);

  return (
    <div style={{ border: `1px solid ${colors.gray}`, borderRadius: '0.3rem', padding: '0.5rem 2rem 0 2rem' }}>
      <Container direction={'column'} style={{ height: '100%' }} rowSpacing={'0rem'}>
        <HeaderComponent />
        <Item xs={true}>
          {Array.isArray(myDocuments) && myDocuments.length > 0 ? (
            <div style={{ marginTop: '-1rem' }}>
              <TileContentMaker
                type="listWithSubheaders"
                data={{
                  [formatMessage({ id: 'myService.latestQueries' })]: {
                    type: 'recentList',
                    data: twoMostRecentQuestionnaires.map((d) => ({
                      date: new Date(d._cdate).toLocaleDateString(locale),
                      title: formatMessage({ id: 'myService.documents.' + d?._type }),
                      key: d?._id,
                    })),
                  },
                  [formatMessage({ id: 'myService.relapseSuspicion' })]: {
                    type: 'specificList',
                    data: mostRecentRelapse.map((d) => ({
                      date: new Date(d.relapseDate).toLocaleDateString(locale),
                      title: formatMessage({ id: 'myService.documents.' + 'relapse' }),
                      key: d?._id,
                    })),
                  },
                }}
              />
            </div>
          ) : (
            <Container style={{ height: '100%' }} justifyContent="center" alignItems="center">
              <Item style={{ fontSize: 24, color: colors.lightGray, marginBottom: '2rem' }}>
                {formatMessage({ id: 'myService.noData' })}
              </Item>
            </Container>
          )}
        </Item>

        <FooterComponent />
      </Container>
    </div>
  );
};

export default MyMSBox;
