import { RootState } from '../../../store';
import { sortPartialDate } from 'neuro-utils';
import { path, take, uniq } from 'Utility/ramdaReplacement';

export const sortAndTakeMany = (type: string, documents: TDocument[], number?: number): TDocument[] =>
  take(
    number || (documents || []).filter((d) => d._type === type).length,
    (documents || [])
      .sort((n1, n2) => sortPartialDate(n1.date, n2.date))
      .reverse()
      .filter((d) => d._type === type),
  );

export const filterEnded = (documents?: TDocument[] | undefined): TDocument[] =>
  (documents || []).filter((d) => !d.endDate || !d.endDate[0]);

export const docType = (documents?: Array<TDocument | IMedication>, editing?: string): string | undefined =>
  documents && editing
    ? path(
        ['_type'],
        documents.find((d: TDocument | IMedication) => d._id === editing),
      ) || undefined
    : undefined;

export const getDocumentsByType = (
  types: string[],
  documents: (TDocument | IMedication | IDiagnosis)[],
): (TDocument | IMedication | IDiagnosis)[] => documents.filter((d) => types.includes(d._type));

export const getMedicationNames = (documents: (TDocument | IMedication)[]): string[] => {
  const meds = getDocumentsByType(['medication'], documents) as IMedication[];
  const medsFiltered = meds.filter((m) => !!m.medicationName);
  const medNames = uniq(medsFiltered.map((m) => m.medicationName) as string[]);
  return medNames;
};

export const getDiagnosisCodes = (documents: (TDocument | IMedication | IDiagnosis)[]): string[] => {
  const diagnosis = getDocumentsByType(['diagnosis'], documents) as IDiagnosis[];
  const diagnosisCodes = diagnosis.map((d) => d.diagnosis) as string[];
  return diagnosisCodes;
};

export const stateToHistoryProps = (state: RootState): Pick<IJWTBody, 'visitreason' | 'patientssn'> => {
  return {
    visitreason: state.session.data?.visitreason ?? '',
    patientssn: state.session.data?.patientssn ?? '',
  };
};

export const methodsOfContraception: { [key: string]: string[] } = {
  noContraception: [],
  obstacleMethod: ['femaleCondom', 'maleCondom'],
  combinationContraception: ['pill', 'tire', 'plaster'],
  progestinPrevention: ['pill', 'inject', 'capsule'],
  coil: ['hormoneCoil', 'copperCoil'],
  sterilization: ['sterilizationOfAWoman', 'sterilizationOfAMan'],
  otherMethod: ['chemicalPrevention', 'bodyTemperature', 'rhythmMethod', 'interruptedIntercourse'],
};

export type TDocument = IComorbidity | IHospitalization | IPregnancy | IContraception | ISickLeave;
